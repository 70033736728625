.header {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;
  .name {
    width: 40%;
    padding-left: 80px;
  }
  .status {
    width: 60%;
  }
}
.searchInputWrap {
  margin: 10px 0;
  input {
    width: 60%;
  }
}

.wrapper {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;

  .status {
    width: 100px;
  }

  .employeeContainer {
    display: flex;
    align-items: center;
    height: 68px;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 5px;
    min-width: 300px;
    cursor: pointer;
  }

  .name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    font-weight: bold;
    max-width: 245px;

    .parent {
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
    }
  }
  .nameCheckout {
    border: 2px solid rgba(255, 175, 0, 0.5);
    background-color: rgba(255, 175, 0, 0.05);
    &:hover {
      background-color: rgba(255, 175, 0, 0.15);
    }
    color: #e29a00;
    .checkInOutBadge {
      background-color: #e29a00;
    }
  }

  .nameCheckin {
    border: 2px solid rgba(8, 128, 58, 0.5);
    background-color: rgba(8, 128, 58, 0.1);
    &:hover {
      background-color: rgba(8, 128, 58, 0.15);
    }
    color: #08803a;
    .checkInOutBadge {
      background-color: #08803a;
    }
  }

  .namePause {
    color: #00a5dc;
    .checkInOutBadge {
      background-color: #00a5dc;
    }
  }

  .checkInOutBadgeWrapper {
    display: flex;

    .checkInOutBadge {
      color: white;
      border-radius: 3px;
      padding: 2px;
      margin-top: 5px;
      font-size: 13px;
      font-weight: 500;
      width: auto;
    }
  }

  .statusWrap {
    display: flex;
    align-items: center;
  }
}
.photo {
  width: 52px;
  height: 52px;
  margin-right: 10px;
}

.changeStatusCheckIn {
  width: 34px;
  height: 30px;
  border-radius: 3px;
  background-color: #08803a;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}

.changeStatusCheckOut {
  width: 34px;
  height: 30px;
  border-radius: 3px;
  background-color: #ffaf00;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}

.statusIn {
  color: #08803a;
}

.statusOut {
  color: #ffaf00;
}

.statusAbsent {
  color: #ff4532;
}
