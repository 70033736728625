.container {
  padding: 10px;
  font-size: 13px;
  background-color: #fff;
  box-shadow: 0 0 3px #dadada;
  border-radius: 4px;
  position: relative;
  margin-bottom: 15px;
}

.input {
  margin-bottom: 15px;

  & + div i {
    font-size: 17px;
  }
}

.checkboxes {
  margin-left: -5px;
}

.buttons {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.35s;

  &Left {
    display: flex;
    & > * {
      margin-right: 3px;
      position: relative;
    }

    .uploadButton {
      height: 36px;
      width: 47px;
      font-size: 15px;
      background-color: #ededed;
      color: #777;
    }
  }

  &Right {
    position: relative;
    display: flex;
    align-items: center;
    & > * {
      margin-left: 5px;
    }
  }
}

.body {
  margin-top: 11px;
}

.error {
  color: #ff8989;
  font-size: 13px;
  margin-top: 6px;
}

.moreBtn {
  width: 34px;
  height: 28px;
}
