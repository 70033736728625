.sort {
  &Asc {
    &:after {
      content: '\25bc';
    }
  }

  &Desc {
    &:after {
      content: '\25b2';
    }
  }
}
