.input {
  margin-bottom: 16px;

  &Description {
    color: #777;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 5px;
  }
}

.button {
  margin-right: 5px;
  &s {
    margin-top: 16px;
    margin-bottom: 8px;
  }
}

.error {
  color: #ff8989;
  &s {
    margin-top: 10px;
  }
}
