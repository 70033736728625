.container {
  padding: 15px;
  cursor: pointer;
  font-size: 13px;
  color: #777;

  .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;

    .headerLeft {
      display: flex;
      flex-flow: row;

      .assignmentInfoContainer {
        margin-right: 10px;
        display: flex;
        flex-flow: column;

        .assignmentInfoTitle {
          display: flex;
          flex-flow: row;
          font-weight: bold;
          align-items: center;

          i {
            margin-right: 5px;
          }
        }

        .assignmentInfoText {
          font-size: 12px;
          display: flex;
          flex-flow: column;

          .assignmentInfoTextAssigned {
            display: flex;
            flex-flow: row;
            align-items: center;

            i {
              margin-right: 5px;
            }
          }

          .assignmentInfoTextResponsible {
            display: flex;
            flex-flow: row;
            align-items: center;

            i {
              margin-right: 5px;
            }
          }
        }
      }

      .sheduleInfoContainer {
        display: flex;
        flex-flow: column;

        .sheduleInfoTitle {
          display: flex;
          flex-flow: row;
          font-weight: bold;
          align-items: center;

          i {
            margin-right: 5px;
          }
        }

        .sheduleInfoInfoText {
          font-size: 12px;
        }

        .sheduleInfoInfoTextOverdue {
          color: #ff8989 !important;
        }
      }
    }

    .headerRight {
      @media screen and (max-width: 767px) {
        margin-top: 5px;
      }

      .buttonReset {
        padding: 3px 8px;
      }

      .taskStatusButtonContainer {
        position: relative;
        font-weight: 600;
        display: flex;
        flex-flow: row;
        align-items: center;
        background: #708fa0;
        color: #fff !important;
        padding-left: 8px;
        font-size: 12px;
        border-radius: 3px;

        &:hover {
          background: #628394;
        }

        i {
          margin-right: 5px;
        }

        .taskStatusButtonDropdownConntainer {
          margin-left: 5px;

          .taskStatusButtonDropdown {
            padding: 5px;
            width: fit-content;
            height: fit-content;
            border-radius: 3px;
            background: #708fa0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:hover {
              background: #628394;
            }
          }
        }
      }
    }
  }

  .taskDescription {
    margin-bottom: 10px;
  }

  .checklistContainer {
    margin-bottom: 10px;

    .checklistContainerHeader {
      font-weight: bold;

      i {
        margin-right: 5px;
      }
    }
  }
}

.buttonSubmitting {
  height: fit-content;
}
