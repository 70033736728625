.container {
  padding: 10px;
  background-color: #f7f7f7;
}

.title {
  font-weight: 300;
  font-size: 16px;
  color: #555555;
  margin-right: 40px;
  margin-bottom: 1px;
}

.info {
  font-size: 11px;
  font-weight: 300;
  color: #bebebe;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &Updated {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &Separator {
    font-size: 16px;
    color: #555555;
    margin: 0 4px;
  }
}

.button {
  &Container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Icon {
    margin-right: 1px;
    font-size: 14px;
  }
}

.imagesWrapper {
  display: flex;
  gap: 10px;
}
