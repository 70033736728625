.orderTable {
  width: 100%;
}
.bigtr{
  border-width:5px;
  border-color: lightgray;
  border-top-style:double;
}
.monthSelect{
  width: 30%;
}
@media only screen and (max-width: 730px) {
  .monthSelect {
    width: 100%;
  }
}
