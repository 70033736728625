.institutionSearchContainer {
  display: flex;
  gap: 5px;
  flex-direction: column;
  cursor: pointer;
  margin: auto;
  align-items: center;
  flex-direction: column;
  padding: 0 13px;
  margin-top: auto;
  margin-bottom: 7px;
  color: #555;

  .textSearch {
    font-size: 10px;
    font-weight: 600;
  }

  .iconTextSearch {
    font-size: 16px;
  }

  .iconAWOTextSearch {
    font-size: 20px;
  }
}

.searchPopupContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;

  .inputSearchWrapper {
    position: relative;

    input {
      padding-left: 25px;
    }

    .icon {
      position: absolute;
      left: 10px;
      top: 10px;
      color: #555;
    }
  }

  .kitaTableList {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .item {
      display: flex;
      gap: 5px;
      padding: 10px 0;
      border-bottom: 1px solid #eee;
      cursor: pointer;
    }
  }
}