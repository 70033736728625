@import 'resources/styles/mixins.scss';

.tooltipWrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  transition: 0.2s opacity;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  background-color: #708fa0;
  padding: 3px 7px;
  font-size: 13px;
  min-height: 25px;
  border-radius: 3px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  font-weight: 700;
  z-index: 1602;

  &Text {
    max-width: 300px;
    overflow: hidden;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 7px;
    border-bottom-width: 12px;
    border-top-color: #708fa0;
  }

  &IsOpened {
    opacity: 1;
  }
}

.positionLeft {
  width: 300px;
  right: calc(100% + 10px);
  left: unset;
  bottom: unset;
  transform: translateY(-50%);
  white-space: normal;
  top: 10px;

  &:after {
    left: 100%;
    top: 2px;
    transform: rotate(270deg);
  }
  @include media-tablet {
    width: 100%;
    top: calc(100% + 15px);
    right: 0;
    &:after {
      left: calc(100% + 2px);
      transform: rotate(-90deg) translateX(50%);
      top: calc(50% - 4px);
    }
  }
}

.positionRight {
  left: 100%;
  transform: none;
  bottom: unset;
  top: -12px;

  &:after {
    transform: rotate(90deg);
    left: -16px;
    top: 3px;
  }

  @media screen and (max-width: 1350px) {
    font-size: 12px;
  }

  @include media-mobile {
    font-size: 11px;
  }
}
