.noChildren {
  &Text {
    margin: 10px 0;
  }
}
.addKidBtn{
  margin-right: 8px;
}
.kidCodeBtn{
  margin-right: 5px;
}
.kidCodeContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.kidCode{
  padding: 8px;
  background: #f7f7f7;
  width: fit-content;
  border-radius: 3px;
}
.title {
  font-size: 20px;
  font-weight: 300;

  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
}
.avatarContainer{
  display: flex;
  flex-direction: row;
}
.avatarName{
  margin-left: 8px;
  margin-top: 11px;
  font-weight: bold;
}
.button {
  margin-right: 5px;
}
.red{
  color: red;
}
.avatar {
  width: 42px;
  height: 42px;
  border-radius: 3px;
  margin-bottom: 11px;
}
.formGroup {
  margin-bottom: 10px;

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }
}

.select {
  overflow: auto;
  max-height: 200px !important;
  font-size: 13px;
  width: 100%;
}

.inputBirthday {
  width: 100%;
}

.button {
  margin-right: 5px;
}

.QRbuttonDiv {
  display: flex;
}

.QRbutton {
  height: 38px;
  margin-top: 32px;
}

.QR {
  margin-top: 17px;
  margin-left: 12px;
}

.verificationImages {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  img {
    width: 150px;
  }
}

.selectedImage {
  box-shadow: 0 0 1px 4px;
}
.QRbuttonDiv {
  display: flex;
}

.QRbutton {
  height: 38px;
  margin-top: 32px;
}

