.moreBtn {
  width: 29px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #eeeeee;
  }

  &.active {
    background-color: #708fa0;
    i {
      color: #fff;
    }
  }

  i {
    color: #000000;
  }
}
