.header {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;

  .name {
    width: 245px;
  }

  .homebyown {
    width: 40%;
    padding-left: 80px;
    font-size: 17px;
  }

  .pickUpPerson {
    width: 40%;
    padding-left: 100px;
  }

  .status {
    width: 60%;
  }
}

.searchInputWrap {
  margin: 10px 0;

  input {
    width: 60%;
  }
}

.allKids {
  margin-bottom: 10px;
}

.saveButton {
  margin-right: 10px;
}