.container {
  position: relative;
  cursor: pointer;
}

.icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video {
  pointer-events: none;
  width: 100%;
  max-height: 100%;
}
