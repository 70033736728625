.editor {
  &Container {
    margin-bottom: 10px;
    position: relative;

    &Buttons {
      margin-top: 4px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      .uploadButton {
        font-size: 14px;
        padding: 6px 10px;
        background: transparent !important;
        color: #7a7a7a;
        opacity: 0.4;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  &Error {
    color: #ff8989;
    font-size: 13px;
    margin-bottom: 6px;
  }
}

.editPublicationTime {
  z-index: 13!important;
  background: transparent !important;
  color: #7a7a7a;
  opacity: 0.5;
  margin-right: 5px;

  *[role='button'] {
    padding: 4px 16px!important;
    height: unset!important;
    font-size: 14px!important;
  }

  &:hover {
    opacity: 0.9;
  }
}
