.recovery_container {
  max-width: 300px;
  margin: 0 auto 20px;
  padding-bottom: 15px;
  text-align: left;
}

.recovery_heading_2 {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  padding: 15px 32px 15px 15px;
  color: #555;
}

.recovery_heading_text_sign_in {
  font-weight: 300;
  color: #555;
  padding-left: 15px;
  padding-top: 15px;
}

.back_to_dash_container {
  margin: 0 15px;
}

.back_to_dash:hover {
  background: #286090;
  cursor: pointer;
}

.back_to_dash_btn_text {
  font-family: 'Open sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
