.awoMemberList {
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.awoMemberItem {
  height: 30px;
  display: flex;
  gap: 20px;
  cursor: pointer;
}

.profileAvatarWrapper {
  width: 34px;
  height:34px;
}

.memberName,
.memberEmail {
  width: 250px;
}
