.wrapper{
  display: flex;
}

.uploadButton{
  width: max-content;
  background: #ededed;
  color: #7a7a7a;
  font-size: 13px;
  padding: 4px 8px;
  flex-flow: row;
  margin-right: 10px;
  i {
    margin-right: 5px;
  }

  &:hover {
    background: #e8e8e8;
    border-color: #adadad;
  }
}
.loading{
  pointer-events: none;
}
