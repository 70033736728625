.avatar {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 3px;

  &Link {
    width: 100%;
    height: 100%;
  }

  &Default {
    width: 100%;
    height: 100%;
  }

  &NotDefault {
    width: 100%;
  }
}

.hidden {
  visibility: hidden;
}
