.tabList {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  width: 100%;
  border-bottom: 1px solid #ddd;

  .tab {
    position: relative;
    overflow: hidden;
    margin-right: 3px;
    list-style: none;
    width: auto;
    padding: 12px 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #ddd;
    font-weight: 500;
    font-size: 13px;
    background-color: #fafafa;
    margin-bottom: -1px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;

    &::after {
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
      content: ' ';
    }
    &:hover {
      border-top: 3px solid #ddd;
      padding-top: 10px;
    }
    &.selected {
      padding-top: 10px;
      border-bottom: 1px solid transparent;
      background-color: white;
      border-top: 3px solid #6fdbe8;
      cursor: default;
    }
    &.activeAwoWW {
      border-top: 3px solid #e2001A;
    }
    &.secondary {
      background: transparent;
      border: 1px solid transparent;
      &:hover {
        padding: 12px 16px;
        border: 1px solid;
        border-color: #eeeeee #eeeeee #ddd;
        background-color: #f7f7f7;
      }
      &.selected {
        padding: 12px 16px;
        border: 1px solid #ddd;
        border-bottom: 1px solid white;
        &:hover {
          background: white;
        }
      }
    }
  }
}

.componentWrapper {
  padding: 20px 0;
  padding-bottom: 10px;
}
