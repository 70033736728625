.cwnavigationContainer {
  display: flex;
  flex-direction: row;
}
.calendarWeek {
  text-align: center;
  vertical-align: center;
  display: flex;
  align-self: center;
  margin: 0 13px 0 13px;
}
.foodContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.secondnav {
  display: flex;
  flex-direction: row;
}

.mr2 {
  margin-right: 15px;
}

.hr {
  background-color: lightgray;
  height: 1px;
  border: 0;
}
.mr1 {
  margin-right: 3px;
}

.uploadButton {
  width: max-content;
  background: #ededed;
  color: #7a7a7a;
  height: 36px;
  font-size: 13px;
  padding: 4px 8px;
  flex-flow: row;

  i {
    margin-right: 5px;
  }

  &:hover {
    background: #e8e8e8;
    border-color: #adadad;
  }
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
}
