.button {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  font-weight: 600;

  &Medium {
    font-size: 14px;
    padding: 8px 16px 9px;
  }

  &Small {
    padding: 4px 8px;
    font-size: 12px;
  }

  &Default {
    background: #ededed;
    color: #7a7a7a !important;

    &:hover {
      background: #e8e8e8;
    }

    &:active,
    &.active {
      background: #e0e0e0;
    }
  }

  &Info {
    background: #6fdbe8;
    color: #fff;
  }

  &Primary {
    background: #708fa0;
    color: #fff;

    &:hover {
      background: #628394;
    }
  }

  &Danger {
    background: #ff8989;
    color: #fff;

    &:hover {
      background: #ff6f6f;
    }
  }

  &Warning {
    background: #ffa726;
    color: #fff;

    &:hover {
      background: #f57c00;
    }
  }

  &Success {
    color: #ffffff;
    background-color: #97d271;

    &:hover {
      background-color: #89cc5e;
    }
  }

  &Light {
    color: #fff;
    background-color: #44deec;

    &:hover {
      background-color: #45cfdc;
    }
  }

  &Awoww {
    color: #fff;
    background-color: #e2001A;

    &:hover {
      background-color: #e2001A;
    }
  }

  &Disabled {
    pointer-events: none;
    background-color: rgb(219, 213, 213) !important;
  }

  &:active,
  &.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &TextCenter {
    text-align: center;
  }

  &TextLeft {
    text-align: left;
  }

  &TextRight {
    text-align: right;
  }

  @media (max-width: 768px) {
    &Medium {
      font-size: 12px;
      padding: 5px 10px 6px;
    }

    &Small {
      padding: 2px 4px;
      font-size: 9px;
    }
  }
}

.fullWidth {
  display: block;
  width: 100%;
}
