.dateInputsContainer {
  display: flex;
  flex-flow: column;
  gap: 15px;
  width: 300px;

  .dateInputError {
    color: red;
  }

  .datePickerContainer {
    gap: 20px;
    display: inline-flex;
    flex-flow: row;

    .halfDayCheckbox {
      margin-left: 0;
      margin-right: 0;
    }

    input {
      width: 100%;
      height: 35px;
      box-shadow: none;
      display: block;
      padding: 6px 12px;
      color: #555555;
      font-size: 14px;
      font-family: 'Open sans', sans-serif;
      background-color: #fff;
      border: 2px solid #ededed;
      border-radius: 4px;
      transition: border-color ease-in-out 0.15s;

      &:focus {
        border: 2px solid #6fdbe8;
      }
      &.input_border_error {
        border: 2px solid #ff8989;
      }
      &:disabled {
        background-color: #eeeeee;
        color: #eeeeee;
      }
    }
    ::-webkit-input-placeholder {
      color: #c0c0c0;
    }
    ::-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 19+ */
    :-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 18- */
    :-ms-input-placeholder {
      color: #c0c0c0;
    }
  }

  .absenceTypeContainer {
  }
}

.dateStart,
.dateEnd {
  display: flex;
  flex-flow: column;
  width: 140px;
}

.container {
  position: relative;

  section {
    margin: 40px 0;
  }
  h2 {
    margin-bottom: 15px;
  }
}

.text {
  margin-bottom: 20px;
}
