.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.fileNameWrap {
  margin-bottom: 10px;
}

.wrapper {
  padding: 20px;
}

.inputGroup {
  margin-bottom: 20px;
}

.required {
  content: '**';
  color: #6fdbe8;
}
