.autoGrowingTextareaContainer {
  position: relative;
}

.textarea {
  box-sizing: border-box;
  padding: 6px 12px;
  width: 100%;
  min-height: 36px;
  resize: none;
  background-color: #fff;
  color: #555555;
  font-size: 14px;
  font-family: 'Open sans', sans-serif;
  border: 2px solid #ededed;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s;
  overflow-y: hidden;

  &.input_border:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #6fdbe8;
  }

  &.input_border_error {
    border: 2px solid #ff8989;
  }
  &:focus {
    border: 2px solid #6fdbe8;
  }
}

.placeholder {
  position: absolute;
  top: 8px;
  left: 8px;
  color: gray;
  pointer-events: none;
}
