@import '../variables.scss';

@mixin media-laptop {
  @media screen and (max-width: #{$laptop-width}) {
    @content;
  }
}

@mixin media-tablet {
  @media screen and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin media-mobile {
  @media screen and (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin small-mobile {
  @media screen and (max-width: #{$small-width}) {
    @content;
  }
}
