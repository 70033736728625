.container {
  margin-bottom: 10px;
  padding: 0 20px;
}

.selectContainer {
  margin-bottom: 15px;
}

.inputContainer {
  margin-bottom: 15px;
}

.checkboxContainer {
  margin-bottom: 15px;
}

.textareaContainer {
  margin-bottom: 15px;

  .textareaContent {
    min-height: 100px;
  }  
}