@keyframes waveLoading {
  0% {
    background-color: #FFF;
  }

  50% {
    background-color: transparent;
  }

  100% {
    background-color: #FFF;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px 5px;

  .group {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
    width: 32px;
    height: 32px;
    margin-right: 3px;
    margin-bottom: 5px;
    border-radius: 3px;
    cursor: pointer;
  
    .logo {
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }
  }
}

.cardContainer {
  min-height: 40px;
}

.addMoreButton {
  width: 100%;
  padding: 5px 0;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: center;

  &__loading {
    & div {
      display: flex;
      align-items: center;
    }

    & div span {
      width: 10px;
      height: 10px;
      margin: 0 5px;
      border-radius: 10px;
      animation-name: waveLoading;
      animation-iteration-count: infinite;
      animation-duration: 1s;
    }
  }
}
