@import 'resources/styles/mixins.scss';

.container {
  padding: 10px;
  width: 100%;
}

.loader {
  margin: 40px 0 41px;
}

.tableWrapper {
  overflow: auto;
  @include media-mobile {
    overflow: scroll;
  }
}
