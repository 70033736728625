.toolBarIcon {
  display: inline-block;
  font-size: 40px;
  line-height: 50px;
  background-color: white;
  color: black;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: bottom;
  border-style: solid;
  border-width: thin;
  border-radius: 10px;
  cursor: pointer;
}

.imageUpload {
  cursor: pointer;
}

.fileInput {
  display: none;
}