.footerBtnLeft {
  margin-right: 10px;
}

.inputGroup {
  margin-bottom: 15px;
}
.error {
  color: #ff8989;
  font-size: 13px;
}
