@import 'resources/styles/mixins.scss';
.filterContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.numberSelect{
  border-radius: 5px;
  margin-left: -5px;
  margin-right: 10px;
  width: 50%;
}

.filterSelect {
  width: 100px;
}
