.container {
  padding: 20px;
  padding-top: 58px;
}
.modules {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .module {
    background-color: #ededed;
    padding: 10px;
    border-radius: 3px;
    width: 349px;
    display: flex;
    margin-bottom: 19px;
    .rightSection {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
    &__image {
      width: 64px;
      height: 64px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    &__title {
      font-size: 14px;
      font-weight: 500;
      color: #555;
      line-height: 16px;
      margin-bottom: 4px;
    }
    &__description {
      font-size: 13px;
      margin-bottom: 10px;
    }
    &__select {
      cursor: pointer;
      background: #708fa0;
      color: white;
      padding: 5px 8px;
      font-size: 12px;
      border-radius: 3px;
      text-align: center;
      max-width: 88px;
      &:hover {
        background: #628394;
      }
      &_disabled {
        cursor: default;
        background: #7a7a7a;
        &:hover {
          background: #7a7a7a;
        }
      }
    }
  }
}
.footerNavigation {
  display: flex;
  justify-content: center;
  & > div {
    margin: 0 5px;
  }
}
