.container {
  padding: 10px;

  & select {
    padding: 4px;
    font-family: sans-serif;
    border-radius: 4px;
  }
}

.text {
  margin-bottom: 10px;
  font-size: 14px;
  color: #555555;

  &Container {
    margin-bottom: 30px;
  }
}

.checkbox {
  margin-bottom: 10px;
  margin-left: 0;
}

.button {
  margin-bottom: 10px;
}

.hidden {
  display: none;
}


.checkboxes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}