@import 'resources/styles/mixins.scss';

.addKitaListItemContainer {
  box-sizing: content-box;
  width: 100%;
  height: 70px;
  position: relative;
  padding: 11px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #f7f7f7;
  }

  &.opened {
    justify-content: flex-start;
  }

  @media screen and (max-width: 1350px) {
    height: 58px;
  }

  @include media-mobile {
    height: 46px;
  }

  .kitaListItemAvatarWrapper {
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1350px) {
      width: 58px;
      height: 58px;
    }

    @include media-mobile {
      width: 46px;
      height: 46px;
    }

    &.active {
      border: 3px solid #6fdbe8;
    }

    .kitaListItemAvatarContainer {
      position: relative;
      width: 58px;
      height: 58px;
      font-size: 30px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1350px) {
        width: 46px;
        height: 46px;
      }

      @include media-mobile {
        width: 34px;
        height: 34px;
      }
    }
  }
}
