.arrivalTimeContainerWrapper {
  overflow-x: auto;

  .header {
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
    border-bottom: 1px solid #bdbdbd;

    .name {
      width: 180px;
      margin-right: 5px;
      margin-left: 65px;
      text-align: left;
      cursor: pointer;
    }

    .group {
      width: 100px;
      text-align: center;
      cursor: pointer;
    }

    .time {
      width: 124px;
      text-align: center;
      cursor: pointer;
    }

    .status {
      width: 60%;
    }

    i {
      margin-left: 1rem;
    }
  }

  .searchInputWrap {
    margin: 10px 0;

    input {
      width: 60%;
    }
  }
}
