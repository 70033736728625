.msLoginWrapper {
  display: flex;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid #8c8c8c;
  font-size: 14px;
  padding: 7px 12px;
  align-items: center;
  cursor: pointer;
  img {
    height: 15px;
  }
}
