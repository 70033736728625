.multipleActions {
  position: relative;
  width: 200px;
  .title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    border: 1px solid #ededed;
    height: 40px;
    margin-top: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  .dropdown {
    display: none;
    z-index: 2;
    position: absolute;
    top: 40px;
    left: -1px;
    right: -1px;
    background-color: white;
    border: 1px solid #ededed;
    border-top: none;
    transition: all 200ms ease-in-out;
    .item {
      padding: 12px 8px;
      cursor: pointer;
      &:hover {
        background-color: #e2f8fa;
      }
    }
  }
  &_active {
    .dropdown {
      display: block;
    }
  }
}
