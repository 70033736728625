@import 'resources/styles/mixins.scss';

.eventInfo {
  padding-left: 10px;
  border-left: 3px solid;

  &Container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &Icon {
    font-size: 35px;
    color: #ededed;
    margin-right: 10px;
  }

  &Title {
    font-size: 14px;
    font-weight: 700;
    color: #555555;
  }

  &Date {
    font-weight: 300;
    margin-bottom: 5px;
    min-height: 15px;
  }

  &Description {
    color: #555555;
    font-size: 13px;
    padding-bottom: 10px;
  }

  &Location {
    i {
      color: #6fdbe8;
      margin-right: 5px;
      font-size: 18px;
    }
    span {
      color: #6fdbe8;
      font-size: 18px;
    }
  }
}

.participants {
  margin-top: 18px;

  &Title {
    font-weight: 700;
    color: #777777;
    font-size: 13px;
  }

  &Count {
    color: #777777;
    font-size: 13px;
    margin-bottom: 10px;
    user-select: none;

    &Clickable {
      color: #6fdbe8;
      cursor: pointer;
    }
  }

  &Button {
    &s {
      margin-bottom: 9px;
      display: flex;
      align-items: center;
      gap: 3px;
    }

    &CheckIcon {
      font-size: 14px;
      margin-right: 5px;
    }
  }
}

.mapContainer {
  position: relative;
  transition: all .5s;
}

.smMapContainer {
  height: 300px;
  width: 400px;

  @include media-mobile {
    width: 100%;
  }
}

.extendedMapContainer {
  height: 400px;
  width: 100%;
}

.mapResizeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: #444444c2;
  color: white;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
}

.attendCountInput {
  width: 64px;
  padding: 6px 4px 6px 6px;
  height: 24px;
}

.attendCountLabel {
  color: #777777;
  font-size: 12px;
}

.popupBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
