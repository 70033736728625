.photoWrapper {
  width: 34px;
  .photo {
    width: 34px;
    height: 34px;
    overflow: hidden;
    border-radius: 4px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
.role {
  &__select {
    font-size: 12px;
  }
}
.remove {
  background: #ff8989;
  color: #fff;
  cursor: pointer;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 19px;
  border-radius: 3px;
  &:hover {
    background: #ff6f6f;
    text-decoration: none;
  }

  .confirmWindow {
    color: #555
  }
}
