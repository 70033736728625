.wrapContainer {
  overflow-x: auto;
}
.header {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;
  flex-direction: row;
  .name {
    width: 220px;
    padding-left: 80px;
  }
}
.searchInputWrap {
  margin: 10px 0;
  input {
    width: 60%;
  }
}
