.actionsContainer {
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
  .action {
    width: 34px;
    height: 30px;
    border-radius: 3px;
    background-color: #ededed;
    color: #7a7a7a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:first-child {
      margin-right: 2px;
    }
    &:hover {
      background-color: #d7d7d7;
    }
  }

}
