.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
}

.userInfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #555555;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
