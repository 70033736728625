.fp_card_container {
  max-width: 300px;
  margin: 0 auto 20px;
  padding-bottom: 15px;
  text-align: left;
}

.fp_heading_2 {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  padding: 15px 32px 15px 15px;
  color: #555;
}

.reset_btn_width {
  width: 138px;
}

.fp_heading_text_password {
  font-weight: 300;
  color: #555;
}

.fp_heading_padding_15 {
  padding: 15px;
}

.horizontal_line {
  height: 1px;
  margin: 20px 15px 15px 15px;
}

.btnContainer {
  margin: 1.5rem 15px 0;
  display: flex;

  & > * {
    margin-right: 5px;
  }
}

.reset_pwd_btn,
.fp_back_btn {
  height: 36px;
  background: #708fa0;
  padding: 8px 14px;
  border-radius: 3px;
  cursor: pointer;
}

.reset_pwd_btn_hover:hover,
.fp_back_btn_hover:hover {
  background: #286090;
  cursor: pointer;
}

.reset_pwd_btn_disabled:disabled,
.fp_back_btn_disabled:disabled {
  height: 36px;
  padding: 8px 14px;
  background: #708fa0;
  opacity: 0.7;
  border-radius: 3px;
  cursor: pointer;
}

.reset_pwd_btn_text,
.fp_back_btn_text {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.forget_password_text {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #6fdbe8;
  cursor: pointer;
}

.email_form {
  padding: 0 15px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #555;
}

.security_code {
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #555;
}

.error_msg {
  margin: 0 15px;
}
