.historyDateContainer {
  border-bottom: 1px solid #bdbdbd;
  padding: 12px 5px;
  display: flex;
  flex-direction: column;
  .historyDateHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    color: #333;
    padding: 10px;
    > i {
      margin-left: auto;
    }
    .date {
      font-weight: 600;
      font-size: 1rem;
      width: 11ch;
    }
    .dayWorkingTime {
      display: flex;
      align-items: center;
      gap: 15px;

      .separator {
        width: 1px;
        height: 35px;
        background-color: #d4d4d4;
      }

      .timeWorked {
        width: 105px;
        color: #222;
        background-color: #e1e1e1;
      }

      .dateMetricWrapper {
        display: flex;
        flex-direction: column;
        .labelDateMetric {
          font-weight: 600;
        }
        .dateMetric {
          display: flex;
          gap: 5px;
        }
      }
    }
  }
  .historyDateBody {
    padding: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    cursor: auto;
    .historyDateGroup {
      display: flex;
      flex-direction: column;
      background-color: #f2f2f2;
      border-radius: 3px;
      padding: 10px;
      gap: 5px;

      .checkInOutduration {
        font-size: 18px;
        border-bottom: solid 1px #d4d4d4;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        .durationCaption {
          display: flex;
          flex: 0 0 80px;
          align-items: center;
          gap: 5px;
        }
      }
      .historyDateEntry {
        display: flex;
        align-items: center;
        gap: 10px;

        .entryStatus {
          display: flex;
          width: 110px;
        }

        .entryTimestamp {
          display: flex;
          max-width: 240px;
        }

        .executivePerson {
          display: flex;
          width: 250px;
          align-items: center;
        }

        .statusIn,
        .statusOut,
        .statusAbsent {
          i {
            margin-right: 5px;
          }
        }

        .statusIn {
          color: #08803a;
        }

        .statusOut {
          color: #ffaf00;
        }

        .statusAbsent {
          color: #ff4532;
        }

        .editCheckInOutControls {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 3px;
          background-color: #e3e3e3;
          border-radius: 3px;

          .timeInputContainer {
            display: flex;
            gap: 5px;

            .editCheckInOutButtonGroup {
              display: flex;
              gap: 5px;

              .approveBtn {
                background-color: #97d271;
                &:hover {
                  background-color: #89cc5e;
                }
              }
              .cancelBtn {
                background-color: #ff8989;
                &:hover {
                  background-color: #ff6f6f;
                }
              }

              .approveBtn,
              .cancelBtn {
                i {
                  color: white;
                }
              }
            }
          }
        }

        .editCheckInOutButton {
          background-color: #d6d3d3;
          &:hover {
            background-color: #ababab;
          }
        }
      }
    }

    .manualCheckInOutNote {
      margin-top: 8px;
      i {
        margin-right: 5px;
      }
    }

    .breakContainer,
    .legalBreakContainer {
      color: #005872;
      background-color: #e5f9ff;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      margin-top: 10px;
    }
    .legalBreakContainer {
      margin-top: 0;

      > .scale {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #005872;
      }
    }
  }
}

.todayContainer {
  border-left: 5px solid #6fdbe8;
}

.notTodayContainer {
  border-left: 5px solid transparent;
}

.clickable {
  cursor: pointer;
}

.grayedOut {
  color: #b1b1b1;
}

.historyDateContainer:last-child {
  border-bottom: none;
}

.badge {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  padding: 4px;
}

.manualEntry {
  color: #fff;
  background-color: #005872;
  font-size: 14px;
}

.timeEntryUnapproved {
  color: #fff;
  background-color: #8e3611;
  font-size: 14px;
}

.errorMessageContainer {
  color: #f44b4b;
  font-weight: 600;
  font-size: 0.75rem;
}
