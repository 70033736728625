.title {
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #555555;
}

.container {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
}

.item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;

    .itemBody {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  &Title {
    font-size: 20px;
    font-weight: 600;
    color: #555;
    margin-bottom: 17px;
  }

  &Body {
    padding-left: 22px;
    border-bottom: 1px solid #adadad;
    padding-bottom: 20px;

    &Title {
      font-size: 16px;
      margin-bottom: 3px;
      font-weight: 600;
      color: #555;
    }

    &Text {
      font-size: 13px;
    }

    &Container {
      margin-bottom: 10px;
    }
  }


}

.iframecl {
  border: 0;
  width: 95%;
  height: 5500px;
}