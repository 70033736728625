@import 'resources/styles/mixins.scss';

.error {
  color: red;
}

.module {
  width: 100%;
  height: 100px;
  display: flex;
  margin-bottom: -1px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-left: 0px;
  padding-top: 15px;
  padding-bottom: 10px;

  @include media-mobile {
    height: auto;
  }

  .rightSection {
    margin-left: 10px;
    position: relative;

    &__header {
      margin-bottom: 4px;
      display: flex;

      .module__title {
        font-size: 14px;
        font-weight: 500;
        color: #555;
        line-height: 16px;
      }

      .module__activated {
        font-size: 10px;
        color: white;
        background: #97d271;
        border-radius: 3px;
        font-weight: bold;
        font-weight: 600;
        padding: 3px 5px 4px;
        line-height: 1;
        margin-left: 3px;
      }
    }
  }

  &__image {
    width: 64px;
    height: 64px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__description {
    height: 18px;
    font-size: 13px;
    margin-bottom: 10px;
    color: #555;

    @include media-mobile {
      height: auto;
    }
  }

  &__bttn {
    cursor: pointer;
    background: #708fa0;
    color: white;
    padding: 4px 9px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 3px;
    text-align: center;
    display: inline-block;
    margin-right: 4px;

    &:hover {
      background: #628394;
    }

    &_disabled {
      cursor: default;
      background: #7a7a7a;

      &:hover {
        background: #7a7a7a;
      }
    }

    &_configure {
      background: #ededed;
      color: #7a7a7a;

      &:hover {
        background: #e8e8e8;
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.buttonSubmitting {
  height: 17px;
}