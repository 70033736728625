.title {
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #555555;
}

.container {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
}

.item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;

    .itemBody {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  &Title {
    font-size: 20px;
    font-weight: 600;
    color: #555;
    margin-bottom: 17px;
  }

  &Body {
    padding-left: 22px;
    border-bottom: 1px solid #adadad;
    padding-bottom: 20px;

    &Title {
      font-size: 16px;
      margin-bottom: 3px;
      font-weight: 600;
      color: #555;
    }

    &Text {
      font-size: 13px;
    }

    &Container {
      margin-bottom: 10px;
    }
  }
}

.button {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  font-weight: 600;

  &Medium {
    font-size: 14px;
    padding: 8px 16px 9px;
  }

  &Small {
    padding: 4px 8px;
    font-size: 12px;
  }

  &Default {
    background: #ededed;
    color: #7a7a7a !important;

    &:hover {
      background: #e8e8e8;
    }

    &:active,
    &.active {
      background: #e0e0e0;
    }
  }

  &Info {
    background: #6fdbe8;
    color: #fff;
  }

  &Primary {
    background: #708fa0;
    color: #fff;

    &:hover {
      background: #628394;
    }
  }

  &Danger {
    background: #ff8989;
    color: #fff;

    &:hover {
      background: #ff6f6f;
    }
  }

  &Success {
    color: #ffffff;
    background-color: #97d271;

    &:hover {
      background-color: #89cc5e;
    }
  }

  &Light {
    color: #fff;
    background-color: #44deec;

    &:hover {
      background-color: #45cfdc;
    }
  }

  &Disabled {
    pointer-events: none;
    background-color: rgb(219, 213, 213) !important;
  }

  &:active,
  &.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}

.fullWidth {
  display: block;
  width: 100%;
}
