@import 'resources/styles/mixins/table.scss';
@include table;

.formGroup {
  margin-bottom: 10px;

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }
}

.headerText {
  margin-left: 15px;
}

.checkboxWrapper {
  display: grid;
  grid-template-columns: minmax(0, 100px) minmax(0, 100px) 1fr;
  gap: 8px;

  & > div {
    display: flex;
    justify-content: center;
  }
}

.userFieldCheckboxWrapper {
  display: grid;
  grid-template-columns: minmax(0, 100px) 1fr;
  gap: 8px;

  & > div {
    display: flex;
    justify-content: center;
  }
}

.label {
  display: flex;
  align-items: flex-end;
  line-height: 1;
}

.selectWrapper {
  margin: 12px 0px;
  display: flex;
  justify-content: flex-end;

  & > * {
    max-width: 300px;
  }
}

.buttonWrapper {
  margin: 12px 0px;
}

.questionContainer {
  margin-top: 10px;
  .btnAddQuestion {
    margin-bottom: 10px;
  }
  .errorLimitCustomField {
    color: red;
    margin-bottom: 5px;
  }
}

.customFieldWrapper {
  .userFieldCheckboxWrapper, .checkboxWrapper {
    margin-bottom: 10px;
    .label {
      align-items: center;
      gap: 10px;
      i {
        cursor: pointer;
      }
    }
  }
}
.fieldContainer {
  display: flex;
  gap: 10px;
}
