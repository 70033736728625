.member {
  position: relative;
  width: 32px;
  height: 32px;
  margin-right: 3px;
  margin-bottom: 5px;
  cursor: pointer;
  overflow: hidden;

  &Avatar {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    margin-bottom: 5px;
  }
}

.admin {
  border: 1px solid #97d271;
}
