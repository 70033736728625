@import 'resources/styles/mixins.scss';

.pagination {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .paginationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .page {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 30px;
      border: 1px solid #ddd;
      border-right: none;
      font-family: Open Sans;
      font-size: 12px;
      line-height: 12px;
      color: #777777;
      cursor: pointer;

      .fa {
        font-family: Open Sans;
        font-size: 12px;
        line-height: 12px;
        color: #777777;
      }
    }
    .first,
    .last,
    .prev,
    .next {
      padding-top: 3px;
    }
    .first {
      border-radius: 4px 0 0 4px;
    }
    .last {
      border-radius: 0 4px 4px 0;
      border-right: 1px solid #ddd;
    }
    .current {
      color: white;
      background-color: #708fa0;
    }
  }
}

.hiddenOn {
  &Desktop {
    display: none;

    @include media-tablet {
      display: flex;
    }
  }

  &Mobile {
    @include media-tablet {
      display: none;
    }
  }
}
