@import 'resources/styles/mixins.scss';

.ProseMirror-color-box {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid black;
  cursor: pointer;

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 110px;
  }
}

.ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror li {
  position: relative;
}

.ProseMirror-hideselection *::selection {
  background: transparent;
}
.ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}
.ProseMirror-hideselection {
  caret-color: transparent;
}

.ProseMirror-selectednode {
  outline: 2px solid #8cf;
}

/* Make sure li selections wrap around markers */

li.ProseMirror-selectednode {
  outline: none;
}

li.ProseMirror-selectednode:after {
  content: '';
  position: absolute;
  left: -32px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  border: 2px solid #8cf;
  pointer-events: none;
}
.ProseMirror-textblock-dropdown {
  min-width: 3em;
}

.ProseMirror-menu {
  margin: 0 -4px;
  line-height: 1;
}

.ProseMirror-tooltip .ProseMirror-menu {
  width: -webkit-fit-content;
  width: fit-content;
  white-space: pre;
}

.ProseMirror-menuitem {
  margin-right: 3px;
  display: inline-block;
}

.ProseMirror-menuseparator {
  border-right: 1px solid #ddd;
  margin-right: 3px;
}

.ProseMirror-menu-dropdown,
.ProseMirror-menu-dropdown-menu {
  font-size: 90%;
  white-space: nowrap;
}

.ProseMirror-menu-dropdown-menu.color {
  display: flex;
  flex-wrap: wrap;
  width: 133px;

  .ProseMirror-icon {
    display: block;
  }

  .ProseMirror-menu-dropdown-item {
    padding: 0;
    width: 17px;
    height: 17px;
    border: 1px solid black;
    margin-right: 5px;
    margin-bottom: 5px;
    color: transparent;
    border-radius: 5px;
    overflow: hidden;

    &:nth-child(6n) {
      margin-right: 0;
    }

    * {
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.ProseMirror-menu-dropdown {
  vertical-align: 1px;
  cursor: pointer;
  position: relative;
  padding-right: 15px;
}

.ProseMirror-menu-dropdown-wrap {
  padding: 1px 0 1px 4px;
  display: inline-block;
  position: relative;
}

.ProseMirror-menu-dropdown:after {
  content: '';
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid currentColor;
  opacity: 0.6;
  position: absolute;
  right: 4px;
  top: calc(50% - 2px);
}

.ProseMirror-menu-dropdown-menu,
.ProseMirror-menu-submenu {
  position: absolute;
  background: white;
  color: #666;
  border: 1px solid #aaa;
  padding: 2px;
}

.ProseMirror-menu-dropdown-menu {
  z-index: 15;
  min-width: 6em;
}

.ProseMirror-menu-dropdown-item {
  cursor: pointer;
  padding: 2px 8px 2px 4px;
}

.ProseMirror-menu-dropdown-item:hover {
  background: #f2f2f2;
}

.ProseMirror-menu-submenu-wrap {
  position: relative;
  margin-right: -4px;
  min-width: 65px;
}

.ProseMirror-menu-submenu-label:after {
  content: '';
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid currentColor;
  opacity: 0.6;
  position: absolute;
  right: 4px;
  top: calc(50% - 4px);
}

.ProseMirror-menu-submenu {
  display: none;
  min-width: 5em;
  left: 100%;
  top: -3px;
}

.ProseMirror-menu-active {
  background: #eee;
  border-radius: 4px;
}

.ProseMirror-menu-active {
  background: #eee;
  border-radius: 4px;
}

.ProseMirror-menu-disabled {
  opacity: 0.3;
}

.ProseMirror-menu-submenu-wrap:hover .ProseMirror-menu-submenu,
.ProseMirror-menu-submenu-wrap-active .ProseMirror-menu-submenu {
  display: block;
  z-index: 2147483647;
}
.ProseMirror-menubar-wrapper {
  position: relative;
}
.ProseMirror-menubar {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border: 1px solid #d7d7d7;
  position: absolute;
  min-height: 1em;
  color: #666;
  padding: 1px 6px;
  bottom: 100%;
  left: 0;
  margin-top: -24px;
  border-bottom: 1px solid silver;
  background: white;
  z-index: 10;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: visible;
  display: none;
}

.ProseMirror-widget {
  color: #bebebe;
}

.ProseMirror-menubar-visible {
  display: block;

  & + .ProseMirror {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .ProseMirror-widget {
      display: none;
    }
  }
}

.ProseMirror-icon {
  display: inline-block;
  line-height: 0.8;
  vertical-align: -2px; /* Compensate for padding */
  padding: 2px 8px;
  cursor: pointer;
}

.ProseMirror-icon-underline {
  position: relative;
  top: -2px;
}

.color .ProseMirror-icon-underline {
  position: static;
  top: 0;
}
.ProseMirror-menu-disabled.ProseMirror-icon {
  cursor: default;
}

.ProseMirror-icon svg {
  fill: currentColor;
  height: 1em;
  width: 13px;
}

.ProseMirror-icon span {
  vertical-align: text-top;
}
.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}

.ProseMirror-gapcursor:after {
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid black;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}
/* Add space around the hr to make clicking it easier */

.ProseMirror-example-setup-style hr {
  padding: 2px 10px;
  border: none;
  margin: 1em 0;
}

.ProseMirror-example-setup-style hr:after {
  content: '';
  display: block;
  height: 1px;
  background-color: silver;
  line-height: 2px;
}

.ProseMirror ul,
.ProseMirror ol {
  padding-left: 30px;
}

.ProseMirror blockquote {
  padding-left: 1em;
  border-left: 3px solid #eee;
  margin-left: 0;
  margin-right: 0;
}

.ProseMirror-example-setup-style img {
  cursor: default;
}

.ProseMirror-prompt {
  background: white;
  padding: 5px 10px 5px 15px;
  border: 1px solid silver;
  position: fixed;
  border-radius: 3px;
  z-index: 1000;
  box-shadow: -0.5px 2px 5px rgba(0, 0, 0, 0.2);
}

.ProseMirror-prompt h5 {
  margin: 0;
  font-weight: normal;
  font-size: 100%;
  color: #444;
}

.ProseMirror-prompt input[type='text'],
.ProseMirror-prompt textarea {
  background: #eee;
  border: none;
  outline: none;
}

.ProseMirror-prompt input[type='text'] {
  padding: 0 4px;
}

.ProseMirror-prompt-close {
  position: absolute;
  left: 2px;
  top: 1px;
  color: #666;
  border: none;
  background: transparent;
  padding: 0;
}

.ProseMirror-prompt-close:after {
  content: 'âœ•';
  font-size: 12px;
}

.ProseMirror-invalid {
  background: #ffc;
  border: 1px solid #cc7;
  border-radius: 4px;
  padding: 5px 10px;
  position: absolute;
  min-width: 10em;
}

.ProseMirror-prompt-buttons {
  margin-top: 5px;
  display: none;
}
#editor,
.editor {
  background: white;
  color: black;
  background-clip: padding-box;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 5px 0;
  margin-bottom: 23px;
}

.ProseMirror h1:first-child,
.ProseMirror h2:first-child,
.ProseMirror h3:first-child,
.ProseMirror h4:first-child,
.ProseMirror h5:first-child,
.ProseMirror h6:first-child {
  margin-top: 10px;
}

.ProseMirror {
  width: 100%;
  box-shadow: none;

  display: block;
  padding: 6px 12px;
  color: #555555;
  font-size: 13px;
  font-family: 'Open sans', sans-serif;
  background-color: #fff;
  border: 2px solid #ededed;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s;
  resize: none !important;

  position: relative;
  word-wrap: break-word;
  white-space: pre-wrap;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;

  &:focus {
    border: 2px solid #6fdbe8;
  }
}

.prosemirror-suggestion {
  color: rgb(0, 120, 215);
}

.prosemirror-mention {
  &-node {
    color: #6fdbe8;
  }

  &-container {
    margin-top: 7px;
    background: white;
    color: #555555;
    font-size: 14px;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    min-width: 120px;
    max-width: 265px;
    padding: 5px 0;
    max-height: 160px;
    overflow: auto;
  }

  &-attention {
    padding: 0 5px;
  }

  &-item {
    padding: 5px 10px;
    border-left: 3px solid transparent;
    padding: 4px 15px 4px 8px;
    cursor: pointer;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    &.prosemirror-mention-item-active {
      border-left: 3px solid #59d6e4;
      background-color: #f7f7f7 !important;
    }
  }
}

.prosemirror-mention-loader {
  width: 100%;
  text-align: center;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  &-dot {
    background-color: #6fdbe8;
    width: 13px;
    height: 13px;
    margin-right: 5px;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;

    &:first-child {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ProseMirror-emoji {
  width: 17px;
  position: relative;
  top: 4px;
  margin: 0 4px;
}

.ProseMirror-emoji-dropdown:nth-child(2) {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 209px;
  transform: translateX(-60px);
  padding-top: 5px;
  padding-left: 5px;
  max-height: 137px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.ProseMirror-emoji-dropdown img {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.ProseMirror-emoji-dropdown .ProseMirror-menu-dropdown-item {
  padding: 0;
}
.ProseMirror-emoji-dropdown .ProseMirror-icon {
  padding: 0;
}

blockquote {
  padding-left: 1em;
  border-left: 3px solid #eee;
  margin-left: 0;
  margin-right: 0;
}

.ProseMirror-prompt {
  z-index: 2000;
}
