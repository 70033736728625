.avatar {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &NotDefault {
    width: 100%;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
  }
}

.hidden {
  visibility: hidden;
}
