@keyframes likezooming {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

.likeButton {
  font-size: 11px;
  font-weight: 700;
  color: #7a7a7a;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
  display: flex;
  position: relative;
  top: 2px;

  &:hover {
    color: #555555;
  }

  &Like {
    display: flex;
  }
}

.removeLikeIcon {
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.likeIcon {
  &WithAnimation {
    animation: likezooming 0.25s linear;
  }

  &Wrapper {
    width: 15px;
    // color: red;
    img {
      width: 100%;
    }
  }
}
