.table {
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
  .th {
    width: auto;
  }
  .td {
    width: auto;
  }

  @media screen and (max-width: 555px) {
    width: 488px;
  }
}

.tableOfMembers {
  td {
    &:nth-child(3) {
      min-width: 120px;
    }
    &:nth-child(4) {
      min-width: 100px;
    }
    &:nth-child(5) {
      min-width: 100px;
    }
    &:nth-child(6) {
      min-width: 150px;
    }
  }
}