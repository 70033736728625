.input {
  margin-bottom: 15px;

  & + div i {
    font-size: 17px;
  }
}

.checkboxes {
  margin-left: -5px;
}
.separator {
  margin-top: 30px;
  margin-bottom: 10px;
  border-color: #fff;
  opacity: 0.3;
}

.addPreparedQuestionBtn {
  margin-top: 10px;
}

.addQuestionBtn {
  margin-bottom: 20px;
}

.allowAddSuggestion {
  margin-top: 20px;
  margin-left: 0;
}

.mt2 {
  margin-top: 15px;
}

.allowAddSuggestionPreparedQuestion {
  margin: -2px 0 20px 5px;
}

.addSuggestionContainer {
  display: flex;
  flex-direction: row;
}

.addSuggestionContainer > i {
  margin-left: 20px;
}

.preparedQuestionWrapper {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    margin-top: 0;
  }

  .indicator {
    height: 1px;
    margin-right: 16px;
    flex: 1 1;
    border-top: 1px solid red;
  }
  .moveButtonsWrapper {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}

.preparedQuestionContainer {
  display: flex;
  flex-direction: row;
  margin-left: 9px;
}

.preparedQuestionOption {
  width: fit-content;
  margin: 5px 0 15px 5px;
  padding: 5px 10px 5px 10px;
  background: #708fa0;
  color: white;
  border-radius: 3px;
}

.arrowIconButton {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #eee;
  border: none;

  i {
    font-size: 17px;
    color: #555;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
}

.reminderContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reminderValueInput {
  min-width: 40px;
}

.reminderTypeSelect {
  flex: 1;
  min-width: 300px;
  max-height: 2.5em;
  margin-top: 0;

}

.reminder {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  margin-bottom: 1em;
  gap: 8px;
}
