.card {
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 30px;
    background-color: #fff;
    border: none;
}

.card_body {
    padding: 30px;
}

.btn_primary {
    border-color: #5a8dee !important;
    background-color: #5a8dee !important;
    color: #fff;
}

.form_submit {
    padding: 13px 30px;
    font-size: 15px;
    letter-spacing: 0.3px;
    font-weight: 400;
}

.kb_data_box {
    width: 100%;
    flex: 1;
    margin-top: 15px;
}

.kb_modal_data_title {
    margin-bottom: 10px;
}

.kb_data_title h6 {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
}

.kb_file_upload {
    margin-bottom: 20px;
}

.file_upload_box {
    border: 1px dashed #b6bed1;
    background-color: #f0f2f7;
    border-radius: 4px;
    min-height: 150px;
    position: relative;
    overflow: hidden;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8194aa;
    font-weight: 400;
    font-size: 15px;
}

.file_upload_box .file_upload_input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.file_link {
    color: #475f7b;
    text-decoration: underline;
    margin-left: 3px;
}

.file_upload_box .file_link:hover {
    text-decoration: none;
}

.file_atc_box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.file_image {
    /* width: 130px;
    min-width: 130px;
    height: 85px;
    min-height: 85px; */
    width: 130px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px;
}

.file_image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
}

.file_detail {
    flex: 1;
    width: calc(100% - 210px);
}

.file_detail h6 {
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
}

.file_detail p {
    font-size: 12px;
    color: #8194aa;
    line-height: initial;
    font-weight: 400;
    margin-bottom: 8px;
}

.file_actions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}

.file_action_btn {
    font-size: 12px;
    color: #8194aa;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-right: 15px;
    cursor: pointer;
}

.file_action_btn:hover {
    color: #3d546f;
    text-decoration: underline;
}

.file-atc_box:last-child {
    margin-bottom: 0;
}

.first_article_heading {
    background-color: #df0022;
    color: white;
    height: 50px;
    padding-left: 14px;
    padding-top: 12px;
    font-weight: 700;
    font-size: larger;
    margin-bottom: 25px;
}


.textArea {
    margin-bottom: 25px;
}

.first_article_title {
    margin-bottom: 5px;
}

.captionInput {
    border-radius: 6px;
    width: 300px;
    height: 39px;
}

.buttonsContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
  
    .buttonSubmit {
        margin-right: 10px;
    }
}
  
.containerEnable {
    padding: 10px;
    text-align: center;
}

.backButton {
    background: #e2001A !important;
    margin-left: 15px;
    margin-top: 5px;
}

.submitButton {
    background: #e2001A !important;
    color: white !important;
}

.editButton {
    background: #e2001A !important;
    margin-top: 15px;
}

.box {
    margin-left: 20rem;
    width: 35rem;
}

.description {
    white-space: pre-wrap;
}

.noArticle {
    height: 50px;
    background: lightgray;
    border-radius: 6px;
    padding: 14px;
}

@media (max-width: 767px) {
    .first_article_heading {
        height: 62px;
    }

    .box {
        margin-left: 2rem;
        width: 15rem;
    }
}

@media (max-width: 1000px) and (min-width: 768px) {
    .first_article_heading {
        height: 62px;
    }

    .box {
        margin-left: 5rem;
        width: 25rem;
    }
}

.editorShow {
    display: block;
}

.editorNoShow {
    display: none;
}

.editText {
    display: flex;
    border-style: dotted;
    border-radius: 10px;
    padding: 5px;
}

/* carouselStyles.css */
.carousel .slide .img {
    width: 100%;
    height: 22rem; /* Set your desired height */
    object-fit: contain; /* Ensures the image covers the area without distortion */
  }
  

  .pdfThumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    color: #e74c3c;
    font-weight: bold;
    font-size: 14px;
  }
  
  .pdfThumbText {
    text-align: center;
  }
  
  .pdfSlide {
    height: 28rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pdfSlide canvas {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
