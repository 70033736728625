.button {
  margin-right: 5px;
  &s {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.p {
  margin-bottom: 10px;
}
