.cwnavigationContainer {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.calendarWeek {
    text-align: center;
    vertical-align: center;
    display: flex;
    align-self: center;
    margin: 0 13px 0 13px;
}

.foodContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.secondnav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mr2 {
    margin-right: 15px;
}

.hr {
    background-color: lightgray;
    height: 1px;
    border: 0;
}

.mealItem {
    width: 47%;
    border-radius: 8px;
    border: 2px solid #ebebeb;
    -webkit-box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.39);
    box-shadow: 5px 5px 12px -4px rgba(0, 0, 0, 0.3);
    margin: 0 13px 16px 0;
}

.mealItemHeader {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 13px;
    width: 100%;
    background-color: #ebebeb;
    display: flex;
    flex-direction: row;
}

.mealItemBody {
    padding: 13px;
}

.orderedFood {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}


.showFurtherInformation {
    vertical-align: center;
    padding-top: 11px;
    padding-left: 8px;
    padding-right: 5px;
}

.foodName {
    padding-top: 9px;
}

.orders {
    border: 1px solid lightgray;
    background-color: gray;
    min-width: 55px;
    border-radius: 15px;
    padding: 8px;
    margin-right: 10px;
    color: white;
}

.icon {
    padding-left: 5px;
}

.foodHeader {
    display: flex;
    flex-direction: column;
}

.border {
    background-color: #f4f4f4;
    margin-top: 4px;
    margin-bottom: 6px;
    padding: 8px;
    border: 1px solid #ebebeb;
    border-radius: 3px;
}

@media only screen and (max-width: 730px) {
    .mealItem {
        width: 97%;
    }
}

.foodOrderWeeklyTable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
   table-layout: fixed;
}

.orderedFood {
    overflow: auto;
}

.foodHeaderSub {
    display: flex;
    flex-direction: row;
}