@import 'resources/styles/mixins.scss';

.topbar {
  height: 50px;
  width: 100%;
  padding: 0 15px;
  top: 0;
  z-index: 1;
  background-color: #fff;

  @media (max-width: 845px) {
    padding: 0;
  }

  &Panel {
    @media (max-width: 845px) {
      display: none;
    }
  }

  &NoPanel {
    display: none;
    @media (max-width: 845px) {
      display: block;
      margin-left: 17px;
    }
    @media (max-width: 330px) {
      margin-left: 0;
    }
  }

  &NoStylePanel {
    margin-left: 0;
    transition: none;
  }

  &Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &Brand {
    font-size: 18px;
    line-height: 50px;
    color: #ffffff;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include small-mobile {
      img {
        height: 32px !important;
      }
    }
  }
}
