.memberCard {
  display: flex;
  &__names {
    display: flex;
    flex-direction: column;

    .email {
      font-size: 0.625rem;
      color: #777;
    }
  }
}
