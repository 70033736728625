@import 'resources/styles/mixins.scss';

.loader {
  max-height: 100px;

  @include media-tablet {
    margin-top: 30px;
  }
}

.notFound {
  padding: 10px;
  font-size: 13px;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.backToStream {
  margin-bottom: 20px;
}
.loadMoreWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 32px 0px;
}

.postNotFound {
  background: white;
  padding: 5px;

  &Description {
    font-size: 16px;
    font-weight: 300;
    color: #555;
    border: none;
    padding: 10px;
    border-radius: 4px;
  }

  &Text {
    padding: 5px;
    font-size: 30px;
  }
}

.warningWrapper {
  position: relative;
  padding: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #fff;
  box-shadow: 0 0 3px #dadada;
  border-radius: 4px;
}

.authButton {
  margin-bottom: 8px;
}

.filterSectionHeadline {
  font-size: 22px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: black;
  margin-top: 15px;
  margin-bottom: 15px;
}
