.container {
  margin-bottom: 10px;
  padding: 0 20px;

  .checkboxContainer {
    margin-bottom: 15px;
  }

  .dateAndTimeInputsContainer {
    margin-bottom: 15px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  
    input {
      width: 100%;
      height: 35px;
      box-shadow: none;
      display: block;
      padding: 6px 12px;
      color: #555555;
      font-size: 14px;
      font-family: 'Open sans', sans-serif;
      background-color: #fff;
      border: 2px solid #ededed;
      border-radius: 4px;
      transition: border-color ease-in-out 0.15s;
  
      &:focus {
        border: 2px solid #6fdbe8;
      }
      &.input_border_error {
        border: 2px solid #ff8989;
      }
      &:disabled {
        background-color: #eeeeee;
        color: #eeeeee;
      }
    }
    ::-webkit-input-placeholder {
      color: #c0c0c0;
    }
    ::-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 19+ */
    :-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 18- */
    :-ms-input-placeholder {
      color: #c0c0c0;
    }
  }
  
  .dateInputsContainer {
    display: flex;
    flex-flow: column;
    width: 45%;
  }
  
  .timeInputsContainer {
    display: flex;
    flex-flow: column;
    width: 45%;
  }
  
  .timeInputsContainerDisabled {
    opacity: 0.2;
  }

  .dateStart {
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;
  }
  
  .dateEnd {
    display: flex;
    flex-flow: column;
  }
  
  .timeStart {
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;
  }
  
  .timeEnd {
    display: flex;
    flex-flow: column;
  }
  
  .dateEndError {
    
    input {
      border-color: #ff8989 !important;
    }
  }
  
  .timezoneContainer {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    margin-top: -10px;
    cursor: pointer;
  
    .timezoneTitle {
      color: #6fdbe8 !important;
      font-size: 11px;
    }
  
    .timezoneDropdown {
      width: 45%;
  
      .timezoneSelect {
        width: 100%;
        height: 35px;
        font-size: 13px;
      }
  
      .timezoneSelectOpen {
        overflow: auto;
        max-height: 200px;
        font-size: 13px;
        width: 100%;
      }
    }
  }
}