@import 'resources/styles/mixins.scss';

.popupsContainer {
  display: flex;
  position: absolute;
  top: 10px;
  left: 56%;
  column-gap: 3px;

  @include media-mobile {
    position: static;
    margin-left: 45px;
  }

  @include small-mobile {
    margin-left: 2px;
  }

  @media (max-width: 330px) {
    margin-left: 1px;
    column-gap: 2px;
  }

  .action {
    width: 34px;
    height: 30px;
    border-radius: 3px;
    background-color: #afcb05;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .employeeTimeBtn {
    font-size: 18px;
  }
}
