.container {
  margin-bottom: 10px;
  padding: 0 20px;
}

.usersInput {
  margin-bottom: 15px;
}

.chatNameContainer {
  margin-bottom: 15px;
}

.messageContainer {
  margin-bottom: 15px;
  margin-top: 0;
  transition: margin-top 0.05s;

  .textareaContent {
    min-height: 100px;
  }

  &Error {
    .textareaContent {
      border: 2px solid #ff8989;
    }
  }
}

.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.buttonCancel {
  margin-right: 10px;
}

.uploadButton {
  height: 36px;
  width: 47px;
  font-size: 15px;
  background-color: #ededed;
  color: #777;

  &:hover {
    background-color: #ededed;
  }
}

.progressBar {
  background: #ededed;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  margin: 10px 0px;
  overflow: hidden;

  &Value {
    background: #6fdbe8;
    transition: width 0.6s ease;
    height: 100%;
  }
}

.buttonWithLoader {
  background: #257D9D;
  color: #fff !important;

  &:hover {
    background: #257D9D;
  }
}

.fileButtonWrapper {
  display: flex;
  gap: 5px;
  .voiceButton {
    display: flex;
    height: 36px;
  }
}

.filePreviewContainer {
  display: flex;
  flex-direction: column;
  ul {
    padding: 0;
  }
}
