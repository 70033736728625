.participants {
  margin-top: 18px;

  &Title {
    font-weight: 700;
    color: #777777;
    font-size: 13px;
  }

  &Count {
    color: #777777;
    font-size: 13px;
    margin-bottom: 10px;
    user-select: none;

    &Clickable {
      color: #6fdbe8;
      cursor: pointer;
    }
  }

  &Button {
    margin-right: 3px;

    &s {
      margin-bottom: 9px;
    }

    &CheckIcon {
      font-size: 14px;
      margin-right: 5px;
    }
  }
}