.cwnavigationContainer{
  display: flex;
  flex-direction: row;
}
.calendarWeek{
  text-align: center;
  vertical-align: center;
  display: flex;
  align-self: center;
  margin: 0 13px 0 13px;
}
.foodContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.secondnav{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mr2{
  margin-right: 15px;
}

.hr{
  background-color: lightgray;
  height: 1px;
  border: 0;
}
