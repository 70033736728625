.container {
  position: relative;
}

.title {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 300;
}

.description {
  font-size: 12px;
  color: #bac2c7;
}

.text {
  margin-bottom: 20px;
}

.groupAvatar {
  display: flex;
}

.groupName {
  font-size: 13px;
}

.loader {
  height: 17px;
}

.table {
  border-collapse: collapse;
  font-size: 13px;
  width: 100%;
  white-space: nowrap;

  &Wrapper {
    overflow-x: auto;
  }

  thead {
    tr {
      th {
        text-align: left;
        font-size: 13px;
        font-weight: bold;
        padding: 10px 10px 10px 0;
        white-space: nowrap;
      }
    }
  }
  tbody {
    tr {
      border-top: 1px solid #ddd;
      td {
        padding: 10px 10px 10px 0;
        white-space: nowrap;
      }
      &:hover {
        background: #f5f5f5;
      }
    }
  }
}
.headerText {
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;

  &Container {
    display: flex;
    align-items: center;
  }

  &WithPadding {
    padding-left: 44px !important;
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &Text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  &Box {
    font-size: 15px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    margin-right: 10px;
  }
}

.acceptButton {
  padding: 4px 8px;
  font-size: 12px;
  background: #97d271;
  color: #fff !important;
  margin-right: 5px;

  &:hover {
    background: #89cc5e;
    border-color: #398439;
  }
}

.denyButton {
  padding: 4px 8px;
  font-size: 12px;
  background: #ff8989;
  color: #fff !important;
  margin-left: 3px;

  &:hover {
    background: #ff6f6f;
    border-color: #ac2925;
  }
}

.emailContainer {
  width: 25%;
}

.thEmployee {
  width: auto;
  font-size: 100%;
  color: black;
}

.textAreaHeader {
  width: 40%;
  height: 35px;
  border: double;
}

.colorPicker {
  z-index: 2;
  top: 0;
  left: 0;
  .label {
    width: 40px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ededed;
    margin-bottom: 2px;
    border-radius: 2px 0px 0px 2px;
    cursor: pointer;
    .current {
      width: 16px;
      height: 16px;
    }
  }
  .picker {
    background: white;
    width: 130px;
    height: 146px;
    position: absolute;
    padding: 6px 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    &:before {
      position: absolute;
      content: '';
      border: 8px solid transparent;
      border-bottom: 8px solid #dedede;
      z-index: 2;
      bottom: 100%;
      left: 5px;
    }
    &:after {
      position: absolute;
      content: '';
      border: 6px solid transparent;
      border-bottom: 6px solid white;
      z-index: 2;
      bottom: 100%;
      left: 7px;
    }
    .currentColor {
      width: 100%;
      height: 9px;
    }
  }

}


.relativeContainer {
  position: relative;
}

.absoluteContainer {
  position: absolute;
}