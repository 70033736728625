

.inputTags {
  border: 2px solid #ededed;
  min-height: 40px;
  border-radius: 4px;
  background: transparent;
  cursor: text;
  display: flex;
  padding: 2px 2px;
  flex-wrap: wrap;
  justify-content: flex-start;

  .isSmall & {
    min-height: 35px;
  }
  &_focused {
    border: 2px solid #6fdbe8;
  }
  &_error {
    border: 2px solid #ff8989;
  }
  &__item {
    padding: 2px;
    font-size: 12px;
    color: white;
    align-items: center;
    justify-content: space-between;
    display: flex;
    background: #6fdbe8;
    height: 28px;
    border-radius: 4px;
    position: relative;
    padding-right: 7px;
    margin: 2px;

    .isSmall & {
      height: 23px;
    }

    .photo {
      width: 24px;
      height: 24px;
      margin-right: 5px;
      border-radius: 3px;
      overflow: hidden;

      .isSmall & {
        width: 18px;
        height: 18px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .name {
      padding-bottom: 4px;
      font-weight: 700;

      .isSmall & {
        padding-bottom: 3px;
      }
    }
    .delete {
      margin-left: 4px;
      margin-bottom: 4px;
      cursor: pointer;

      .isSmall & {
        margin-bottom: 0;
      }
    }
  }
}