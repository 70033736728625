.header {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;
  .name {
    width: 40%;
    padding-left: 80px;
  }
  .status {
    width: 60%;
  }
}
.searchInputWrap {
  margin: 10px 0;
  input {
    width: 60%;
  }
}

.dateInput {
  input {
    width: 100px;
    height: 35px;
    box-shadow: none;
    display: block;
    padding: 6px 12px;
    color: #555555;
    font-size: 14px;
    font-family: 'Open sans', sans-serif;
    background-color: #fff;
    border: 2px solid #ededed;
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s;

    &:focus {
      border: 2px solid #6fdbe8;
    }
    &.input_border_error {
      border: 2px solid #ff8989;
    }
    &:disabled {
      background-color: #eeeeee;
      color: #eeeeee;
    }
  }
}
