.actionsContainer {
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 117px;
    .action {
      width: 34px;
      height: 30px;
      border-radius: 3px;
      background-color: #ededed;
      color: #7a7a7a;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:first-child {
        margin-right: 2px;
      }
      &:hover {
        background-color: #d7d7d7;
      }
    }
  
}
.moreBtn {
    width: 29px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
  
    &:hover {
      background-color: #eeeeee;
    }
  
    &.active {
      background-color: #708fa0;
      i {
        color: #fff;
      }
    }
  
    i {
      color: #000000;
    }
}
  
  