@import 'resources/styles/mixins.scss';

.bottombar {
  background: #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #d4d4d4;
  height: 50px;
  position: relative;
  z-index: -1;

  &Panel {
    @media (max-width: 845px) {
      display: none;
    }
  }

  &NoPanel {
    display: none;

    @media (max-width: 845px) {
      display: block;
      margin-left: 17px;
    }

    @media (max-width: 330px) {
      margin-left: 0px;
    }
  }

  &Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Nav {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.desktopNav {
  display: flex;

  @include media-tablet {
    display: none;
  }
}

.awoDektopNav {
  display: none;
}

.mobileNav {
  display: none;

  @include media-tablet {
    display: flex;
  }
}

.awoMobileNav {
  display: flex;
}

.avatarContainer {
  background-color: #6fdbe8;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 2px;
  margin-right: 3px;
  margin-top: 3px;
  color: #ffffff;
  overflow: hidden;
}

.groupNavContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}