.container {
  .text {
    text-align: center;
    font-size: 13px;
    padding: 21px;
  }

  .radioButtonsContainer {
    margin-bottom: 10px;
    padding-left: 15px;
    display: flex;
    flex-flow: column;
    font-size: 12px;
  
    .radioButton {
      margin: 0;
    }
  }
}

.footerNavigation {
  display: flex;
  justify-content: center;
  & > div {
    margin: 0 5px;
  }
}