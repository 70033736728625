.item {
  border-left: 3px solid;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;

  &.themeYellow {
    border-left-color: #708fa0;
    background-color: #afcb05;
    color: #fff;

    &:hover,
    .active & {
      border-left-color: #6fdbe8;
      background-color: #628394;
    }
  }

  &.themeDark {
    border-left-color: #708fa0;
    background-color: #708fa0;
    color: #fff;

    &:hover,
    .active & {
      border-left-color: #6fdbe8;
      background-color: #628394;
    }
  }

  &.themeLight {
    border-left-color: #fff;
    background-color: #fff;
    color: #555;

    &:hover,
    .active .div & {
      border-left-color: #6fdbe8;
      background-color: #f7f7f7;
    }
  }
}
.active div {
  border-left-color: #6fdbe8 !important;
  background-color: #f7f7f7 !important;
}
.activeAwoWW div {
  border-left-color: #e2001A !important;
}
