.loader {
  height: 17px;
}

.container {
  padding: 4px 10px 0;
}

.dropdownActions {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.dropdownContainer {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.table {
  border-collapse: collapse;
  font-size: 13px;
  width: 100%;
  white-space: nowrap;

  &Wrapper {
    overflow-x: auto;
  }

  thead {
    tr {
      th {
        text-align: left;
        font-size: 13px;
        font-weight: bold;
        padding: 10px 10px 10px 0;
        white-space: nowrap;
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid #ddd;
      cursor: pointer;

      td {
        padding: 10px 10px 10px 0;
        white-space: normal;
      }

      &:hover {
        background: #f5f5f5;
      }
    }
  }
}

.headerText {
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;

  &Container {
    display: flex;
    align-items: center;
  }

  &WithPadding {
    padding-left: 44px !important;
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &Text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  &Box {
    font-size: 15px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    margin-right: 10px;
  }
}

.actionButtonsWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}