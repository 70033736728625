.container {
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  padding-top: 0px;

  .bottomSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.4s padding-bottom;

    &Button {
      font-size: 11px;
      font-weight: 700;
      color: #7a7a7a;
      margin-bottom: 0;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: #555555;
      }
    }

    &Separator {
      margin: 0 3px;
    }
  }

  .commentsIsOpened {
    padding-bottom: 10px;
  }

  .comments {
    display: flex;

    &Count {
      margin-left: 3px;
    }
  }
}
