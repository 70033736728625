@import 'resources/styles/mixins.scss';

.container {
  padding: 10px;
  display: flex;

  @include media-mobile {
    flex-direction: column;
    align-items: flex-end;
  }

  .editorContainer {
    min-height: 36px;
    flex-grow: 1;

    @include media-mobile {
      width: 100%;
    }
  }

  .textareaContent {
    min-height: 36px;
  }

  .editorContainerError {
    .textareaContent {
      border: 2px solid #ff8989;
    }
  }

  .buttonsContainer {
    display: flex;
    margin-left: 4px;

    @include media-mobile {
      display: block;
      margin-top: 4px;
    }
  }
}

.buttonSend {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.uploadButton {
  height: 36px;
  font-size: 15px;
  width: 47px;
  background-color: #EDEDED;
  color: #7A7A7A;
  margin-right: 5px;

  &:hover {
    background-color: #EDEDED;
  }
}

.voiceButton {
  margin-right: 5px;
  display: flex;
  height: 36px;
}

.progressBar {
  background: #ededed;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  margin: 10px 0px;
  overflow: hidden;

  &Value {
    background: #6fdbe8;
    transition: width 0.6s ease;
    height: 100%;
  }
}

.buttonWithLoader {
  background: #257D9D;
  color: #fff !important;
  height: 36px;

  &:hover {
    background: #257D9D;
  }
}

.fileContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 10px 10px 10px;
}
