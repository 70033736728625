.dropdown {
    position: absolute;
    z-index: 1601;
    top: calc(100% + 2px);
    left: 0;
    min-width: 160px;
    padding: 5px 0;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  
  }
  
  .listItem {
    i {
      width: 22px;
    }
    & > div {
      font-size: 13px;
    }
  }
  
  .separator {
    background-color: #afcb05;
    height: 1px;
    margin: 9px 1px;
    border-left: 3px solid #afcb05;
  }
  