.link {
  margin: 3px 0;
  padding: 10px;
  border-bottom: 1px solid #eee;
  position: relative;
  border-left: 3px solid white;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:hover {
    background-color: #f7f7f7;
    border-left: 3px solid #6fdbe8;
  }

  &.activeAwoWW {
    &:hover {
      border-left: 3px solid #e2001A;
    }
  }

  &Wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  &Title {
    font-size: 13px;
    color: #555;
    text-align: left;
  }

  &Description {
    color: #bebebe;
    font-size: 11px;
    font-weight: 400;
    text-align: left;

    &Short {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}
