@import 'resources/styles/mixins.scss';

.suggestionBoxIcon {
  width: 100%;
}

.content {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 80%;
  background-color: #e3eef2;
  border-radius: 6px;
  float: right;
  padding: 12px;

  @include media-mobile {
    width: 90%;
  }

  .avatarContainer {
    margin-right: 10px;
    padding-right: 2px;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 3px;
      overflow: hidden;
    }
  }

  .messageContainer {
    display: flex;
    flex-flow: column;
    font-size: 14px;
    font-weight: 500;
    color: #555;

    .header {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      margin-bottom: 5px;

      .userInfo {
        margin-bottom: 5px;
        margin-right: 3px;
        padding-right: 3px;
      }

      .date {
        font-size: 11px;
        color: #bebebe;
        //margin: 3px;
        margin-right: 10px;
      }
    }

    .text {
      font-size: 13px;

      ol,
      ul {
        padding-left: 30px;
      }
    }
  }

  .editButton {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 12px;
  }

  .readReceiptButton {
    cursor: pointer;
  }
}

.othercontent {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 80%;
  background-color: #f3f4f6;
  border-radius: 6px;
  padding: 12px;

  @include media-mobile {
    width: 90%;
  }

  .avatarContainer {
    margin-right: 10px;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 3px;
      overflow: hidden;
    }
  }

  .messageContainer {
    display: flex;
    flex-flow: column;
    font-size: 14px;
    font-weight: 500;
    color: #555;

    .header {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      margin-bottom: 5px;

      .userInfo {
        margin-bottom: 5px;
        margin-right: 3px;
        padding-right: 3px;
      }

      .date {
        font-size: 11px;
        color: #bebebe;
        //margin: 3px;
      }
    }

    .text {
      font-size: 13px;

      ol,
      ul {
        padding-left: 30px;
      }
    }
  }

  .editButton {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 12px;
  }
}

.combineDiv {
  display: flex;
  flex-direction: column;
}
