.invalidLinkContainer {
  height: 100vh;
  background-image: linear-gradient(#043B7E 0%, #024C97 10%, #0360AE 20%, #0170BF 30%, #0081D2 40%, #0199EF 50%, #1EADFC 60%, #45C1FD 70%, #4EC7FF 80%, #54C9FF 90%, #60CCFB 100%);


}

.awowwbottombutton {
  font-size: 16px;
  width: 80%;
}

.heading {
  width: 100%;
  height: 50px;
  background-color: #708fa0;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: 135px;
}

.sign_btn,
.back_to_home_btn {
  height: 36px;
  padding: 0 14px;
  cursor: pointer;
  background: #7f9baa;
  border-radius: 3px;
  border: 0;
}

.sign_btn_hover:hover,
.back_to_home_btn_hover:hover {
  background: #286090;
  cursor: pointer;
}

.btn_text {
  font-family: Open Sans sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.card {
  margin: 100px auto;
  padding: 15px;
  max-width: 330px;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.heading_1 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 22px;
  color: #555;
}

.heading_2 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding-top: 15px;
  color: #555;
}

.back_btn_text {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}

.back_to_home_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: #000000;
  margin-right: 10px;
  font-size: 12px;
  text-decoration: underline;

  &:last-child {
    margin-right: 0;
  }

  &s {
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}