/* AuthorizedPeople.module.scss */

.authorizedPeopleContainer {
    width: 100%;
  }

  .authorizedPeopleHeader {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    column-gap: 20px;
  }

  .authorizedPersonCard {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;

    .personImage {
      img {
        max-width: 60px;
        max-height: 60px;
      }
    }

    .buttonColumn {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > * {
        margin-bottom: 5px;
      }
    }
  }
