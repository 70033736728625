@import 'resources/styles/mixins/textEditor.scss';

ul,
ol {
  padding: revert;
}
.text {
  @include textEditor;

  &Container {
    position: relative;

    &Inner {
      &Opened {
        max-height: none;
      }
    }

    &AlignedButton {
      z-index: 1;
      border: 1px solid #c4c4c4;
      margin: 10px 0px;
    }
    &Button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -15px;
      z-index: 1;
      border: 1px solid #c4c4c4;
    }
    a {
      color: #385898;
      :hover {
        color: #3b5998;
      }
      :visited {
        color: #6d84b4;
      }
    }
  }
}
