.titleIcon {
  font-weight: 700;
}

.container {
  padding: 10px;
  overflow: hidden;

  :global(.blueimp-gallery-carousel) {
    box-shadow: none;
    margin: 0;
  }
}
