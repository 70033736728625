.container {
    font-size: 13px;
    padding: 21px;
  }
  
  .footerNavigation {
    display: flex;
    justify-content: center;
    & > div {
      margin: 0 5px;
    }
  }

  .row{
    display: flex;
    margin-bottom: 10px;
  }

  .row > * {
    margin-right: 10px;
  }