.templateLine {
  .headerLabel {
    padding: 5px 10px;
    background-color: grey;
    color: white;
    font-weight: bold;
    border: 1px solid grey;
    border-radius: 5px;
  }

  .detailsWrapper {

    .detailLabel {
      font-weight: bold;
    }

    .templateDetails {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
