.container {
  padding-top: 10px;

  .title {
    font-size: 16px;
    font-weight: 300;
    color: #555;
    margin-bottom: 20px;
    padding: 0 10px;
  }
}