.inputTable {
  margin-bottom: 1em;
  table-layout: fixed;
  width: 100%;

  tr {
    .inputTableLeft {
      width: 30%;
      font-size: 16px;
      font-weight: bold;
    }
    .inputTable:hover {
      cursor: default !important;
    }

    .cellHeader {
      width: 100%;
    }
    .cellHeader > label {
      font-weight: bold;
      width: 100%;
    }
  }

  tr,
  td {
    padding: 0;
    margin: 0;

    // [contenteditable][placeholder]:empty:before {
    //   content: attr(placeholder);
    //   position: absolute;
    //   background-color: transparent;
    // }

    font-size: 16px;
    border: 1px solid #ccc;
    white-space: pre-line;
    td:hover {
      cursor: text;
    }
  }
}
