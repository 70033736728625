.container {
  margin-bottom: 10px;
  padding: 0 20px;

  .asignedUsersContainer {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 15px;

    .asignedUsersDesctiption {
      color: #aeaeae !important;
      margin-top: 5px;
      font-size: 12px;

      &:hover {
        color: #7a7a7a !important;
      }
    }

    .selectMeButton {
      color: #6fdbe8;
      font-size: 12px;
      width: fit-content;
      align-self: flex-end;
      cursor: pointer;
    }
  }

  .responsibleUsersContainer {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 15px;

    .selectMeButton {
      color: #6fdbe8;
      font-size: 12px;
      width: fit-content;
      align-self: flex-end;
      cursor: pointer;
    }
  }

  .checkboxContainer {
    margin-bottom: 15px;
  }

  .infoContainer {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 15px;
    
    .infoButton {
      background: #6fdbe8;
      color: #fff !important;
      padding: 1px 5px;
      font-size: 12px;
      width: fit-content;
      align-self: flex-end;
      cursor: pointer;
    }

    .infoDescription {
      margin-top: 5px;
      color: #555;
      background-color: #f7f7f7;
      font-size: 13px;
      padding: 15px;
      border: 1px solid #ededed;
      border-radius: 4px;
    }
  }
}