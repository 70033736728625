.container {
  font-size: 13px;
  padding: 15px;

  .description {
    margin-bottom: 10px;
  }

  .buttonsContainer {
    display: flex;
    flex-flow: row;

    .userProfilesButtonsContainer {
      margin: 0 auto;
    }

    .radioButtonsContainer {
      margin-bottom: 10px;
      padding-left: 15px;
      display: flex;
      flex-flow: column;
      font-size: 12px;
    
      .radioButton {
        margin: 0;
      }
    }
  }

}

.footerNavigation {
  display: flex;
  justify-content: center;
  & > div {
    margin: 0 5px;
  }
}