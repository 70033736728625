.container {
  margin-bottom: 10px;
  padding: 0 20px;
  padding-top: 25px;
}

.inputGroup {
  margin-bottom: 15px;
  position: relative;
}
.dropDown {
  &__title {
    color: #555;
    font-size: 11px;
    position: relative;
    cursor: pointer;
    margin-left: 7px;
    &:before {
      content: '';
      position: absolute;
      border: 3px solid transparent;
      border-left: 4px solid #555;
      right: 100%;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 0;
      width: 0;
    }
  }
  &__content {
    padding-top: 18px;
    margin: 0px -38px;
    display: none;
    .inputGroup {
      display: inline-block;
      width: 172px;
      margin: 0 37px;
    }
  }
  &_opened {
    .dropDown__title {
      &:before {
        transform: rotate(90deg) translate(2px, 2px);
      }
    }
    .dropDown__content {
      display: flex;
    }
  }
}
.inputCheckbox {
  font-size: 13px;
  line-height: 25px;
  padding-left: 33px;
  position: relative;
  label {
    cursor: pointer;
    .mark {
      position: absolute;
      width: 18px;
      height: 18px;
      border: 2px solid #ccc;
      left: 11px;
      top: 0;
      border-radius: 50%;
      bottom: 0;
      margin: auto;
      &:after {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #6fdbe8;
        border-radius: 50%;
        display: none;
      }
    }
  }
  input {
    position: absolute;
    z-index: -2;
    opacity: 0;
    &:checked ~ label .mark:after {
      display: block;
    }
  }
}
.inputWithColorPicker {
  padding-left: 40px;
  input[type='text'] {
    padding-left: 12px;
    border-radius: 0px 4px 4px 0px;
  }
}
.colorPicker {
  position: absolute;
  z-index: 2;
  top: 24px;
  left: 0;
  .label {
    width: 40px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ededed;
    margin-bottom: 2px;
    border-radius: 2px 0px 0px 2px;
    cursor: pointer;
    .current {
      width: 16px;
      height: 16px;
    }
  }
  .picker {
    background: white;
    width: 130px;
    height: 146px;
    position: absolute;
    padding: 6px 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    &:before {
      position: absolute;
      content: '';
      border: 8px solid transparent;
      border-bottom: 8px solid #dedede;
      z-index: 2;
      bottom: 100%;
      left: 5px;
    }
    &:after {
      position: absolute;
      content: '';
      border: 6px solid transparent;
      border-bottom: 6px solid white;
      z-index: 2;
      bottom: 100%;
      left: 7px;
    }
    .currentColor {
      width: 100%;
      height: 9px;
    }
  }
}
.error_msg {
}
