.container {
  width: 100%;
}

.wrapper {
  padding: 0 10px 10px;
}

.title {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 13px;
}

.selectText {
  margin-bottom: 15px;
}

.button {
  &Container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
  }

  &Icon {
    margin-right: 1px;
    font-size: 14px;
  }
}

.noGalleries {
  font-size: 13px;
  color: #777777;
  padding: 0 10px;
}
