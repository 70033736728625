.inputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  border: 1px solid #ededed;
  min-height: 40px;
  margin-top: 4px;
  border-radius: 3px;

  &.inputFocused {
    border: 1px solid #6fdbe8;
  }
  .input {
    display: flex;
    flex: 1;
    height: 30px;
    margin-left: 5px;
    font-size: 13px;
    border: none;
    min-width: 150px;
  }
}

.space {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #6fdbe8;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 3px;
  padding: 3px 3px 3px 3px;
  overflow: hidden;

  img,
  .spaceImg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  .spaceImg {
    background-color: darkkhaki;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spaceName {
    color: white;
    font-size: 12px;
    margin-right: 8px;
  }
  button {
    border: none;
    background: white;
    margin-right: 3px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    cursor: pointer;

    .closeSpace {
      color: #6fdbe8;
      font-size: 14px;
      line-height: 14px;
      margin-left: 0.6px;
    }
  }
}

.availableSpaces {
  z-index: 1;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  .space {
    border-left: 3px solid white;
    background: transparent;
    border-radius: 0;
    &:first-of-type {
      margin-top: 5px;
    }
    &:last-of-type {
      margin-bottom: 5px;
    }
    .spaceName {
      color: #777777;
    }
    img,
    .spaceImg {
      margin-left: 5px;
    }

    &:hover {
      border-left: 3px solid #6fdbe8;
      background: #fafafa;
      cursor: pointer;
    }
  }
}

.loadingContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
}
