.breadcrumbs {
  display: flex;
  justify-content: space-between;
  padding: 8px 15px;
  background-color: #f5f5f5;
  font-size: 13px;
  color: #555555;
  margin-bottom: 10px;
  user-select: none;
  border-radius: 4px;
  box-shadow: 0 0 3px #dadada;
  min-height: 34px;

  &Left {
    display: flex;
  }

  &Home {
    cursor: pointer;
  }

  &Item {
    display: inline;

    &:before {
      padding: 0 8px;
      color: #ccc;
      content: '/\00a0';
    }
  }
}
