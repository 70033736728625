.container {
  margin-bottom: 10px;
  padding: 25px 20px;
}
.inputGroup{
  margin-bottom: 15px;
  position: relative;
}
.error_msg {
  margin-bottom: 10px;
}

.success_msg {
  margin-bottom: 10px;
}

.labelInput {
  white-space: normal;
}
