.fc-button {
  white-space: nowrap;
  box-sizing: border-box;
  height: 2.1em;
  padding: 0 .6em;
  font-size: 1em;
}

.fc-button-primary {
  background-color: #ededed;
  color: #777;
  border-color: #ededed;
}

.fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #d7d7d7;
  border-color: #d7d7d7;
  color: #777;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-button-primary:hover {
  background-color: #d7d7d7;
  border-color: #d7d7d7;
  color: #777;
}

.fc-button-primary:not(:disabled):active:focus, .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-button-primary:disabled {
  background-color: #ededed;
  color: #777;
  border-color: #ededed;
  opacity: 0.65;
}

.fc-button-primary:focus {
  box-shadow: unset;
}