.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 10px
}

.formGroup {
  padding: 24px;
}

.formInput {
  margin-bottom: 24px;
}

.languageDropDown {
  display: flex;
  justify-content: flex-end;

  .chooseLanguageText {
    padding-right: 7px;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #555;
  }

  .chooseLanguage {
    color: #555555;
  }
}

.error_msg {
}
