.publication {
  .dropdownContainer {
    position: relative;

    .button {
      padding: 8px 16px 9px;
      height: 40px;
      font-size: 15px;
      background-color: #ededed;
      color: #777;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }

      .buttonContainer {
        white-space: nowrap;

        .clockIcon {
          font-size: 16px;
        }

        .buttonValue {
          padding-left: 4px;
          white-space: nowrap;
        }
      }
    }
  }

  .timeSelectionDropdown {
    position: absolute;
    z-index: 2;
    width: 240px;
    top: calc(100% + 8px);
    left: 0%;

    @media screen and (max-width: 768px) {
      transform: translateX(-42%);
    }

    &Content {
      padding: 10px;
      display: flex;
      flex-direction: column;
      position: relative;

      .inputContainer {
        position: relative;
        margin-bottom: 8px;

        input[type='text'] {
          width: 100%;
          padding: 6px 5px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 4px;
        }

        .clearIcon {
          position: absolute;
          right: 4px;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          background-color: #f1f1f1;
          cursor: pointer;

          &:hover {
            background-color: #ededed;
          }
        }
      }

      .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
      }
    }
  }
}
