.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.buttonConfirm {
  margin-right: 10px;
}

.buttonCancel {
  margin-left: 10px;
}

.groupList {
  display: flex;
  flex-wrap: wrap;
}

.groupElement {
  width: 49%;
  margin-top: 5px;
  margin-right: 3px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  border-radius: 6px;
}

.groupElement:hover {
  cursor: pointer;
}

.groupName {
  margin-top: 8px;
}

@media screen and (max-width: 700px) {
  .groupElement {
    width: 100%;
  }
}

.groupElementActive {
  @extend .groupElement;
  border: 1px solid black;
}

.groupLogo {
  width: 35px !important;
  height: 35px !important;
}

.kidInfo {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.verificationImagesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verificationImages {
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  img {
    width: 200px;
  }
}

.selectedImage {
  box-shadow: 0 0 1px 4px;
}

.verificationError {
  margin-top: 12px;
  color: red;
  font-size: 14px;
}
