@import 'resources/styles/mixins.scss';

.wrapper {
  width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  transition: all 0.3s ease;

  &Wide {
    width: 1240px;
  }

  @media screen and (max-width: 1350px) {
    &Wide {
      width: 1230px;
    }
  }

  @include media-laptop {
    width: 970px;
  }

  @include media-tablet {
    width: 750px;
  }

  @include media-mobile {
    width: 100%;

    &Wide {
      width: 100%;
    }
  }
}
