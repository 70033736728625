.formGroup {
  margin-bottom: 10px;
}

.buttons {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eeeeee;
}

.error {
  color: #ff8989;
}
