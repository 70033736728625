.login_container {
  max-width: 300px;
  margin: 0 auto 20px;
  padding-bottom: 15px;
  text-align: left;
}

.login_heading_2 {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  padding: 15px 32px 15px 15px;
  color: #555;
}

.login_heading_text_sign_in {
  font-weight: 300;
  color: #555;
}

.login_heading_padding_15 {
  padding: 15px;
}

.horizontal_line {
  height: 1px;
  margin: 15px;
}

.signBtnContainer {
  margin: 0 15px;
  display: flex;
  margin-top: 1.5rem;
  & > * {
    margin-right: 5px;
  }
}

.sign_btn_width {
  width: 76px;
}
.formGroup {
  margin-bottom: 1rem;
}

.sign_in_btn {
  height: 36px;
  background: #708fa0;
  padding: 8px 14px;
  border-radius: 3px;
  cursor: pointer;
}

.sign_in_btn_hover:hover {
  background: #286090;
  cursor: pointer;
}

.sign_in_btn_disabled:disabled {
  height: 36px;
  padding: 8px 14px;
  background: #708fa0;
  opacity: 0.7;
  border-radius: 3px;
  cursor: pointer;
}

.sign_in_btn_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.forget_password_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #6fdbe8;
  cursor: pointer;
}

.password_form {
  padding: 0 15px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #555;
}

.confirmPassword {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #555;
}

.error_msg {
  margin: 0 15px;
}

.password_label {
  padding-left: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #555;
}

.reset_pwd_btn,
.fp_back_btn {
  height: 36px;
  background: #708fa0;
  padding: 8px 14px;
  border-radius: 3px;
  cursor: pointer;
}

.change_pwd_btn_hover:hover,
.fp_back_btn_hover:hover {
  background: #286090;
  cursor: pointer;
}

.change_pwd_btn_disabled:disabled,
.fp_back_btn_disabled:disabled {
  height: 36px;
  padding: 8px 14px;
  background: #708fa0;
  opacity: 0.7;
  border-radius: 3px;
  cursor: pointer;
}

.change_pwd_btn_text,
.fp_back_btn_text {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.error_label {
  color: #ff8989;
}
