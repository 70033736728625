@import 'resources/styles/mixins.scss';

.module {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #eeeeee;
  padding-left: 0px;
  margin-bottom: 15px;
  padding-bottom: 10px;

  .rightSection {
    margin-left: 10px;
    position: relative;

    &__header {
      margin-bottom: 4px;
      display: flex;

      .module__title {
        font-size: 14px;
        font-weight: 500;
        color: #555;
        line-height: 16px;
      }

      .module__activated {
        font-size: 10px;
        color: white;
        background: #97d271;
        border-radius: 3px;
        font-weight: bold;
        font-weight: 600;
        padding: 3px 5px 4px;
        line-height: 1;
        margin-left: 3px;
      }
    }
  }

  &__image {
    width: 64px;
    height: 64px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__description {
    font-size: 13px;
    margin-bottom: 10px;
    color: #555;

    @include media-mobile {
      font-size: 12px;
      height: fit-content;
    }
  }

  &__buttonContainer {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;

    &__bttn {
      cursor: pointer;
      background: #708fa0;
      color: white;
      padding: 4px 9px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 3px;
      text-align: center;
      display: inline-block;
      margin-right: 4px;

      &:hover {
        background: #628394;
      }

      &_configure {
        background: #ededed;
        color: #7a7a7a;

        &:hover {
          background: #e8e8e8;
        }
      }

      @include media-mobile {
        padding: 3px 6px;
        font-size: 11px;
        margin-bottom: 4px;
      }

      .buttonSubmitting {
        height: 17px;
      }
    }
  }


  &:last-child {
    border-bottom: none;
  }
}