.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add some space between the rows */
  padding: 20px;
  background-color: white;
}

.kitaNameHeader {
  font-size: 18px;
  font-weight: bold;
  margin: 16px 0 36px;
}

.articleContainer {
  padding: 0 36px;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add some space between the rows */
}

.rowContainer {
  display: flex;
  justify-content: space-between; /* Distribute buttons evenly */
  gap: 10px; /* Gap between buttons */
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

.buttonStyle {
  flex: 1; /* Equal width for all buttons */
  max-width: 30%; /* Allow up to 3 buttons in a row */
  text-align: center;
  background: #e2001A !important;
  /* height: 180px;  <-- Remove this or switch to min-height */
  min-height: 180px; /* optional, if you want a baseline height but allow growth */
  padding-top: 6.5%;
  white-space: normal;    /* Ensure text can wrap */
  overflow-wrap: break-word; /* Prevent overflow if a word is too long */
}

.buttonEmpty {
  flex: 1; /* Keeps the layout consistent with other buttons */
  max-width: 30%;
}

@media (max-width: 768px) {
  /* Mobile responsiveness */
  .rowContainer {
    flex-direction: column;
  }

  .buttonStyle,
  .buttonEmpty {
    max-width: 100%; /* Full width on mobile */
  }
}

.footerContainer {
  padding: 20px; /* Optional: adjust as needed */
  background-color: white;
}

.rowFooterContainer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 200px; /* Space between the buttons */
  flex-wrap: wrap; /* Ensure the buttons wrap on smaller screens */
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 150px; /* Limit button width on larger screens */
  text-align: center;
}

.footerButton {
  font-size: 24px; /* Adjust icon size */
  padding: 10px;
  background: none !important;
  color: gray !important;
}

.footerSpan {
  margin-top: 8px;
  font-size: 14px;
  color: #df0022;
  font-weight: 600;
}

@media (max-width: 768px) {
  .rowContainer {
    flex-direction: column; /* Stack the buttons vertically on smaller screens */
  }
  
  .buttonWrap {
    max-width: 100%; /* Make sure buttons take full width on small screens */
  }

  .rowFooterContainer {
    gap: 20px;
  }

  .buttonStyle {
    height: 180px;
    min-height:0% ;
  }
}
