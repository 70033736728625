@import 'resources/styles/mixins.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include media-tablet {
    flex-wrap: wrap;
  }
}

.body {
  width: 100%;
  flex: 1;
  background-color: #fff;
  padding-top: 10px;
  box-shadow: 0 0 3px #dadada;
  border-radius: 4px;
  margin-bottom: 20px;
  max-width: calc(100% - 222px);

  &__title {
    font-size: 16px;
    font-weight: 300;
    color: #555;
    margin-bottom: 20px;
    padding: 0 10px;
  }

  @include media-laptop {
    max-width: calc(100% - 167px);
  }

  @include media-tablet {
    max-width: unset;
  }
}

.noTitle {
  padding-top: 0px;
  height: 100%;
  width: 100%;
}

.menu {
  flex: 0 192px;
  margin-right: 30px;

  &__padding_bottom {
    padding-bottom: 10px;
  }

  @include media-laptop {
    flex: 0 137px;
  }

  @include media-tablet {
    flex: 0 100%;
    margin-right: 0;
  }
}

.listItem {
  padding: 6px 15px 5px;
  align-items: flex-start;
  border-left-width: 3px;
  i {
    font-size: 14px;
    width: 18px;
    position: relative;
    top: 1px;
  }

  & > div {
    font-size: 12px;
  }
  &.selected {
    border-left-color: #6fdbe8;
  }
}
