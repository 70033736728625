.container {
  margin-bottom: 0px;
  padding: 0px 10px;
  /* height: 241px; */
  overflow: hidden;
  transition: 0.2s cubic-bezier(0.37, -0.25, 0.47, 1.26);
}
.description {
  p {
    color: #555;
    font-size: 13px;
    margin-bottom: 10px;
  }
  & {
    margin-bottom: 27px;
  }
}
.inputGroup {
  margin-bottom: 25px;
  position: relative;
}
.error_msg {
  position: absolute;
}
.footer {
  display: flex;
  justify-content: space-between;
}
