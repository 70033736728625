.employeeItemContainer {
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid #bdbdbd;
  .employee {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0 20px;
    cursor: pointer;

    .profileAvatar {
      height: 30px;
      width: 30px;
    }
    .profileName {
      width: 150px;
      font-size: 1.125rem;
      font-weight: 600;
    }
    .expandIcon {
      margin-left: auto;
      margin-right: 10px;
    }

    .weeklyWorkingTime {
      padding: 10px;
      background-color: #e1e1e1;
      color: #666;
      border-radius: 3px;
      display: flex;
      align-items: center;

      i {
        margin-right: 5px;
      }
    }
  }

  .workingTimeLabel {
    font-weight: 600;
  }

  .weekContainer {
    border-top: 2px solid #e5e5e5;
    margin-top: 10px;
    padding-top: 10px;
  }

  .weekdayContainer {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e5e5e5;
    padding: 15px 0;
    gap: 10px;

    .weekdayRow {
      display: flex;
      flex-direction: row;
      gap: 25px;
    }

    .weekdayHeading {
      display: flex;

      span.weekdayLabel {
        font-weight: bold;
        font-size: 1.1rem;
        width: 110px;
      }

      span.weekdayHours {
        font-weight: normal;
        font-size: 0.875rem;
        padding: 3px;
        background-color: #e1e1e1;
        color: #666;
        border-radius: 3px;
        margin-left: 10px;
      }
    }

    .workingTime {
      display: flex;
      flex-direction: column;
      gap: 5px;
      > div {
        display: flex;
        align-items: center;
      }

      label {
        width: 100px;
      }
    }

    fieldset {
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #bdbdbd;
    }
  }

  .weekdayContainer:last-child {
    border-bottom: none;
  }

  .highlightError {
    background-color: #ffe6e6;
  }

  .errorMessage {
    padding: 10px 0;
    color: red;
    font-weight: 600;
  }

  .saveButton {
    height: 100%;
    width: 120px;
  }

  .timeRecordingInputsHours {
    padding: 2%;
  }

  .workingTime {
    width: 200px;
  }

  .separator {
    border-top: 2px solid #e5e5e5;
    margin: 10px 0;
  }

  .autoCheckoutAtSevenCheckbox {
    padding: 20px 0;
    margin: 0;
  }

  .overtimeChangeContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    margin: 10px 0;
  }

  .datePickerContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    margin: 10px 0;
    max-width: 500px;

    .caption {
      display: flex;
      align-items: center;
      gap: 10px;
      label {
        font-weight: 600;
      }
    }

    .helpText {
      margin: 0;
    }

    .datePickerWrapper {
      display: flex;
      gap: 5px;
    }

    input {
      width: 104px;
      height: 35px;
      box-shadow: none;
      display: block;
      padding: 6px 12px;
      color: #555555;
      font-size: 14px;
      font-family: 'Open sans', sans-serif;
      background-color: #fff;
      border: 2px solid #ededed;
      border-radius: 4px;
      transition: border-color ease-in-out 0.15s;

      &:focus {
        border: 2px solid #6fdbe8;
      }
      &.input_border_error {
        border: 2px solid #ff8989;
      }
      &:disabled {
        background-color: #eeeeee;
        color: #545454;
      }
    }
    ::-webkit-input-placeholder {
      color: #c0c0c0;
    }
    ::-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 19+ */
    :-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 18- */
    :-ms-input-placeholder {
      color: #c0c0c0;
    }
  }
}

.reviewButtonGroup {
  justify-content: center;
  display: flex;
  gap: 10px;
}
