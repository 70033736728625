@import 'resources/styles/mixins/table.scss';
@import 'resources/styles/mixins.scss';
@include table;

.acceptBttn {
  margin-right: 15px;
}

.tableWrapper {
  overflow: auto;
  @include media-mobile {
    overflow: scroll;
  }
}

.altTable {
  display: none;
  margin-top: 6px;

  @include small-mobile {
    display: flex;
    flex-direction: column;
  }

  &Child {
    display: none;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #d0d0d0;

    @include small-mobile {
      display: flex;
    }
  }

  &Row {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    border-bottom: 1px solid #e6e6e6;
    align-items: center;

    & > div:first-child {
      flex-shrink: 0;
      padding-right: 7px; 
    }

    & > div:last-child {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &RightText {
    color: #525252;
    font-weight: 600;
    font-size: 15px;
  }

  &AcceptBttn, &RejectBttn {
    font-size: 13px;
    padding: 5px 10px;
    margin: 3px 0;
  }

  &RejectBttn {
    margin-left: 7px;
  }
}


.tableOfChildrenDefaultRow {
  @include small-mobile {
    display: none;
  }
}

.table {
  display: initial;
  @include small-mobile {
    display: none;
  }
}
