@import 'resources/styles/mixins.scss';
@import 'resources/styles/mixins/table.scss';
@import 'resources/styles/mixins.scss';
@include table;

.wrapper {
  width: 100%;
  padding: 10px;
  min-height: 200px;
  position: relative;
}

.loader {
  position: absolute;
  top: 0;
}

.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonSubmit {
    margin-right: 10px;
  }
}

.tablePaginationControl {
  display: flex;
  justify-content: center;

  .pages {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;

    .paginationItem {
      //margin-inline: 10px;
      width: 30px;
      height: 30px;
      margin: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
        border-radius: 100%;
      }
    }

    .paginationItemSelected {
      color: #ffffff;
      background-color: #555555;
      font-size: 16px;
      border-radius: 100%;

      &:hover {
        background-color: #555555;
      }
    }
  }

  .pageArrowControl {
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

    .singleChevronRight {
      margin-left: 10px;
    }

    .singleChevronLeft {
      margin-right: 10px;
    }

    .doubleChevronLeft {
      margin-right: 10px;
    }

    .doubleChevronRight {
      margin-left: 10px;
    }

    .singleChevronRight,
    .singleChevronLeft {
      padding: 2px 0;
    }

    .doubleChevronLeft,
    .doubleChevronRight {
      padding: 4px;
      font-size: 12px;
    }

    .doubleChevronLeft,
    .doubleChevronRight,
    .singleChevronRight,
    .singleChevronLeft {
      display: flex;
    }
  }
}

.tableWrapperOverflow {
  overflow-x: auto;
}

.absenceTable {
  display: inline-block;
  width: 100%;
  min-height: 53vh;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;

  th {
    font-size: 11px;
    color: #bebebe;
    font-weight: 700;
    vertical-align: bottom;
    border-bottom: 1px solid #ddd;
    text-align: left;

    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(5) {
      @include media-laptop {
        //width: 100px;
        min-width: 100px;
      }
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      @include media-tablet {
        min-width: 80px;
      }
    }

    &:nth-child(6) {
      @include media-tablet {
        min-width: 150px;
      }
    }
  }

  td {
    border-bottom: 1px solid #ddd;
    font-size: 13px;
    color: #555555;
    max-height: 5vh;
  }

  a {
    color: #555555;
  }

  tr.indexEven td {
    background-color: #ffffff;
  }

  tr.indexOdd td {
    background-color: #ebebeb7c;
  }

  tr.noReturnReceipt td {
    color: #bd081c;
    font-weight: bold;
  }

  tr.returnReceipt td {
    color: #02b875;
    font-weight: bold;
  }

  tr.noReturnReceipt a {
    color: #bd081c;
    font-weight: bold;
  }

  tr.returnReceipt a {
    color: #02b875;
    font-weight: bold;
  }
}

.absenceEmpty {
  margin: 5px;
  font-size: 18px;
  text-align: center;
}

.email {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.headerTextContainerCheckbox {
  justify-content: flex-start;
  margin-right: 1px;
}

.titleCheckbox {
  margin: 0 0 0 6px;
}

.titleCheckboxTitle {
  min-width: 10px;
}

.titleButtons {
  @include media-mobile {
    position: initial;
  }
}

.textUnderlined {
  text-decoration: underline;
}

.absenceDeleteButton {
  margin-left: 10px;
}

.absenceTimeRangeWrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  input {
    width: 100%;
    height: 35px;
    box-shadow: none;
    display: block;
    padding: 6px 12px;
    color: #555555;
    font-size: 14px;
    font-family: 'Open sans', sans-serif;
    background-color: #fff;
    border: 2px solid #ededed;
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s;

    &:focus {
      border: 2px solid #6fdbe8;
    }

    &.input_border_error {
      border: 2px solid #ff8989;
    }

    &:disabled {
      background-color: #eeeeee;
      color: #eeeeee;
    }
  }

  ::-webkit-input-placeholder {
    color: #c0c0c0;
  }

  ::-moz-placeholder {
    color: #c0c0c0;
  }

  /* Firefox 19+ */
  :-moz-placeholder {
    color: #c0c0c0;
  }

  /* Firefox 18- */
  :-ms-input-placeholder {
    color: #c0c0c0;
  }
}

.timeRangeError {
  color: red;
  margin-top: 5px;
}

.symptomWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .formControl {
    .label {
      font-weight: 700;
      margin-bottom: 5px;

      &.noMarginBottom {
        margin-bottom: 0;
      }
    }
  }
}
.dateWrapper {
  display: flex;
  gap: 25px;
  margin-top: 15px;
  margin-bottom: 15px;

  @include small-mobile {
    input {
      gap: 12px;
    }
  }
}

.dateStart,
.dateEnd {
  @include small-mobile {
    input {
      width: 100px;
    }
  }
}

.checkbox {
  margin-top: 25px;
}
