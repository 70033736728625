.tableColumn {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
}

.tableFirstColumn{
  font-weight: bold;
}

.symbolContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.symbolBox {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  box-sizing: border-box;
}

.letter {
  font-size: 24px; /* Adjust size as needed */
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: black;
}
