.container {
  margin: 6px 15px 0 15px;
  user-select: none;
  cursor: pointer;
}

.checkbox {
  height: 18px;
  width: 18px;
  vertical-align: middle;
  &.disabled {
    background: #f2f2f2;
    cursor: default;
  }
}

.checkboxColor {
  color: #6FDBE8;
}

.checkboxAWOColor {
  color: #e2001A;
}

.text {
  vertical-align: middle;
  padding-left: 5px;
  font-family: 'Open sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #777;
  cursor: default;
  min-width: 40px;
}

.loadingContainer {
  display: flex;
  flex-flow: row;

  .submitting {
    height: 18px;
    width: fit-content;
    vertical-align: middle;
    cursor: default;
  }
}
