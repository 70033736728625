@import 'resources/styles/mixins.scss';

.groupAvatar {
  width: 40px;
  height: 40px;
  border-radius: 3px;

  img {
    width: 100%;
    height: 100%;
  }

  &Letter {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 3px;

  &Container {
    width: 50px;
    position: relative;
  }

  &Group {
    position: absolute;
    top: 24px;
    left: 25px;
    width: 20px;
    height: 20px;
    font-size: 9px;
    border-radius: 2px;
    color: #ffffff;
    overflow: hidden;

    &Letter {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 7px;
  border-bottom: 1px solid #eeeeee;

  &Btns {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > * {
      margin-left: 5px;
    }
  }
}

.wrapper {
  width: 100%;
  margin-left: 5px;

  @include media-mobile {
    margin-left: 10px;
  }

  .wrapperTop {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding-right: 35px;

    @include media-mobile {
      flex-flow: column;
      padding-right: 0px;
    }

    .labels {
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      align-items: center;
      & > * {
        margin-left: 5px;
      }
      @include media-mobile {
        justify-content: flex-start;
        flex-wrap: wrap;
        & > * {
          margin-left: 0px;
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.fileDescriptionEdit {
  cursor: pointer;
}

.name {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  min-width: 60px;

  & * {
    color: #555555;
  }
}

.groupName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;

  i {
    padding: 0 8px;
    color: #bebebe;
  }
}

.kitaName {
  font-size: 13px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

@mixin date {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #bebebe;
}

.awoDesktopDate {
  @include date;
  @include media-mobile {
    display: none;
  }
}

.awoMobileDate {
  display: none;
  @include media-mobile {
    @include date;
  }
}

.desktopDate {
  @include date;
}

.updated {
  &Separator {
    margin: 0 3px;
  }
}

.customLabel {
  color: #fff !important;
}

.pendingLabel {
  background-color: #ededed;
  color: #7a7a7a !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
  }
}

.inProgressLabel {
  background-color: #6fdbe8;
  color: #fff !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}

.pendingReviewLabel {
  background-color: #fdd198;
  color: #fff !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}

.completedLabel {
  background-color: #97d271;
  color: #fff !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}

.overdueLabel {
  background-color: #ff8989;
  color: #fff !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}
@include media-mobile {
  .header {
    &Btns {
      position: static;
      margin-right: 22px;
    }
    .moreBtn{
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.submitting {
  height: 24px;
}

.blueLinkColor {
  text-decoration: underline;
  color: #0000EE;
}

.normal {
  color: black;
}

.popupBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
