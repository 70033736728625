.workingTimeRangeWrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.workingHourTimeRangeError {
  color: red;
  margin-top: 5px;
}

.btnUpdateWorkingHour{
  margin-right: 10px;
}
