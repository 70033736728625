.title {
  margin-bottom: 1em;
}

.separator {
  border-top: 1px solid #a8a3a3;
  width: 50%;
  margin: 0 auto;
}

.contentWrapper {
  display: flex;
  justify-content: center;
}

.contentContainer {
  padding: 1em 5em 2em;
  display: block;
}

.attachmentArea {
  width: 50%;
  margin: 0 auto;
}
