.cardDropdown {
  padding: 5px 0;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  cursor: default;
}

.header {
  font-size: 16px;
  padding: 3px 10px;
  margin: 3px 0 10px;
  font-weight: 300;
  color: #bebebe;
}

.footer {
  padding: 10px 10px 5px;
}
