.cardContainer {
  padding: 12px;

  .groupListTitle {
    font-size: 20px;
    font-weight: bold;
  }
}

.nodeHeader {
  margin: 4px 0;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  cursor: pointer;
  background-color: #e2001A;
  color: white;
}

.contentPanel {
  margin-left: 36px;
  padding-bottom: 8px;
}

.kitaItem {
  padding: 2px 0;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  i {
    font-size: 12px;
  }
}

.groupLink {
  color: black;
  text-decoration: underline;
}

.divider {
  border-top: 1px solid #cecece;

  &:last-child {
    border-bottom: 1px solid #cecece;
  }
}
