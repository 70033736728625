.label {
  display: inline-block;
  padding: 3px 5px 4px;
  font-weight: 600;
  font-size: 10px !important;
  color: white !important;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: none;
  line-height: 1;
}

.labelInfo {
  background-color: #6fdbe8;
  border-radius: 2px;
}

.labelInfoGray {
  background-color: #d7d7d7;
  border-radius: 2px;
}

.labelInput {
  font-weight: bold;
  color: #777 !important;
  font-size: 13px !important;
  padding-bottom: 0;
  margin-bottom: 7px;
  padding-left: 0;
}

.labelDefault {
  background: #ededed;
  color: #7a7a7a !important;
}

.labelSuccess {
  background: #97d271;
}

.labelDanger {
  background: #ff8989;
}