.footerBtnLeft {
  margin-right: 10px;
}

.inputGroup {
  margin-bottom: 15px;
}
.error {
  color: #ff8989;
  font-size: 13px;
}

.bazaar-avatar{
  width: 40px;
  height: 40px;
  border-radius: 3px;
}

.bazaar-avatar-container{
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-top: 5px;
}

.bazaar-avatar-text{
  font-weight: bold;
  margin-top: 10px;
  margin-left: 10px;
}

.success{
  margin-top: 20px;
  color: green;
}
