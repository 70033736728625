.wrapper {
  display: flex;
  margin-bottom: 20px;

  div {
    margin-right: 5px;
  }

  .photo {
    padding: 2px;

    img {
      height: 60px;
      width: 60px;
      vertical-align: middle;
    }
  }

  .status {
    width: 100px;
  }

  .name {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    width: 180px;

    .parent {
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
    }
  }

  .group {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    flex-shrink: 0;
  }

  .statusWrap {
    display: flex;
    align-items: center;

    .statusContainer {
      display: flex;
      flex-direction: column;
      hyphens: auto;
      font-size: 10px;
      align-items: center;
      justify-content: center;
      width: 55px;
      margin-top: 12px;

      div {
        margin: 0;
      }

      .statusIconWrapper {
        width: 36px;
        height: 34px;
        border-radius: 3px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .statusCheckin {
      .statusIconWrapper {
        background-color: #08803a;
      }

      .statusLabel {
        color: #08803a;
      }
    }

    .statusCheckout {
      .statusIconWrapper {
        background-color: #ffaf00;
      }

      .statusLabel {
        color: #ffaf00;
      }
    }

    .statusSick {
      .statusIconWrapper {
        background-color: #bd081c;
      }

      .statusLabel {
        color: #bd081c;
      }
    }

    .statusVacation {
      .statusIconWrapper {
        background-color: #7f00ff;
      }

      .statusLabel {
        color: #7f00ff;
      }
    }
  }

  &.redBorder {
    background: #ff6f6f;
    color: white;
  }

  .note {
    min-width: 90px;
  }
}
