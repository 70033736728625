.container {
  margin-bottom: 10px;
  padding: 0 20px;
}

.postLoadTemplateButtonContainer {
  display: flex;
}

.inputGroup {
  margin-bottom: 15px;
  position: relative;
}

.inputWithColorPicker {
  padding-left: 40px;
  position: relative;
  input[type='text'] {
    padding-left: 12px;
    border-radius: 0px 4px 4px 0px;
  }
}

.colorPicker {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  .label {
    width: 40px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ededed;
    margin-bottom: 2px;
    border-radius: 2px 0px 0px 2px;
    cursor: pointer;
    .current {
      width: 16px;
      height: 16px;
    }
  }
  .picker {
    background: white;
    width: 130px;
    height: 146px;
    position: absolute;
    padding: 6px 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    &:before {
      position: absolute;
      content: '';
      border: 8px solid transparent;
      border-bottom: 8px solid #dedede;
      z-index: 2;
      bottom: 100%;
      left: 5px;
    }
    &:after {
      position: absolute;
      content: '';
      border: 6px solid transparent;
      border-bottom: 6px solid white;
      z-index: 2;
      bottom: 100%;
      left: 7px;
    }
    .currentColor {
      width: 100%;
      height: 9px;
    }
  }
}

.eventTypeContainer {
  margin-bottom: 15px;
}

.descriptionContainer {
  margin-bottom: 15px;

  .textareaContent {
    min-height: 100px;
  }
}

.checkboxContainer {
  margin-bottom: 15px;
}

.dateAndTimeInputsContainer {
  margin-bottom: 15px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  input {
    width: 100%;
    height: 35px;
    box-shadow: none;
    display: block;
    padding: 6px 12px;
    color: #555555;
    font-size: 14px;
    font-family: 'Open sans', sans-serif;
    background-color: #fff;
    border: 2px solid #ededed;
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s;

    &:focus {
      border: 2px solid #6fdbe8;
    }
    &.input_border_error {
      border: 2px solid #ff8989;
    }
    &:disabled {
      background-color: #eeeeee;
      color: #eeeeee;
    }
  }
  ::-webkit-input-placeholder {
    color: #c0c0c0;
  }
  ::-moz-placeholder {
    color: #c0c0c0;
  } /* Firefox 19+ */
  :-moz-placeholder {
    color: #c0c0c0;
  } /* Firefox 18- */
  :-ms-input-placeholder {
    color: #c0c0c0;
  }
}

.dateInputsContainer {
  display: flex;
  flex-flow: column;
  width: 45%;
}

.timeInputsContainer {
  display: flex;
  flex-flow: column;
  width: 45%;
}

.timeInputsContainerDisabled {
  opacity: 0.2;
}

.locationInputContainer {
  margin-bottom: 15px;
}

.dateStart {
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
}

.dateEnd {
  display: flex;
  flex-flow: column;
}

.timeStart {
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
}

.timeEnd {
  display: flex;
  flex-flow: column;
}

.dateEndError {

  input {
    border-color: #ff8989 !important;
  }
}

.timezoneContainer {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin-top: -10px;
  cursor: pointer;

  .timezoneTitle {
    color: #6fdbe8 !important;
    font-size: 11px;
  }

  .timezoneDropdown {
    width: 45%;

    .timezoneSelect {
      width: 100%;
      height: 35px;
      font-size: 13px;
    }

    .timezoneSelectOpen {
      overflow: auto;
      max-height: 200px;
      font-size: 13px;
      width: 100%;
    }
  }
}

.reminderValueInput {
  flex: 1;
  min-width: 10%;
}

.reminderTypeSelect {
  flex: 1;
  min-width: 80%;
  max-height: 2.5em;
  margin-top: 0;

}

.reminderTypeSelect {
  flex: 1;
  min-width: 80%;
  margin-top: 0;
}

.reminderRemoveButton {
  flex: 1;
  min-width: 10%;
}

.reminder {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  margin-bottom: 1em;
}
