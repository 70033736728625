.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 160px;
  padding: 3px 0;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  &Item {
    border-left: 3px solid #fff;
    background-color: #fff;
    font-size: 13px;
    font-weight: 600;
    padding: 3px 20px;
    color: #555;
    white-space: nowrap;

    .active & {
      border-left: 3px solid #6fdbe8;
      background-color: #f7f7f7;
      color: #555;
    }
  }
}

.dropdown_awo {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 160px;
  padding: 3px 0;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  &Item {
    border-left: 3px solid #fff;
    background-color: #fff;
    font-size: 13px;
    font-weight: 600;
    padding: 3px 20px;
    color: #555;
    white-space: nowrap;

    .active & {
      border-left: 3px solid #e30813;
      background-color: #f7f7f7;
      color: #555;
    }
  }
}

.aLink {
  border-left: 3px solid #fff;
  background-color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 20px;
  color: #555;
  white-space: nowrap;
}