.demoPicturesContainer {
  .demoPicturesContainerList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0px;
    padding: 8px;
    transition: box-shadow 0.2s;

    .dragHandle {
      transform: translateX(-10px);
      opacity: 0;
    }

    &:hover {
      .dragHandle {
        opacity: 1;
      }
    }

    .delete {
      line-height: 100%;
      cursor: pointer;
      margin-left: 8px;

      &:hover {
        color: #ff8989;
      }
    }

    .demoPicturesContainerListPrewiev {
      margin-left: 4px;
      line-height: 100%;
      position: relative;
      visibility: visible;
      cursor: pointer;

      &:hover {
        .prewievImageWrapper {
          visibility: visible;
        }
      }

      .fileLink {
        color: inherit;
      }

      .prewievImageWrapper{
        background-color: #fff;
        box-shadow: 0 0 3px #dadada;
        border: 1px solid #ededed;
        border-radius: 4px;
        padding: 5px 10px;
        position: absolute;
        left: 125px;
        bottom: 15px;
        visibility: hidden;

        img {
          height: 100px;
        }
      }
    }
  }
}
