.container {
  padding: 10px;
}

.table {
    display: block;
    width: 100%;
    margin: 20px 0;
    overflow-x: initial;
    @media only screen and (max-width: 768px) {
      overflow-x: auto;
    }
}

.component {
  min-height: 40px;
  border: 1px dashed black;
}

.componentActionButtons i {
  margin-right: 5px;
}

.buttonsTopRight {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.buttonsTopRight div {
  margin-bottom: 5px;
}

.formNameInput {
  width: 40%;
}

.formDataContainer {
  margin-bottom: 2em;
  padding-bottom: 1em;
  border-bottom: 1px solid black;
}

.errorMsg {
  color: red;
  list-style-type: none;
}

.inlineInput {
  border: 1px solid gray;
  border-radius: 3px;
  display: inline;
  font-size: inherit;
}

.inlineCheckbox {
  display: inline;
}

.inlineSelect div {
  display: inline;
  width: 10%;
}

@media only screen and (max-width: 600px) {
  .formNameInput {
    width: 100%;
  }
}

.wrapper {
  margin-top: 10px;
}

.bottomButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.bottomButton {
  margin-left: 5px;
  margin-right: 5px;
  width: 210px;
}
