.dateRowWrapper {
    border: 1px solid grey;
    border-radius: 5px;
    background-color: grey;
    line-height: 30px;
}

.rowItem {
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
}

.rowItemTitle {
  background-color: rgb(237, 237, 237);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}

.smileyStatus {
  display: flex;
  align-items: center;
  gap: 4px;
}

.smileyIcon {
  font-size: 24px;
  color: #f2bf16;
}

