.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: rgb(207, 31, 189);
  border-radius: 4px;
  overflow: hidden;
}

.group {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &Title {
    font-size: 14px;
    font-weight: 500;
    color: #555;
  }

  &Description {
    color: #aeaeae;
    font-weight: 300;
  }
}
.avatarGroupLetter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
}

.img {
  width: 100%;
  height: 100%;
}
