@import 'resources/styles/mixins/textEditor.scss';

.comment {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  &Body {
    flex: 1;
  }
}
.name {
  font-size: 14px;
  font-weight: 500;
  color: #555;
  padding-top: 1px;
  margin-bottom: 3px;
  display: inline-block;
}
.date {
  font-size: 11px;
  color: #bebebe;
  padding-left: 5px;
  display: inline-flex;
}
.avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.text {
  @include textEditor;
}
.hr {
  margin: 15px 0 10px;
  border-top: 2px solid #d9d9d9;
}

.moreBtn {
  position: absolute;
  top: 0;
  right: 0;
}

.updated {
  &Separator {
    margin: 0 3px;
  }
}

.editor {
  margin-bottom: 0;
}

.submitting {
  height: 24px;
}

.commentTextContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}
