@import 'resources/styles/mixins.scss';

body {
  font-family: 'Open Sans', sans-serif;
  top: 0 !important;
  word-break: break-word;
}

body.not-login {
  padding-top: 130px;
  font-size: 14px;
  background-color: #ededed;
  color: black;

  @include media-tablet {
    padding-top: 120px;
  }

  @include media-mobile {
    padding-top: 115px;
  }
}

pre {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  overflow-y: hidden;
  font-size: 12px;
  line-height: 20px;
  background: #efefef;
  border: 1px solid #b5b5b5;
}

.pswp__button__download {
  width: 44px;
  height: 44px;
  float: right;
  background: transparent;
  cursor: pointer;
  border: none;
}

.pswp__button__download__span {
  color: #EEE;
  font-size: 20px;
  display: block;
  margin-top: 3px;
}

.pswp__button--zoom {
  display: none !important;
}
