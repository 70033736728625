.wrapper {
    display: flex;
    justify-content: center;
    width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
    transition: all 0.3s ease;
}

.cameraWrapper {
    display: flex;
    justify-content: center;
    width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
    transition: all 0.3s ease;
    margin-top: 10px;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
}

.buttonSubmit {
    margin-left: 30px;
}