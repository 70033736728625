@import 'resources/styles/mixins.scss';

.userFrame {
  display: flex;
  justify-content: flex-start;
  position: relative;
  padding: 10px 8px 8px;
  cursor: pointer;
  user-select: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.name {
  color: #ffffff;
  font-weight: 700;
  position: relative;
  top: -2px;

  @include media-mobile {
    display: none;
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
}

.title {
  font-size: 12px;
  color: #d7d7d7;
  margin-top: -4px;

  @include media-mobile {
    display: none;
  }
}

.avatar {
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 10px;
}

.awoMember {
  position: absolute;
  left: -12px;
  bottom: -12px;
  width: 24px;
  height: 24px;

  img {
    width: 100%;
  }
}

.textCaret {
  margin-left: 3px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
