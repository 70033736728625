@import 'resources/styles/mixins.scss';

.button {
  font-size: 13px;
  font-weight: 400;
  position: relative;

  &More {
    padding: 8px;
    height: 100%;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 35px;

  @include media-mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  input {
    display: none;
  }

  &Right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &Button {
      margin-left: 3px;
      position: relative;

      @include media-mobile {
        margin-left: 0;
      }

      .button {
        height: 35px;
        margin-right: 0;
      }
    }
  }

  &Left {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-right: 5px;

    @include media-mobile {
      margin-bottom: 5px;
    }

    .button {
      &:hover {
        background-color: #e8e8e8 !important;
      }
    }
  }
}

.dropdown {
  &ButtonActive {
    background-color: #449d44;
  }
}

.selectedOptions {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  cursor: pointer;
  z-index: 1;
  border: 1px solid #d7d7d7;

  i {
    margin-right: 7px;
  }

  &Item {
    padding: 4px 15px;
    color: #555555;
    font-size: 13px;
    font-weight: 600;
    background-color: #ffffff;
    text-align: left;

    &:hover {
      background-color: #f7f7f7;
    }
  }

  &Opened {
    display: block;
  }
}

.progressBar {
  background: #ededed;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  margin: 10px 0px;
  overflow: hidden;

  &Value {
    background: #6fdbe8;
    transition: width 0.6s ease;
    height: 100%;
  }
}

.caretIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 24px;
  background: #ededed;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

  &:hover {
    background: #e8e8e8;
  }
}

.noBorder {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttonsContainer {
  display: flex;
  position: relative;
}

.buttonCentered {
  margin: 0 auto;
}
