.badgesContainer {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 3px;
  padding: 4px;
  font-weight: 600;
}

.iconBadge {
  font-size: 0.875rem;
  margin: 0;
  width: 35px;
  height: 35px;
  i {
    color: #ffffff;
  }
}

.holiday {
  background-color: #610072;
}

.sick {
  background-color: #bb7700;
}

.publicHoliday {
  background-color: #00726a;
}

.holidayOnOvertime {
  background-color: #610072;
}

.absence0 {
  background-color: #b82eae;
}

.absence1 {
  background-color: #1c9643;
}

.absence2 {
  background-color: #7a8505;
}

.absence3 {
  background-color: #397cd3;
}

.absence4 {
  background-color: #822248;
}

.inputAbsenceType {
  width: 200px;
}
