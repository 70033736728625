.block {
  display: none;
  opacity: 0;
  transition: 0.35s;
}

.visible {
  transition: 0.35s;
  display: block;
}

.opacity {
  opacity: 1;
}
