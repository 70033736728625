@import 'resources/styles/mixins.scss';

.messagePopup {
  height: 62px;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.73);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1602;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transform: translateY(100%);
  transition-duration: 0.3s;
  color: white;
  @include media-laptop {
    z-index: 1602;
    padding: 0 20px;
  }
  &_visible {
    transform: translateY(0%);
  }
  i {
    color: #85ca2c;
    font-size: 20px;
    margin-right: 10px;
  }
}

.error {
  color: #ff8989 !important;
}

.warning {
  color: #fdd198 !important;
}
