.title {
  font-size: 20px;
  font-weight: 300;
}

.formContainer {
  padding: 1em;
}

.inlineCheckbox {
  display: inline;
}

.actionButtonsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 1em;
  flex-wrap: wrap;
}

.actionButtonsContainer div {
  margin-right: 1em;
  flex-basis: 30%;
  width: 100%;
  text-align: center;
}

.footerNavigation {
  display: flex;
  justify-content: center;

  &>div {
    margin: 0 5px;
  }
}

.header {
  width: 100%;
  margin: 0 1em;
  margin-top: 3em;
  margin-bottom: 0.5em;
  padding-bottom: 0.7em;
  border-bottom: 1px solid #ddd;

  .title {
    font-size: 20px;
    font-weight: 500;
    color: #555;

    span {
      cursor: pointer;
      font-weight: bold;
    }
  }

  .changeChildButton {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    // color: #6fdbe8;

    &:hover {
      text-decoration: underline;
    }

    i {
      margin-right: 0.8em;
      font-size: 0.5rem;
    }
  }
}

// no child selected (empty state)
.emptyState {
  padding: 0 1em;
  padding-bottom: 6em;
  padding-top: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .emptyStateHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.7em;

    .heading {
      text-align: center;
    }

    .description {
      text-align: center;
      max-width: 30em;
    }
  }

  .actionButtons {
    display: inline-flex;
    align-items: center;
    gap: 1em;

    .selectInput {
      width: 300px;
    }
  }
}