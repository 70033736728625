.comments {
  background: #ededed;
  display: none;
  padding: 10px;
  margin-bottom: 0;
}

.showAllButton {
  font-size: 12px;
  color: #555555;
  cursor: pointer;
}

.hr {
  margin: 15px 0 10px;
  border-top: 1px solid #d9d9d9;
}

.isOpened {
  display: block;
}

.editor {
  margin-bottom: 0;

  & + * {
    margin-top: 6px;
    margin-bottom: 0;
  }
}

.progressBar {
  background: #ededed;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  margin: 10px 0px;
  overflow: hidden;

  &Value {
    background: #6fdbe8;
    transition: width 0.6s ease;
    height: 100%;
  }
}

.emptyCard {
  margin-bottom: 0;
}
