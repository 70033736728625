.container {
  margin-bottom: 0px;
  padding: 0px 10px;
  padding-top: 4px;
  transition: 0.2s cubic-bezier(0.37, -0.25, 0.47, 1.26);
}

.checkboxContainer {
  margin-top: 12px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px;
}

.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonCancel {
    margin-right: 10px;
  }
}

.headerPopup {
  padding: 40px 20px 0;
  border-bottom: none;
  text-align: center;
  color: #555;
  font-weight: 300;
  font-size: 20px;
}

.subheaderPopup {
  margin: 20px 30px;
}

.bodyPopup {
  padding: 0 30px;
}

.filter {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .searching {
    min-width: 100px;
    display: flex;
    margin-right: 10px;

    &__input {
      width: 100%;
      input {
        border-radius: 4px 0px 0px 4px;
      }
    }
    &__submit {
      border-radius: 0px 4px 4px 0px;
      width: 45px;
      background: #ededed;
      color: #7a7a7a;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: -1px;
      &:hover {
        background: #e8e8e8;
        position: relative;
        z-index: 2;
      }
    }
  }
  .roleFilter {
    min-width: 140px;
  }
}

.tableWrapper {
  thead {
    tr {
      th {
        text-align: left;
        font-size: 13px;
        font-weight: bold;
        padding: 10px 10px 10px 0;
        color: #555;
        &:first-child {
          padding-left: 5px;
        }
      }
    }
  }
  tbody {
    tr {
      border-top: 1px solid #ddd;
      td {
        padding: 6px 10px 6px 15px;
        &:first-child {
          padding-left: 5px;
        }
      }
      &:hover {
        background: #f5f5f5;
      }
    }
  }
}
