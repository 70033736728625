.popupContainer{
  padding: 30px;
}
.mr2{
  margin-right: 5px;
}
.red {
  color:red;
}
.green{
  color:green;
}
