.linkAvatar {
  background-color: #6fdbe8;
  width: 24px;
  min-width: 24px;
  height: 24px;
  font-size: 10.56px;
  padding: 4.32px 0;
  border-radius: 2px;
  margin-right: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  &Letter {
    text-transform: none;
  }

  &WithImage {
    position: relative;

    &Container {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      overflow: hidden;

      .logoImg {
        width: 100%;
        height: 100%;
        image-rendering: -webkit-optimize-contrast;
      }
    }

    span {
      background-color: #6fdbe8;
      width: 20px;
      height: 20px;
      font-size: 8.8px;
      padding: 3.6px 0;
      border-radius: 2px;
      position: absolute;
      top: 14px;
      left: 14px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      color: #ffffff;
    }
  }
}