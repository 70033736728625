.container {
  width: 100%;
  padding-bottom: 20px;
}

.pageHeader {
  height: 30px;
  font-size: 100%;
  text-align: center;
  padding: 10px;
}

.audio {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.editIcon {
  display: inline-block;
  font-size: 30px;
  line-height: 40px;
  background-color: white;
  color: black;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
}