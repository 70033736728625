.dropdown {
  position: absolute;
  z-index: 1601;
  top: calc(100% + 2px);
  right: 0;
  min-width: 160px;
  padding: 5px 0;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  &.themeYellow {
    background: #afcb05;
  }

  &.themeDark {
    background: #708fa0;
  }

  &.themeLight {
    background: #fff;
  }

  &.themeBlue {
    background: #051957;
  }
  &.themeBrown {
    background: #d7790f;
  }
}

.listItem {
  i {
    width: 22px;
  }
  & > div {
    font-size: 13px;
  }
}

.separator {
  background-color: #afcb05;
  height: 1px;
  margin: 9px 1px;
  border-left: 3px solid #afcb05;
}
