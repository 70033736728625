.optionInput {
  position: relative;

  .input {
    margin-bottom: 15px;

    & + div {
      right: 76px;
      border-radius: 0;

      i {
        font-size: 17px;
      }
    }
  }
}

.checkboxes {
  margin-left: -5px;
}

.arrowIcons {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;

  .arrowIconButton {
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #eee;
    border: none;

    i {
      font-size: 17px;
      color: #555;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

.reminderContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reminder {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  margin-bottom: 1em;
  gap: 8px;
}

.reminderValueInput {
  min-width: 40px;
}

.reminderTypeSelect {
  flex: 1;
  min-width: 300px;
  max-height: 2.5em;
  margin-top: 0;

}
