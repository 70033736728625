.popup {
  &Btns {
    & > * {
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
