.holidaysContainer {
    width: 100%;
    padding: 10px;
  
    .categoryTitle {
      margin-top: 12px;
      font-weight: bold;
    }
  
    .holidayGrid {
      display: grid;
      grid-gap: 8px;
      align-items: center;
  
      .gridHeader {
        padding-bottom: 5px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        border-bottom: 1px solid #ccc;
        font-weight: bold;
      }
  
      .gridRow {
        padding-bottom: 5px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 8px;
        align-items: center;
        border-bottom: 1px solid #ccc;
  
        input {
          width: 140px;
          font-size: 14px;
        }
      }
    }
  
    .footerActions {
      padding: 20px 0 0;
    }
  }
  
  .totalHours {
    font-weight: 800;
  }

  .saveButton {
    margin-left: 26%;
  }

  .lastContainer {
    display: flex;
  }

  .deleteButton {
    width: 20%;
    background-color: #c0c0c0;
  }

  .trashButton {
    color: black;
  }