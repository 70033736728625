.filtersContainer {
  display: flex;
  flex-flow: row;
  padding: 10px 30px 10px 10px;
  background-color: #f7f7f7;
  color: #777;
  flex-wrap: wrap;

  .filterContainer {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin: 0px 0px 15px 0px;
  
    .title{
      padding-left: 10px;
      font-weight: bold;
      min-height: 19px;
    }
  
    .checkBoxesContainer{
      display: flex;
      flex-flow: column;
      align-items: flex-start;
  
      img {
        background-color: #fff;
        border: 2px solid #ccc;
      }
    }
  }
}