.scimSettingsContainer {
  padding: 10px;

  .checkboxSpace {
    margin-left: 0;
  }

  .scimSettingWrapper {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 12px;

    .scimLabel {
      width: 190px;
      font-weight: 500;
    }
  }

  .scimSettingUsers {
    width: 320px;
    display: flex;
    align-items: center;

    >div {
      width: 100%;
    }
  }

  .scimSettingInput {
    position: relative;
    width: 320px;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      height: 35px;
      padding: 6px 12px;
      color: #555555;
      font-size: 14px;
      font-family: 'Open sans', sans-serif;
      background-color: #ededed;
      border: 1px solid #ededed;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    i {
      position: absolute;
      right: 12px;
      cursor: pointer;
    }
  }

  .scimcopyButton {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #eee;
    border: none;

    i {
      font-size: 17px;
      color: #555;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .scimUpdateButton {
    margin-top: 5px;
  }
}
