

.errorText{
    color: red;
    margin-top: 0.5em;
  }
  
  .footerCancelButton{
    color: white!important;
    background-color: #ffaf00;
    margin-left: 0.5em;
  }
  
  .footerSubmitButton{
    
    color: white!important;
    background-color: #08803a;
  }