@import './media.scss';

@mixin profile-body-sidebar {
  flex: 0 272px;

  @include media-laptop {
    flex: 0 222px;
  }

  @include media-tablet {
    display: none;
  }
}

@mixin profile-body-main {
  flex: 1;
  margin-right: 15px;
  word-break: break-word;

  @include media-tablet {
    margin-right: 0;
  }
}
