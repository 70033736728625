.noManualCheckInOuts {
  color: #a7a7a7;
  font-size: 1rem;
  padding: 20px 0;
}

.reason {
  padding: 3px;
  display: block;
  background-color: #eeeeee;
  border-radius: 2px;
  margin-top: 5px;
}

.tableBodyCheckInOuts {
  .statusAccepted {
    color: #89cc5e;
  }
  .statusDeclined {
    color: #ff8989;
  }
  .statusOpen {
    color: gray;
  }

  .checkInOutDateTime {
    .checkDate {
      font-size: 1rem;
      font-weight: 600;
    }

    .statusIn,
    .statusOut {
      display: inline-flex;
      flex-direction: column;

      i {
        margin-right: 5px;
      }
    }

    // .statusIn {
    //   color: #08803a;
    // }

    // .statusOut {
    //   color: #ffaf00;
    // }
  }
}
