.mt3 {
  margin-top: 30px;
}

.mt2 {
  margin-top: 20px;
}

.mt1 {
  margin-top: 10px;
}

.w30 {
  width: 30%;
}

.mr1 {
  margin-right: 10px;
}

.timeSelect {
  width: 30%;
}

@media only screen and (max-width: 730px) {
  .timeSelect {
    width: 100%;
  }
}


.statusWrap {
  display: flex;
  align-items: center;
}

.changeStatus {
  width: 34px;
  height: 30px;
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 10px;
}

.changeStatusCheckOut {
  background-color: #ffaf00;
  margin-left: 0;
}

.changeStatusCheckIn {
  background-color: #08803a;
  margin-right: 20px;
}

.wickelCounter {
  margin: 0 10px;
}

.statusIn {
  color: #08803a;
}

.statusOut {
  color: #ffaf00;
}

.statusAbsent {
  color: #ff4532;
}

.repetitionContainer {
  display: flex;
  margin-bottom: 20px;
}