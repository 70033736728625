.container {
  border-left: 3px solid;
  border-right: 1px solid;
  border-top: 2px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right-color: #ddd !important;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;
  font-size: 13px;

  .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    background-color: #f7f7f7;
    padding: 15px;

    .headerLeft {
      display: flex;
      flex-flow: row;
      align-items: center;

      .title {
        margin-left: 10px;
        margin-right: 5px;
      }

      .taskCount {
        margin-right: 5px;
      }
  
      .taskListButtons {
        display: none;
        margin-left: 10px;
        cursor: pointer;
    
        .taskListButtonEdit {
          margin-right: 10px;
        }
      }
    }

    .headerRightWrapper {
      display: flex;
      gap: 4px;

      .iconButton {
        cursor: pointer;
        background: #97d271;
        color: #fff !important;
        padding: 1px 5px;
        font-size: 12px;
      }
    }

    &:hover {
      .headerLeft {
        .taskListButtons {
          display: flex;
          flex-flow: row;
          align-items: center;
        }
      }
    }
  }


  .tasksContainer {
    display: flex;
    flex-flow: column;
  }

  .emptyContainer {
    border-bottom: 1px solid #eee;
    padding: 10px;
  }
}