.addNewButton {
  display: flex;
  justify-content: flex-end;
}

.container {
  padding: 10px;
  width: 100%;
}

.pageContent {
  margin-top: 2em;
}

.settingsButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.proceedButton{
  margin-right: 1rem;
}

.checkBoxContainer {
padding-left: 35%;
padding-top: 1%;
}