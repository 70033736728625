@import 'resources/styles/mixins/table.scss';
@include table;

.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonSubmit {
    margin-right: 10px;
  }
}

.containerEnable {
  padding: 10px;
  text-align: center;
}

.searchInputWrap {
  margin: 10px 0;
  input {
    width: 60%;
  }
}
.leavingTimeItemActions {
  display: flex;
  grid-gap: 12px;

  i {
    cursor: pointer;
  }
}
