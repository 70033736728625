.employeeHistory {
  border: 1px solid #bdbdbd;
  border-radius: 3px;
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ececec;
  background-color: #f5f5f5;
  cursor: pointer;

  .executivePersonTitle {
    width: 245px;
  }
  .name {
    width: 220px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
  }

  .middleColumm {
    display: flex;
    flex-direction: column;
    align-items: 'flex-start';
    gap: 10px;
  }
  .workingTime {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    cursor: text;

    .workingTimeLabel {
      font-weight: 700;
      margin-right: 10px;
    }

    .overtimeValue {
      width: 65px;
      display: flex;
      justify-content: right;
      margin-right: 5px;
    }

    i {
      margin-right: 5px;
    }

    .separator {
      border-right: 1px solid #e1e1e1;
      height: 24px;
      margin-right: 3px;
    }
  }
  .expandIcon {
    margin-left: auto;
    margin-right: 10px;
  }
}

.historyRowsWrapper {
  padding: 10px;
}

.datePickerWrapper {
  input {
    width: 81px;
    height: 35px;
    box-shadow: none;
    display: block;
    padding: 6px 12px;
    color: #555555;
    font-size: 14px;
    font-family: 'Open sans', sans-serif;
    background-color: #fff;
    border: 2px solid #ededed;
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s;

    &:focus {
      border: 2px solid #6fdbe8;
    }
    &.input_border_error {
      border: 2px solid #ff8989;
    }
    &:disabled {
      background-color: #eeeeee;
      color: #eeeeee;
    }
  }
  ::-webkit-input-placeholder {
    color: #c0c0c0;
  }
  ::-moz-placeholder {
    color: #c0c0c0;
  } /* Firefox 19+ */
  :-moz-placeholder {
    color: #c0c0c0;
  } /* Firefox 18- */
  :-ms-input-placeholder {
    color: #c0c0c0;
  }
}

.monthSelectionContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.noCheckInOutEntries {
  text-align: center;
  padding: 20px;
  color: #a1a1a1;
}

.loadAllButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  padding-top: 20px;
}

.exportButton i,
.showFullHistoryButton i {
  margin-left: 5px;
}

.avatar {
  width: 60px;
  height: 60px;
}

.historyHeader {
  margin: 20px 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  h3 {
    font-size: 1.2rem;
  }
  .seperator {
    width: 1px;
    height: 30px;
    background-color: #d4d4d4;
  }
}
