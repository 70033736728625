@import 'resources/styles/mixins.scss';
@import 'resources/styles/mixins/table.scss';
@include table;

.container {
  padding: 10px;
  width: 100%;
}

.loader {
  margin: 40px 0 41px;
}

.tableWrapper {
  overflow: auto;
  @include media-mobile {
    overflow: scroll;
  }
}

h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.1em;
  margin-bottom: 0.5em !important;
  font-weight: bold;
}

.item {
  border-bottom: 1px solid #eee;
  border-left: 3px solid white;
  display:inline-block;
  justify-content: flex-start;
  align-items: flex-start;

  &:hover {
    border-left: 3px solid #6fdbe8;
    background-color: #f7f7f7;
  }

  &Avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  &NotDefault {
    width: 100%;
    height: 100%;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
  }

  &Name {
    font-size: 14px;
    font-weight: 500;
    color: #555;
    margin-bottom: 2px;
  }
  
}

.groupBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logoImg {
  width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  visibility: hidden;
}

.headerText {
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;

  &Container {
    display: flex;
    align-items: center;
  }

  &WithThePadding {
    padding-left: 64px;
  }

  &Wrapper {
    display: flex;
  }
}

.toolBarIcon{
  display:inline-block;
  font-size: 40px;
  line-height: 50px;
  background-color:white;
  color:black;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: bottom;
  border-style: solid;
  border-width: thin;
  border-radius: 10px;
  cursor: pointer;
  margin: auto;
}