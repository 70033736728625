.tableContainer {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 2px;

  thead {
    background-color: #f9fafb;
    width: 100%;
    border-bottom: 1px solid rgb(55, 65, 81);

    th {
      padding: 0.7em 1em;
      font-size: 0.75rem;
      line-height: 1rem;
      font-size: 14px;
      font-weight: bold;
      color: rgb(55, 65, 81);
      text-align: left;
    }
  }

  tbody {
    width: 100%;

    tr {
      color: rgb(17, 24, 39);

      &:hover {
        background-color: rgb(249, 250, 251);
      }
    }

    tr:nth-child(even) {
      background-color: rgb(249, 250, 251);
    }
  }
}