.container {
  margin-bottom: 0px;
  padding: 0px 10px;
  padding-top: 4px;
  transition: 0.2s cubic-bezier(0.37, -0.25, 0.47, 1.26);
}

.inputGroup {
  margin-bottom: 15px;
  position: relative;
}
.inputWithColorPicker {
  padding-left: 40px;
  input[type='text'] {
    padding-left: 12px;
    border-radius: 0px 4px 4px 0px;
  }
}
.colorPicker {
  position: absolute;
  z-index: 2;
  top: 24px;
  left: 0;
  .label {
    width: 40px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ededed;
    margin-bottom: 2px;
    border-radius: 2px 0px 0px 2px;
    cursor: pointer;
    .current {
      width: 16px;
      height: 16px;
    }
  }
  .picker {
    background: white;
    width: 130px;
    height: 146px;
    position: absolute;
    padding: 6px 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    &:before {
      position: absolute;
      content: '';
      border: 8px solid transparent;
      border-bottom: 8px solid #dedede;
      z-index: 2;
      bottom: 100%;
      left: 5px;
    }
    &:after {
      position: absolute;
      content: '';
      border: 6px solid transparent;
      border-bottom: 6px solid white;
      z-index: 2;
      bottom: 100%;
      left: 7px;
    }
    .currentColor {
      width: 100%;
      height: 9px;
    }
  }
}
.error_msg {
}
.footer {
  display: flex;
  justify-content: space-between;
  .deleteButtons {
    margin: -5px;
    div {
      margin: 5px;
    }
  }

  @media screen and (max-width: 555px) {
    justify-content: flex-start;
    flex-wrap: wrap;

    & > div:first-child {
      margin: 0 10px 10px 0;
    }
  }
}
