/* BEGIN Light */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-Light.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/OpenSans-Light.eot?#iefix&v=1.1.0') format('embedded-opentype'),
    url('../../fonts/OpenSans/OpenSans-Light.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/OpenSans-Light.ttf?v=1.1.0') format('truetype');

  font-weight: 300;
  font-style: normal;
}
/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-LightItalic.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/OpenSans-LightItalic.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/OpenSans-LightItalic.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/OpenSans-LightItalic.ttf?v=1.1.0') format('truetype');

  font-weight: 300;
  font-style: italic;
}
/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-Regular.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/OpenSans-Regular.eot?#iefix&v=1.1.0') format('embedded-opentype'),
    url('../../fonts/OpenSans/OpenSans-Regular.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/OpenSans-Regular.ttf?v=1.1.0') format('truetype');

  font-weight: normal;
  font-style: normal;
}
/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-Italic.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/OpenSans-Italic.eot?#iefix&v=1.1.0') format('embedded-opentype'),
    url('../../fonts/OpenSans/OpenSans-Italic.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/OpenSans-Italic.ttf?v=1.1.0') format('truetype');

  font-weight: normal;
  font-style: italic;
}
/* END Italic */
/* BEGIN SemiBold */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-SemiBold.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/OpenSans-SemiBold.eot?#iefix&v=1.1.0') format('embedded-opentype'),
    url('../../fonts/OpenSans/OpenSans-SemiBold.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/OpenSans-SemiBold.ttf?v=1.1.0') format('truetype');

  font-weight: 600;
  font-style: normal;
}
/* END SemiBold */
/* BEGIN SemiBold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-SemiBoldItalic.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/OpenSans-SemiBoldItalic.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/OpenSans-SemiBoldItalic.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/OpenSans-SemiBoldItalic.ttf?v=1.1.0') format('truetype');

  font-weight: 600;
  font-style: italic;
}
/* END SemiBold Italic */
/* BEGIN Bold */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-Bold.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/OpenSans-Bold.eot?#iefix&v=1.1.0') format('embedded-opentype'),
    url('../../fonts/OpenSans/OpenSans-Bold.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/OpenSans-Bold.ttf?v=1.1.0') format('truetype');

  font-weight: bold;
  font-style: normal;
}
/* END Bold */
/* BEGIN Bold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-BoldItalic.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/OpenSans-BoldItalic.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/OpenSans-BoldItalic.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/OpenSans-BoldItalic.ttf?v=1.1.0') format('truetype');

  font-weight: bold;
  font-style: italic;
}
/* END Bold Italic */
/* BEGIN Extrabold */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-ExtraBold.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/OpenSans-ExtraBold.eot?#iefix&v=1.1.0') format('embedded-opentype'),
    url('../../fonts/OpenSans/OpenSans-ExtraBold.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/OpenSans-ExtraBold.ttf?v=1.1.0') format('truetype');

  font-weight: 800;
  font-style: normal;
}
/* END Extrabold */
/* BEGIN Extrabold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf?v=1.1.0') format('truetype');

  font-weight: 800;
  font-style: italic;
}
/* END Extrabold Italic */

/*# sourceMappingURL=open-sans.css.map */
