.container {
  margin-bottom: 10px;
}

.popupContentWrapper {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputWrapper {
  margin: 8px 16px 0;
}

.item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  border-left: 3px solid white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &:hover {
    border-left: 3px solid #6fdbe8;
    background-color: #f7f7f7;
  }

  &Avatar {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  &Name {
    font-size: 14px;
    font-weight: 500;
    color: #555;
    margin-bottom: 2px;
  }

  &Status {
    color: #aeaeae;
    font-weight: 300;
    margin-bottom: 5px;
    font-size: 14px;
  }
}
