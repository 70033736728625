.edit {
  height: 26px;
  width: 31px;
  font-size: 14px;
  background: #6fdbe8;
  color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #59d6e4;
  }
}
