.container {
    font-size: 13px;
    padding: 21px;
}
  
.footerNavigation {
    display: flex;
    justify-content: center;
    & > div {
        margin: 0 5px;
    }
}

.row{
    display: flex;
    margin-bottom: 10px;
}

.row > * {
    margin-right: 10px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}
  
.table th,
.table td {
    padding: 8px;
    border: 1px solid #ccc;
}
  
.table th {
    background-color: #f2f2f2;
}