@import 'resources/styles/mixins.scss';

.wrapper {
  display: flex;
  justify-content: space-between;

  @include media-tablet {
    flex-wrap: wrap;
  }
}

.body {
  width: 86%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  @include media-tablet {
    flex: 0 100%;
    margin-right: 0;
  }
}

.menu {
  flex: 0 170px;
  margin-right: 25px;

  @include media-laptop {
    flex: 0 137px;
  }

  @include media-tablet {
    flex: 0 100%;
    margin-right: 0;
  }
}

.listItem {
  padding: 6px 15px 5px;
  align-items: flex-start;
  i {
    font-size: 14px;
    width: 18px;
    position: relative;
    top: 1px;
  }

  & > div {
    font-size: 12px;
  }
}

.separator {
  height: 20px;
}

.listItem {
  font-size: 12px;

  &First {
    margin-bottom: 19px;
  }
}

.noMatches {
  font-size: 13px;
  font-weight: 700;
  padding: 10px;
  color: #555555;
}

.warningWrapper {
  position: relative;
  padding: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #fff;
  box-shadow: 0 0 3px #dadada;
  border-radius: 4px;
}

.authButton {
  margin-bottom: 8px;
}

