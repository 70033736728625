.mealItem{
  width: 47%;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  -webkit-box-shadow: 5px 5px 12px -4px rgba(0,0,0,0.39);
  box-shadow: 5px 5px 12px -4px rgba(0,0,0,0.3);
  margin: 0 13px 16px 0;
  height: fit-content;
}

.mealItemHeader{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 13px;
  width: 100%;
  background-color: #ebebeb;
  display: flex;
  flex-direction: row;
}
.mealItemBody{
  padding: 13px;
}

.hr{
  background-color: lightgray;
  height: 1px;
  border: 0;
}
.addButton{
  margin-top:10px;
  display: block;
  width: 100%;
}

.isActiveCheckbox{
  display: block;
  margin-left: auto;
  margin-top: 0;
  margin-right: 0;
}

@media only screen and (max-width: 700px) {
  .mealItem {
    width: 97%;
  }
}
