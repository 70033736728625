@import 'resources/styles/mixins.scss';



.scroll{
  overflow: scroll;
  overflow-x: hidden;
  max-height: 120px;
}

.scrollContainer{
  overflow: scroll;
  overflow-x: hidden;
}
.imageDetailsContainerStyle{
  position: fixed;
  width: 100%;
  height: 20%;
  top: 80%;
  left: 0%;
  z-index: 100;
  background-color: white;
}

.imageDetails{
  display: flex;
  flex-direction: row;
}

.imageDetailsFix{
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 600px) {
  .imageDetails{
    display: flex;
    flex-direction: column !important;
  }
}
.flex-1{
  flex: 1;
}
.flex-2{
  flex: 2;
}

.price{
  border-radius: 3px;
  border: 1px solid #eee;
  padding: 10px;
  background-color: #eee;
}

.price > span{
  font-size: 1.3em;
  font-weight: bold;
  align-items: center;
}

.price > strong{
  font-size: 1.3em;
  font-weight: bold;
  margin-left: auto;
}

.imageDetailsItem{
  padding: 20px;
}
.imageDetailsContainer{
  position: fixed;
  width: 100%;
  height: 20vh;
  max-height: 20vh;
  z-index: 99;
  background-color: white;
}
