@import 'resources/styles/mixins.scss';

.bottombarNavItem {
  padding: 0 13px;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50px;
  position: relative;

  @include media-tablet {
    z-index: 0;
  }

  .faIcon {
    font-size: 14px;
  }

  .faAWOIcon {
    font-size: 20px;
  }

  &:hover,
  .active & {
    background-color: #f7f7f7;
    color: #555;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: #f7f7f7;
    }
  }
}

.title {
  margin: 7px 0 -2px;
  user-select: none;
}