.mealContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.error{
  color: red;
}
.link {
  text-decoration: underline;
  text-decoration-color: red;
}
.cwnavigationContainer{
  display: flex;
  flex-direction: row;
}
.calendarWeek{
  text-align: center;
  vertical-align: center;
  display: flex;
  align-self: center;
  margin: 0 13px 0 13px;
}
.mr1{
  margin-right: 3px;
}

.mailButton {
  position: relative;
  display: flex;
}

.checkBoxButton {
  margin-left: auto;
}

.singleCheckbox {
  position: absolute;
  z-index: 1601;
  top: 45px;
  right: 0;
  min-width: 160px;
  padding: 5px 0;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
