.header {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  .name {
    width: 25%;
  }
  .url {
    width: 75%;
  }
}
