.container {
  padding: 10px;
  display: flex;
  flex-flow: column;
  background-color: #f7f7f7;

  .headerTop {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .headerTopLeft {
      display: flex;
      flex-flow: column;

      .titleContainer {
        border-left: 5px solid;
        padding-left: 5px;
        font-size: 16px;
        font-weight: 300;
        color: #555;

        i {
          margin-right: 10px;
        }
      }

      .timeRange {
        font-size: 17px;
        color: #919191;
        font-weight: 300;
      }

      .userInfo {
        color: #bebebe;
        font-size: 11px;
      }
    }

    .headerTopRight {
      display: flex;
      flex-flow: column;
      align-items: flex-end;

      .moreButtonsContainer {
        position: relative;

        .moreButtons {
          width: fit-content;
          padding: 1px 7px;
          color: #bebebe;
          border-radius: 4px;
        }

        .moreButtonIcon {
          i {
            color: #555;
            margin-left: 5px;
          }
        }
      }
    }
  }

  .separate {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #eeeeee;
  }

  .headerBottom {
    display: flex;
    flex-flow: row;

    .responsibleUsersListContainer {
      display: flex;
      flex-flow: column;
      padding-right: 10px;
      border-right: 2px solid #ededed;
      margin-right: 10px;

      .responsibleUsersListTitle {
        font-size: 13px;
        font-weight: bold;
        font-style: italic;
        color: #555;
      }

      .responsibleUsersListText {
        display: flex;
        flex-flow: row;

        .avatar {
          width: 24px;
          height: 24px;
          border-radius: 3px;
          margin-right: 5px;
        }
      }
    }

    .assignedContainer {
      display: flex;
      flex-flow: column;

      .assignedTitle {
        font-size: 13px;
        font-weight: bold;
        font-style: italic;
        color: #555;
      }

      .assignedText {
        display: flex;
        flex-flow: row;

        .avatar {
          width: 24px;
          height: 24px;
          border-radius: 3px;
          margin-right: 5px;
        }

        .assignedTextEmpty {
          font-size: 14px;
          font-style: italic;
          margin: 2px;
          color: #7a7a7a;
        }
      }
    }
  }

  .deleteTaskContainer {
    .wrapper {
      padding: 15px;
      margin-top: 10px;
      text-align: center;
    }

    .buttonsContainer {
      margin-top: 10px;
      display: flex;
      flex-flow: row;
      justify-content: center;
      
      .buttonCancel {
        margin-right: 10px;
      }
    }
  }
}

.pendingLabel {
  background-color: #ededed;
  color: #7a7a7a !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
  }
}

.inProgressLabel {
  background-color: #6fdbe8;
  color: #fff !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}

.pendingReviewLabel {
  background-color: #fdd198;
  color: #fff !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}

.completedLabel {
  background-color: #97d271;
  color: #fff !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}

.overdueLabel {
  background-color: #ff8989;
  color: #fff !important;
  margin-left: 10px;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}