.body {
  padding: 10px;
}

.publicVisible {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eeeeee;
}

.buttons {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Left {
    display: flex;

    .postButton {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      min-height: 40px;
    }

    & > * {
      margin-right: 3px;
      position: relative;
    }

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      gap: 4px;

      & > * {
        margin-right: 0;
      }
    }

    .uploadButton {
      padding: 8px 16px 9px;
      height: auto;
      width: 47px;
      font-size: 15px;
      background-color: #ededed;
      color: #777;
    }
  }

  &Right {
    position: relative;
    display: flex;
    align-items: center;
    & > * {
      margin-left: 5px;
    }
  }
}

.error {
  color: #ff8989;
  font-size: 13px;
  margin-top: 6px;
}

.moreBtn {
  width: 34px;
  height: 28px;
}

.progressBar {
  background: #ededed;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  margin: 10px 0px;
  overflow: hidden;

  &Value {
    background: #6fdbe8;
    transition: width 0.6s ease;
    height: 100%;
  }
}

.inputIcon {
  height: 100%;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    font-size: 14px;
    color: #555;
  }
}

.checkBoxContainer {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.checkbox {
  margin: 0 0 0 10px;

  @media screen and (max-width: 768px) {
    margin: 0;
  }

  span {
    display: flex;
    align-items: center;
  }
}
