.employeeHistory {
  margin: 10px 0;
  border: 1px solid #bdbdbd;
  border-radius: 3px;
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ececec;
  background-color: #f5f5f5;
  .executivePersonTitle {
    width: 245px;
  }
  .name {
    width: 220px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
  }

  .middleColumn {
    margin: 0 10px;
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .label {
      font-weight: 700;
      margin-right: 10px;
      display: inline-flex;
      width: 160px;
    }

    i {
      width: 15px;
      margin-right: 5px;
    }

    .badgeWrapper {
      width: 180px;
      display: inline-flex;
    }
  }
  .expandIcon {
    margin-left: auto;
    margin-right: 10px;
  }
}

.historyRowsWrapper {
  padding: 10px;
}

.avatar {
  width: 60px;
  height: 60px;
}

.searchInputWrap {
  margin: 10px 0;
  input {
    width: 60%;
  }
}

.clickable {
  cursor: pointer;
}
