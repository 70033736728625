@import 'resources/styles/mixins.scss';

.outer {
  position: fixed;
  z-index: 1601;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  box-shadow: 0 2px 26px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: #ffffff;
  position: relative;
  pointer-events: all;
  cursor: auto;

  position: absolute;
  z-index: 101;
  left: 0;
  right: 0;
  margin: 30px auto;

  @include media-mobile {
    max-width: calc(100vw - 20px);
  }
}

.header {
  padding: 40px 0 0;
  border-bottom: none;
  text-align: center;
  color: #555;
  font-weight: 300;
  font-size: 20px;
}

.body {
  padding: 20px;
  font-size: 13px;
}

.footer {
  margin-top: 0;
  padding: 10px 20px 30px;
  border-top: none;
  text-align: center;
}

.cross {
  font-size: 21px;
  font-weight: bold;
  color: #000;
  opacity: 0.2;

  position: absolute;
  right: 21px;
  top: 19px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.size {
  &ChildHistoryTab {
    width: 900px;
    min-width: 900px;

    @include media-tablet {
      max-width: calc(100vw - 20px);
    }
  }

  &Large {
    width: 900px;

    @include media-tablet {
      max-width: calc(100vw - 20px);
    }
  }

  &Medium {
    width: 768px;
  }

  &Normal {
    width: 600px;
  }

  &NormalAndMin {
    width: 600px;
    min-width: 600px;
  }

  &Small {
    width: 500px;

    .cross {
      top: 12px;
    }
  }

  &ExtraSmall {
    width: 400px;

    .cross {
      top: 10px;
    }
  }
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.headerIcon {
  padding-top: 30px;
}

.awoPopup {
  width: 1200px;
}