@import 'resources/styles/mixins.scss';

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}

.contentSide {
  width: 755px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  @include media-laptop {
    width: 620px;
  }
  @include media-tablet {
    width: 100%;
  }

  .notificationContainer {
    border-top: 1px solid #ededed;
  }
}
.widgetSide {
  width: 370px;
  background-color: transparent;
  box-shadow: none;
  @include media-laptop {
    width: 305px;
  }
  @include media-tablet {
    display: none;
  }

  .filterContainer {
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    border-top: 1px solid #ededed;
    padding: 20px 0;
  }
  .saveFilterButton {
    margin-left: 15px;
  }
}

.loader {
  margin: 50px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &MarkAll {
    font-size: 13px;
    font-weight: 700;
    color: #6fdbe8;
    cursor: pointer;
  }
}

.empty {
  padding: 10px;
  color: #777;
}

.deleteCheckbox {
  display: flex;
}

.selectAllNotif {
  margin-left: 2%;
  margin-top: 0.5%;
}

.deleteNotification {
  margin-left: 55%;
}