.cardContainer {
  width: 100%;
  height: fit-content;
}

.bodyTitle {
  background-color: #f7f7f7;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.container {
  padding: 0px 10px;
  max-height: 525px;
  overflow: auto;
}

.container {
  margin-bottom: 10px;
  padding: 0 20px;
}

.usersInput {
  margin-bottom: 15px;
}

.chatNameContainer {
  margin-bottom: 15px;
}

.row{
  display: flex;
  flex-direction: row;
}
.receiverCheckbox{
  margin-left: 0px;
}
.messageContainer {
  margin-bottom: 15px;
  margin-top: 0;
  transition: margin-top 0.05s;

  .textareaContent {
    min-height: 100px;
  }

  &Error {
    .textareaContent {
      border: 2px solid #ff8989;
    }
  }
}

.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.buttonSend {
  margin-right: 10px;
  margin-bottom: 20px;
}

.uploadButton {
  height: 36px;
  width: 47px;
  font-size: 15px;
  background-color: #ededed;
  color: #777;

  &:hover {
    background-color: #ededed;
  }
}

.progressBar {
  background: #ededed;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  margin: 10px 0px;
  overflow: hidden;

  &Value {
    background: #6fdbe8;
    transition: width 0.6s ease;
    height: 100%;
  }
}
