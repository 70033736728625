.table {
  margin-top: 20px;
  table-layout: fixed;
  width: 100%;

  tr {
    .tableLeft {
      width: 10%;
      font-weight: bold;
      text-align: center;
    }
  }

  tr,
  td {
    @media all and (min-width: 600px) {
      font-size: 16px;
    }
    @media not all and (min-width: 600px) {
      font-size: 14px;
    }
    & {
      border: 1px solid #ccc;
      white-space: pre-line;
    }
    a {
      color: blue;
      text-decoration: underline;
    }
    a:hover {
      cursor: pointer;
    }
  }
}


