@import 'resources/styles/mixins/table.scss';
@include table;

.icon {
  width: 36px;
  height: 36px;
  border-radius: 3px;
  background: #6a8fa2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: visible !important;
  }

  &Container {
    margin-left: 10px;
  }
}
