.secondnav{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mr2{
  margin-right: 15px;
}
.timeSelect{
  width: 30%;
}

@media only screen and (max-width: 730px) {
  .timeSelect {
    width: 100%;
  }
}

