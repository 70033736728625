.wrapper {
  display: flex;
  margin-bottom: 20px;

  div {
    margin-right: 20px;
  }

  .photo {
    width: 60px;

    img {
      height: 60px;
      width: 60px;
    }
  }

  .status {
    width: 100px;
  }

  .name {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    width: 140px;

    .parent {
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
    }
  }

  .statusWrap {
    display: flex;
    align-items: center;
  }
}

.changeStatusCheckIn {
  width: 34px;
  height: 30px;
  border-radius: 3px;
  background-color: #08803a;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}

.changeStatusCheckOut {
  width: 34px;
  height: 30px;
  border-radius: 3px;
  background-color: #ffaf00;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}

.statusIn {
  color: #08803a;
}

.statusOut {
  color: #ffaf00;
}

.statusAbsent {
  color: #ff4532;
}