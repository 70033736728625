.popup {
  &Text {
    text-align: center;
  }

  &Btns {
    & > * {
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
