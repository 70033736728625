.container {
  padding: 10px;

  .headerContainer {
    margin-bottom: 10px;
    padding-left: 10px;
    border-left: 3px solid #6fdbe8;
    display: flex;
    flex-flow: column;
    font-size: 13px;

    .headerTop {
      display: flex;
      flex-flow: row;
      margin-bottom: 5px;

      i {
        font-size: 38px;
        color: #ededed;
        margin-right: 10px;
      }

      .titleContainer {
        display: flex;
        flex-flow: column;

        .title {
          font-size: 14px;
          font-weight: 500;
          color: #555;
        }

        .deadline {
          color: #aeaeae;
          font-weight: 300;
          min-height: 15px;
        }

        .deadlineOverdue {
          color: #ff8989 !important;
        }
      }
    }

    .headerBottom {
      height: 10px;
      width: 100%;
      border-radius: 10px;
    }
  }

  .assignmentInfoContainer {
    font-size: 12px;
    margin-bottom: 10px;
    display: flex;
    flex-flow: column;

    .assignmentInfoTitle {
      display: flex;
      flex-flow: row;
      font-weight: bold;
      align-items: center;

      i {
        margin-right: 5px;
      }
    }

    .assignmentInfoText {
      font-size: 12px;
      display: flex;
      flex-flow: column;

      .assignmentInfoTextAssigned {
        display: flex;
        flex-flow: row;
        align-items: center;

        i {
          margin-right: 5px;
        }
      }

      .assignmentInfoTextResponsible {
        display: flex;
        flex-flow: row;
        align-items: center;

        i {
          margin-right: 5px;
        }
      }
    }
  }

  .buttonOpenTask {
    margin-bottom: 10px;
    width: fit-content;
    background: #708fa0;
    color: #fff !important;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;

    &:hover {
      background: #628394;
    }

    i {
      margin-right: 5px;
    }
  }
}
