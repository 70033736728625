@import 'resources/styles/mixins.scss';

.filter {
  font-size: 12px;
  margin-bottom: 10px;
  border-radius: 0 0 4px 4px;
  padding: 10px;
}

.filterHead {
  padding: 5px 0 12px 13px;
  border-bottom: 1px solid #ddd;
  color: #555555;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  &Title {
    margin: 0 2px 0 4px;

    &Wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      cursor: pointer;
    }

    &Count {
      font-size: 10px;
      font-weight: 700;
      margin-right: 2px;
    }
  }

  &ButtonWrapper {
    height: 17px;
    display: flex;
    align-items: center;
  }

  &Icon {
    font-size: 14px;
  }
}

.filterOptions {
  display: none;
  overflow: hidden;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-top: 0;
  border-radius: 0 0 4px 4px;

  &Inner {
    display: flex;
    padding: 15px 15px 15px 1px;
  }
}

.filterColumn {
  padding: 0 10px;
  flex: 1;

  &:not(:first-child) {
    border-left: 2px solid #ededed;
  }
}

.filterBlock {
  &Title {
    margin-bottom: 2px;
    color: #777;
  }
}

.filterList {
  padding-bottom: 3px;
  &Item {
    margin-bottom: 4px;
    font-weight: 600;
    color: #555555;
    user-select: none;

    i {
      width: 13px;
    }

    &Subtabs {
      padding-left: 13px;

      input {
        width: 100%;
        height: 35px;
        box-shadow: none;
        display: block;
        padding: 6px 12px;
        color: #555555;
        font-size: 14px;
        font-family: 'Open sans', sans-serif;
        background-color: #fff;
        border: 2px solid #ededed;
        border-radius: 4px;
        transition: border-color ease-in-out 0.15s;

        &:focus {
          border: 2px solid #6fdbe8;
        }
        &.input_border_error {
          border: 2px solid #ff8989;
        }
        &:disabled {
          background-color: #eeeeee;
          color: #eeeeee;
        }
      }
      ::-webkit-input-placeholder {
        color: #c0c0c0;
      }
      ::-moz-placeholder {
        color: #c0c0c0;
      } /* Firefox 19+ */
      :-moz-placeholder {
        color: #c0c0c0;
      } /* Firefox 18- */
      :-ms-input-placeholder {
        color: #c0c0c0;
      }
    }
  }
}

.detepickers {
  margin-top: 10px;
}
@include media-mobile {
  .filterColumn {
    flex: auto;
  }
  .filterOptions {
    &Inner {
      flex-wrap: wrap;
    }
  }
}
