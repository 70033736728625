.wrapper {
  display: flex;
  margin-bottom: 20px;
  div {
    margin-right: 20px;
  }
  .photo {
    width: 60px;
    img {
      height: 60px;
      width: 60px;
    }
  }
  .status {
    width: 100px;
  }
  .name {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    width: 120px;
    flex-shrink: 0;
    .parent {
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
    }
  }
  .statusWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .wickelnWrap {
      display: flex;
      align-items: center;
    }
    .messageWrap {
      display: flex;
      .btnNote {
        margin-left: -15px;
      }
      input {
        width: 297px;
      }
    }
  }

  .changeStatus {
    width: 34px;
    height: 30px;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 10px;
  }

  .changeStatusCheckOut {
    background-color: #ffaf00;
    margin-left: 0;
  }
  .changeStatusCheckIn {
    background-color: #08803a;
    margin-right: 20px;
  }

  .wickelCounter {
    margin: 0 10px;
  }

  .buyButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 35px;
    width: 35px;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

.statusIn {
  color: #08803a;
}

.statusOut {
  color: #ffaf00;
}

.statusAbsent {
  color: #ff4532;
}
