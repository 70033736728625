.container {
  .text {
    font-size: 13px;
    padding: 21px;
  }
}

.footerNavigation {
  display: flex;
  justify-content: center;
  & > div {
    margin: 0 5px;
  }
}
