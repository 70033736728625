.body {
  width: 100%;
}
.container {
  padding: 10px;
  .heading {
    font-size: 13px;
    margin-bottom: 10px;
  }
}
