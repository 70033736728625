.inputGroup {
  margin-bottom: 15px;

  &KitaCode {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &Button {
      margin-right: 15px;
      margin-top: 5px;
    }

    &Input {
      flex: 1;
      opacity: 0.7;
    }
  }
}

.awoLogo {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.verificationImages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.submit {
  margin-top: 30px;
  margin-bottom: -40px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.error {
  color: #ff8989;
  font-size: 13px;
}

.inputHalf {
  width: calc((100% - 7px) / 2);
  margin-bottom: 5px;

  &Container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.inputFull {
  width: calc(100% - 7px);
  margin-bottom: 5px;

  &Container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.fullWidth {
  width: 100%;
}

.colorPicker {
  padding: 13px;
  width: 44px;
  border-radius: 3px;
  background-color: #ededed;
}

.uploadButtons {
  margin-bottom: 10px;
  display: flex;
}

.deleteButton {
  margin-left: 5px;
}

.submitCancel {
  margin-left: 10px;
}

.holidaysCareContainer {
  width: 100%;
  padding: 10px;

  .holidayCare {
    display: grid;
    grid-gap: 8px;
    align-items: center;

    .holidayCareHeader {
      width: 600px;
      padding-bottom: 5px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border-bottom: 1px solid #ccc;
      font-weight: bold;
    }

    .holidayCareRow {
      display: flex;
      gap: 10px;
      align-items: center;

      .holidayCareDateRow, .holidayCareTimeRow {
        width: 600px;
        padding-bottom: 5px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
        align-items: center;
  
        input {
          width: 140px;
          font-size: 14px;
        }
      }
    }
  }
}