.container {
  position: relative;

  h3 {
    font-weight: 600;
  }

  .title {
    margin: 10px 0;
    font-size: 20px;
    font-weight: 300;
  }

  .text {
    margin-bottom: 20px;
  }

  .employeeList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 10px 30px 10px;
  }

  .withBackground {
    background-color: #f5f5f5;
  }
}
