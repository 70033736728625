.container {
  margin-bottom: 10px;
  padding: 0 20px;

  .inputContainer {
    padding-right: 40px;
    position: relative;
    margin-bottom: 5px;
    
    input[type='text'] {
      padding-left: 12px;
      border-radius: 4px 0px 0px 4px;
    }

    .addChecklistButton {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      width: 40px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #555555;
      background-color: #eeeeee;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      cursor: pointer;
    }

    .deleteChecklistButton {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      width: 40px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #555555;
      background-color: #eeeeee;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}