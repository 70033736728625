.container {
  margin-bottom: 10px;
  padding: 22px 20px;
}
.tabLayout {
  font-size: 13px;
  margin-top: 28px;
  .heading {
    margin-bottom: 18px;
  }
}
.footerNavigation {
  display: flex;
  justify-content: center;

  & > div {
    margin: 0 5px;
  }

  @media screen and (max-width: 555px) {
    justify-content: flex-start;
    flex-wrap: wrap;

    & > div {
      margin-bottom: 5px;
    }
  }
}
