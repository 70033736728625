.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &Lefted {
    justify-content: flex-start;

    .chooseLanguageText {
      color: #777777;
      font-weight: 700;
    }
  }

  form {
    height: 23px;
  }

  option {
    display: block;
    font-size: 14px;
    font-family: Open Sans, sans-serif;
    white-space: pre;
    min-height: 1.2em;
    padding: 0px 2px 1px;
  }

  select {
    text-transform: none;
    font-family: Open Sans, sans-serif;
    font-size: 12px !important;
    line-height: inherit;
    color: #555555;
  }
}

.chooseLanguage {
  color: #555555;
}

.chooseLanguageText {
  padding-right: 7px;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  line-height: 17px;
  color: #555;
}
