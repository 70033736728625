.generalSettingsContainer {
  .formSettingGroups {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
    .formSettingLabel {
      font-weight: bold;
    }
    .settingWrapper {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      div {
        &:first-child {
          flex: 1;
        }
      }
    }
  }
}
