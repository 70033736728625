.card {
  background-color: #fff;
  box-shadow: 0 0 3px #dadada;
  border-radius: 4px;
  position: relative;
  margin-bottom: 15px;
  height: auto !important;
}

.header {
  font-weight: 300;
  font-size: 16px;
  color: #555;
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
}

.content {
  display: none;
  &.toggled {
    display: block;
  }
}

.moreBtn {
  position: absolute;
  right: 10px;
  top: 10px;
}
