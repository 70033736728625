.container {
  padding: 0px 10px;
  position: relative;

  .titleContainer {
    margin-bottom: 10px;

    .title {
      font-weight: 300;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .description {
      color: #aeaeae !important;
      font-size: 12px;

      &:hover {
        color: #7a7a7a !important;
      }
    }
  }

  .addTasklistButtonContainer {
    position: absolute;
    top: -10px;
    right: 10px;
    display: flex;
    gap: 8px;

    .addTasklistButton {
      background: #97d271;
      color: #fff !important;
      padding: 4px 8px;
      font-size: 12px;

      &:hover {
        background: #89cc5e;
        border-color: #398439;
      }

      i {
        margin-right: 5px;
      }
    }

    @media screen and (max-width: 555px) {
      position: static;
    }
  }

  .toggleTasklistButtonContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 10px;

    .toggleTasklistButton {
      background: #ededed;
      color: #7a7a7a !important;
      padding: 1px 5px;
      font-size: 12px;

      &:hover {
        background: #e8e8e8;
        border-color: #adadad;
      }

      i {
        margin-right: 3px;
      }
    }
  }

  .taskListsContainer {
    display: flex;
    flex-flow: column;
  }
}

.addEditWrapper {
  padding: 15px;
  margin-top: 10px;

  .inputWithColorPicker {
    padding-left: 40px;
    position: relative;
    input[type='text'] {
      padding-left: 12px;
      border-radius: 0px 4px 4px 0px;
    }

    .colorPicker {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      .label {
        width: 40px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ededed;
        margin-bottom: 2px;
        border-radius: 2px 0px 0px 2px;
        cursor: pointer;
        .current {
          width: 16px;
          height: 16px;
        }
      }
      .picker {
        background: white;
        width: 130px;
        height: 146px;
        position: absolute;
        padding: 6px 5px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        &:before {
          position: absolute;
          content: '';
          border: 8px solid transparent;
          border-bottom: 8px solid #dedede;
          z-index: 2;
          bottom: 100%;
          left: 5px;
        }
        &:after {
          position: absolute;
          content: '';
          border: 6px solid transparent;
          border-bottom: 6px solid white;
          z-index: 2;
          bottom: 100%;
          left: 7px;
        }
        .currentColor {
          width: 100%;
          height: 9px;
        }
      }
    }
  }

  .checkbox {
    margin-top: 10px;
  }
}

.addEditButtonsContainer {
  margin-top: 20px;
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonSave {
    margin-right: 10px;
  }
}

.deleteWrapper {
  padding: 15px;
  margin-top: 10px;
  text-align: center;
}

.deleteButtonsContainer {
  margin-top: 10px;
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonCancel {
    margin-right: 10px;
  }
}
