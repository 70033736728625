@import 'resources/styles/mixins.scss';

.loader {
  margin-top: 10px;
}

.dropdown {
  position: absolute;
  z-index: 100;
  width: 350px;
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .loadingContainer {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .markAllAsRead {
    font-weight: 600;
    font-size: 13px;
    line-height: 12px;
    color: #6fdbe8;
    cursor: pointer;
  }

  @include media-mobile {
    left: -10px;
    transform: none;
    width: 290px;
    max-width: calc(100vw - 30px);

    &Messages {
      left: -50px;

      .arrow {
        left: 106px;
      }
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 300;
  color: #bebebe;
  margin-bottom: 3px;
}

.arrow {
  position: absolute;
  left: 50%;
  top: -9px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 11px;
  border-top-width: 0;
  border-bottom-color: #fff;

  @include media-mobile {
    left: 6px;
    transform: none;
  }
}

.notificationsContainer {
  max-height: 60vh;
  overflow: scroll;
}
.placeholder {
  padding: 10px;
  color: #555;
  border-left: 3px solid transparent;
}

.messagesContainer {
  max-height: 400px;
  overflow: auto;
}

.button {
  font-size: 13px;
  &Default {
    &:hover {
      background: none;
    }
  }
}

.newMessageBtn {
  position: absolute;
  top: 19px;
  right: 12px;
  padding: 5px 10px;
}

.createButton {
  background: #257d9d;
  color: #fff !important;
  padding: 4px 8px;
  font-size: 12px;
  &.createButtonLoading {
    color: transparent !important;
  }

  &:hover {
    background: #257d9d;
  }

  @include media-mobile {
    padding: 3px 6px;
    font-size: 11px;
  }

  i {
    margin-right: 5px;
  }
}

.emptyContainer {
  padding-left: 10px;
}

.addNewChatContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  @media only screen and (max-width: 600px) {
      flex-direction: column;
  }
}

.deleteNotification {
  margin-left: 20%  ;
}

.deleteCheckbox {
  margin-left: 3.7%;
  display: flex;
}

.checkbox_text__XCv68 {
  margin-left: 4%;
}

.selectAllNotif {
  margin-top: 2%;
}

.filterContainer {
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  div {
    padding: 6px 8px;
    cursor: pointer;
    flex: 1;
    color: white;
    text-align: center;
    text-transform: capitalize;
    white-space: nowrap;
    background-color: #257d9d;
    border-radius: 4px;
    &.active {
      font-weight: bold;
      background-color: #1e5f73;
      color: #fff;
    }
  }
}
