.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 40px;
}
.languageDropDown {
  display: flex;
  justify-content: center;
}

.link {
  color: #b8c7d3;
  margin-right: 10px;
  font-size: 12px;
  text-decoration: underline;

  &:last-child {
    margin-right: 0;
  }

  &s {
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
