@import 'resources/styles/mixins.scss';

.container {
  padding: 10px;
  position: relative;

  @include media-mobile {
    padding-top: 36px;
  }
}

.titleContainer {
  margin-bottom: 15px;

  .titleHeader {
    font-weight: 300;
    font-size: 150%;
  }

  .tittleDescription {
    margin-top: 5px;
    color: #aeaeae !important;
    font-size: 12;

    &:hover {
      color: #7a7a7a !important;
    }
  }
}

.createButton {
  background: #97d271;
  color: #fff !important;
  position: absolute;
  top: 0;
  right: 10px;

  @include media-mobile {
    right: unset;
    left: 10px;
  }

  &:hover {
    background: #89cc5e;
    border-color: #398439;
  }

  i {
    margin-right: 5px;
  }
}

.eventTypesViewTitle {
  font-size: 13px;
  margin-bottom: 5px;
}

.eventTypesViewList {
  display: flex;
  flex-flow: column;

  .eventTypesViewItem {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 6px 12px;
    align-items: center;
    background-color: #eeeeee;
    margin-bottom: 5px;
    border-radius: 4px;
    word-break: break-word;

    .eventTypesViewItemLeft {
      display: flex;
      flex-flow: row;
    }

    .eventTypesViewItemColor {
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    .eventTypesViewItemRight {

      .eventTypesViewItemTypeEditContainer {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-left: 3px;

        .editButton {
          height: 20px;
          width: 20px;
          padding: 3px 5px;
          background: #708fa0;
          color: #fff !important;
          border-radius: 3px;
          font-size: 12px;
          margin-right: 5px;  
        }
        
        .deleteButton {
          height: 20px;
          width: 20px;
          padding: 4px 5px;
          background: #ff8989;
          color: #fff !important;
          border-radius: 3px;
          font-size: 12px; 
        }
      }
    }
  }
}

.inputWithColorPicker {
  margin: 10px;
  margin-bottom: 30px;
  padding-left: 40px;
  position: relative;
  input[type='text'] {
    padding-left: 12px;
    border-radius: 0px 4px 4px 0px;
  }
}

.colorPicker {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  .label {
    width: 40px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ededed;
    margin-bottom: 2px;
    border-radius: 2px 0px 0px 2px;
    cursor: pointer;
    .current {
      width: 16px;
      height: 16px;
    }
  }
  .picker {
    background: white;
    width: 130px;
    height: 146px;
    position: absolute;
    padding: 6px 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    &:before {
      position: absolute;
      content: '';
      border: 8px solid transparent;
      border-bottom: 8px solid #dedede;
      z-index: 2;
      bottom: 100%;
      left: 5px;
    }
    &:after {
      position: absolute;
      content: '';
      border: 6px solid transparent;
      border-bottom: 6px solid white;
      z-index: 2;
      bottom: 100%;
      left: 7px;
    }
    .currentColor {
      width: 100%;
      height: 9px;
    }
  }
}

.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  
  .buttonSave {
    margin-right: 10px;
  }
}

.confirmButtonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonDelete {
    margin-left: 10px;
  }
}

.confirmText {
  text-align: center;
  margin: 10px;
}
