.bookingTableInput input {
  width: 100%;
  height: 35px;
  box-shadow: none;
  display: block;
  padding: 6px 12px;
  color: #555555;
  font-size: 14px;
  font-family: 'Open sans', sans-serif;
  background-color: #fff;
  border: 2px solid #ededed;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s;

  &:focus {
    border: 2px solid #6fdbe8;
  }

  &.input_border_error {
    border: 2px solid #ff8989;
  }

  &:disabled {
    background-color: #eeeeee;
    color: #eeeeee;
  }
}

.bookingTimesContainer {
  width: 100%;
}

.bookingGrid {
  display: grid;
  grid-gap: 8px;
  align-items: center;
  padding: 8px;

  .gridHeader {
    display: grid;
    font-weight: bold;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
  }

  .gridRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;

    input[type='time'] {
      width: 100px;
    }
  }
}