.popup {
  &Text {
    text-align: center;
  }

  &Btns {
    & > * {
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.imagePopupContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .btnRotate {
    cursor: pointer;
    font-size: 16px;
    margin-left: auto;
  }
}

