.popup {
  &Text {
    text-align: center;
  }

  &Btns {
    & > * {
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &InputContainer {
    margin-bottom: 15px;
  }

  &Checkbox {
    margin: 0;
  }

  &Note {
    margin: 10px 0;
    color: #aeaeae;

    &:hover {
      color: #737373;
    }
  }
}

