.EditButtonsContainer {
  display: flex;
  align-items: center;

  .editCheckInOutControls {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 3px;
    background-color: #e3e3e3;
    border-radius: 3px;

    .overtimeInputContainer {
      display: flex;
      gap: 5px;

      .overtimeInput {
        width: 80px;
      }
    }

    .editCheckInOutButtonGroup {
      display: flex;
      gap: 5px;

      .approveBtn {
        background-color: #97d271;
        &:hover {
          background-color: #89cc5e;
        }
      }
      .cancelBtn {
        background-color: #ff8989;
        &:hover {
          background-color: #ff6f6f;
        }
      }

      .approveBtn,
      .cancelBtn {
        i {
          color: white;
        }
      }
    }
    .editCheckInOutButton {
      background-color: #ff8989;
      &:hover {
        background-color: #ababab;
      }
    }
  }

  .editCheckInOutButton {
    background-color: transparent;
    &:hover {
      background-color: #d6d3d3;
    }
  }

  .errorMessageContainer {
    color: #f44b4b;
    font-weight: 600;
    font-size: 0.75rem;
  }
}

.popupBody {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .popupOvertimeCaption {
    font-size: 1rem;
  }
}

.reviewButtonGroup {
  display: flex;
  gap: 5px;
}
