.smallRightMargin {
  margin-right: 5px;
}

.mediumBottomMargin {
  margin-bottom: 15px;
}

.largeTopMargin {
  margin-top: 25px;
}

.largeBottomMargin {
  margin-bottom: 25px;
}
