@import 'resources/styles/mixins.scss';

.panel {
  background-color: #ffffff;
  margin-bottom: 15px;

  &Top {
    border: 3px solid #fff;
    border-radius: 3px;
    user-select: none;

    &Banner {
      position: relative;
      width: 100%;
      height: 100%;
      height: 110px;

      &WithImage {
        height: 192px;

        @include media-laptop {
          height: 158px;
        }

        @include media-tablet {
          height: 121px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
      }

      &Fade {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        pointer-events: none;

        &:not(:hover)>* {
          display: none;
        }

        &:after {
          content: '';
          width: 100%;
          height: calc(100% + 20px);
          position: absolute;
          top: -20px;
          left: 0;
          background: linear-gradient(to bottom,
              rgba(0, 0, 0, 0) 0,
              rgba(0, 0, 0, 0) 1%,
              rgba(0, 0, 0, 0.38) 100%);
        }
      }
    }
  }
}

.textWrapper {
  padding-left: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  color: #ffffff;
  pointer-events: none;
}

.title {
  font-size: 30px;
  margin-bottom: 1px;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 100;

  @include media-mobile {
    font-size: 20px;
  }
}

.description {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 14px;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;

  @include media-mobile {
    margin-bottom: 27px;
  }
}

.panelBottom {
  padding: 10px;
  position: relative;

  @include media-mobile {
    padding-bottom: 0;
  }

  &Wrapper {
    padding-left: 160px;
    height: 36px;
    display: flex;
    justify-content: space-between;

    @include media-mobile {
      flex-wrap: wrap;
      height: auto;
      padding-left: 0;
      padding-top: 44px;
    }
  }
}

.avatarContainer {
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: 1;
  bottom: -50px;
  left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6fdbe8;
  color: #ffffff;
  font-size: 61px;
  border-radius: 3px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border: 3px solid #fff;
  }
}

@include media-mobile {

  .buttons,
  .stats {
    margin-bottom: 10px;
  }
}

.buttons {
  flex: 1;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;
  position: relative;

  &MarginLeft {
    margin-left: 6px;
  }
}

.stats {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  @include media-mobile {
    min-width: 100%;
  }

  &Item {
    margin-left: 20px;

    &Count {
      color: #6fdbe8;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: -2px;
    }

    &Link {
      cursor: pointer;

      .statsItemTitle {
        color: #555555;
      }
    }

    &Title {
      font-size: 12px;
    }
  }
}

.noInformationAttention {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 17px;
}

.userProfileButtonContainer {
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  .userProfileButtonDropdownConntainer {
    margin-left: 5px;

    .dropDownTitle {
      margin-left: 5px;
      margin-right: 5px;
    }

    .acceptDropdown {
      padding: 5px;
      width: 25px;
      height: 35px;
      background: #97d271;
      border-radius: 3px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      color: #fff !important;

      i {
        font-size: 13px;
        color: #fff !important;
      }

      &:hover {
        background: #89cc5e;
      }
    }

    .friendDropdown {
      padding: 5px;
      width: auto;
      height: 35px;
      background: #97d271;
      border-radius: 3px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      color: #fff !important;

      i {
        font-size: 13px;
        color: #fff !important;
      }

      &:hover {
        background: #89cc5e;
      }
    }
  }
}

.editButton {
  background: #708fa0;
  color: #fff !important;

  &:hover {
    background: #628394;
  }
}

.addFriendButton {
  background: #6fdbe8;
  color: #fff !important;

  i {
    margin-right: 5px;
  }

  &:hover {
    background: #59d6e4;
  }
}

.cancelFriendRequestButton {
  background: #ff8989;
  color: #fff !important;

  &:hover {
    background: #ff6f6f;
  }
}

.acceptFriendRequestButton {
  background: #97d271;
  color: #fff !important;
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 10px;

  &:hover {
    background: #89cc5e;
  }
}

.friendButton {
  background: #97d271;
  color: #fff !important;
  padding: 0;

  &:hover {
    background: #89cc5e;
  }
}

.loader {
  justify-content: flex-end;
}