@mixin textEditor {
  margin-bottom: 10px;
  font-size: 13px;

  ol,
  ul {
    padding-left: 30px;
  }

  :global(.prosemirror-suggestion) {
    color: #777777 !important;
  }
}
