.gallery {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 5px;
  flex-wrap: wrap;
  &Images {
    max-width: 410px;
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: 200px;
      height: 125px;
      margin: 0 5px 5px 0;
    }
    &Image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;

      &Wrapper {
        width: 100%;
        height: 100%;
        position: relative;
      }

      &Overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        font-weight: 600;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.7);
        cursor: pointer;
        user-select: none;
      }
    }
  }

  &Info {
    margin-left: 15px;
    margin-bottom: 10px;

    &Title {
      padding-top: 10px;
      font-size: 11px;
      color: #777777;
      margin-bottom: 7px;
    }

    &ImageTitle {
      color: #6fdbe8;
      font-weight: 700;
    }

    &Description {
      font-size: 14px;
      margin: 5px 0;
    }
  }
}
