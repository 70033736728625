.wrapper {
    display: flex;
    margin-bottom: 20px;

    div {
        margin-right: 5px;
    }

    .photo {
        width: 60px;

        img {
            height: 60px;
            width: 60px;
        }
    }

    .status {
        width: 100px;
    }

    .name {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        font-size: 16px;
        font-weight: bold;
        width: 180px;

        .parent {
            font-size: 12px;
            font-weight: normal;
            font-style: italic;
        }
    }

    .group {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        flex-shrink: 0;
    }

    .note {
        min-width: 90px;
    }


    .trafficLight {
        width: 50px;
        height: 50px;
        background-color: black;
        border: 2px solid #333;
        border-radius: 10px;
        position: relative;
        margin-left: 37px;
    }

    .greenLight {
        width: 25px;
        height: 25px;
        background-color: #333;
        border: 2px solid #000;
        border-radius: 50%;
        position: absolute;
        bottom: 11px;
        left: 10px;
        background-color: green;
    }

    .redLight {
        width: 25px;
        height: 25px;
        background-color: #333;
        border: 2px solid #000;
        border-radius: 50%;
        position: absolute;
        bottom: 11px;
        left: 10px;
        background-color: red;
    }

    .statusWrap {
        display: flex;
        align-items: center;
        margin-left: 122px;
    
        .statusContainer {
          display: flex;
          flex-direction: column;
          hyphens: auto;
          font-size: 10px;
          align-items: center;
          justify-content: center;
          width: 55px;
    
          div {
            margin: 0;
          }
    
          .statusIconWrapper {
            width: 34px;
            height: 30px;
            border-radius: 3px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .statusCheckin {
          .statusIconWrapper {
            background-color: #08803a;
          }
          .statusLabel {
            color: #08803a;
          }
        }
    
        .statusCheckout {
          .statusIconWrapper {
            background-color: #ffaf00;
          }
          .statusLabel {
            color: #ffaf00;
          }
        }
      }
}