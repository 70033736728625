@import 'resources/styles/mixins.scss';

.container {
  padding: 10px;
  .description {
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
  }
  .checkbox {
    margin: 30px 0;
  }
}
.spacesContainer {
  margin: 10px 0 20px;
}

.grid {
  overflow-x: auto;
  .gridRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 16px 0;
    border-bottom: 1px solid #EDEDED;
    color: #777777;
    &:first-of-type > div {
      color: #AAAAAA;
    }
    &:first-of-type, &.noBorder {
      color: #777777;
      padding-bottom: 5px;
      border-bottom: none;
    }

    & > div {
      min-width: 80px;
      text-align: center;

      @include media-laptop {
        min-width: 60px;
      }
    }
    & > div:first-of-type {
      display: flex;
      flex: 1;
    }

    .nameDescriptionContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      .width100 {
        width: 100%;
        text-align: left;
      }
    }
    &Title > div {
      text-align: center;
    }
    .checkboxTextClass {
      min-width: 0;
    }
    .checkboxFlex {
      display: flex;
      justify-content: center;
    }
  }
}
.message {
  padding-bottom: 20px;
}
.successText {
  color: green;
}

.loadingContainer {
  display: flex;
  flex: 1;
  min-height: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tryAgainButton {
  margin-top: 15px;
}

.buttonsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  & button {
    white-space: normal;
  }

  & .buttonPrimary {
    margin: 0 8px 8px 0;
  }
}