.container {
  .headerContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .headerImage {
      pointer-events: none;
    }

    .banner {
      position: absolute;
    }

    .progressContainer {
      width: 100%;
      height: 100%;
      padding: 20px;
      background: #f8f8f8;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .progress {
        height: 10px;
        box-shadow: none;
        background: #ededed;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        max-width: 430px;

        .progressInfo {
          background: #6fdbe8;
          height: 100%;
        }
      }
    }

    .uploadHeaderContainer {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;

      &:hover {
        .uploadHeader {
          display: flex;
        }
      }

      .uploadHeader {
        position: absolute;
        right: 5px;
        bottom: 5px;
        display: none;
        align-items: center;
        justify-content: flex-end;
        z-index: 2;

        .uploadHeaderButton {
          margin-left: 5px;
        }
      }
    }
  }
}
