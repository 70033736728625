.input {
  margin-bottom: 15px;

  & + div i {
    font-size: 17px;
  }
}

.checkboxes {
  margin-left: -13px;
}

.deleteButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 0.2vw;
  margin-bottom: 15px;
}

.arrowIconButton {
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #eee;
  border: none;

  i {
    font-size: 17px;
    color: #555;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
}
