.filter {
  font-size: 12px;
  margin-bottom: 10px;
  padding-top: 2px;
  border-radius: 0 0 4px 4px;
}

.kitaFilter {
  text-align: center;
  padding-top: 15px;
}

.filterWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}

.filterHead {
  padding: 5px 0 12px 13px;
  border-bottom: 1px solid #ddd;
  color: #555555;
  display: flex;
  align-items: center;
  user-select: none;
  gap: 15px;

  &Title {
    margin: 0 2px 0 4px;
    font-size: 18px;

    &Wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
    }

    &Count {
      font-size: 10px;
      font-weight: 700;
      margin-right: 2px;
    }
  }

  &Button {
    font-weight: 600;
    color: #555555;
    font-size: 18px;
    user-select: none;

    span {
      font-size: 18px;
      color: #555;
      font-weight: 400;
    }
    div {
      margin-top: 0;
    }

    i {
      width: 22px;
    }

    img {
      background-color: #fff;
      border: 2px solid #ccc;
    }

    &Subtabs {
      padding-left: 13px;
    }
  }

  &ButtonWrapper {
    height: 17px;
    display: flex;
    align-items: center;
  }

  &Icon {
    font-size: 14px;
  }
}

.filterOptions {
  display: none;
  overflow: hidden;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-top: 0;
  border-radius: 0 0 4px 4px;

  &Inner {
    display: flex;
    padding: 15px 15px 15px 1px;

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}

.filterColumn {
  padding: 0 10px;
  flex: 1;

  &:not(:first-child) {
    border-left: 2px solid #ededed;
  }

  @media (max-width: 600px) {
    padding: 10px 10px 0;
    display: flex;
    justify-content: space-between;
    border-left: none;
    &:not(:first-child) {
      border-top: 2px solid #ededed;
    }
  }

  @media (max-width: 480px) {
    display: block;
  }
}

.filterBlock {
  &Title {
    margin-bottom: 2px;
    color: #777;
  }
}

.filterList {
  padding-bottom: 3px;
  &Item {
    margin-bottom: 4px;
    font-weight: 600;
    color: #555555;
    user-select: none;

    img {
      background-color: #fff;
      border: 2px solid #ccc;
    }

    .radioFilterItem {

      > div {
        width: 14px;
        height: 14px;
        min-width: 14px;
        margin: 3px 5px 3px 0;
      }

      > span {
        color: #777;
        font-size: 13px;
        font-weight: 400;
      }
    }

    i {
      width: 13px;
    }

    &Subtabs {
      padding-left: 13px;
    }
  }
}
