.loader {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  &Danger {
    background: #ff8989;
    color: #fff !important;

    &:hover {
      background: #ff6f6f;
    }
  }
  &Primary {
    background: #708fa0;
    color: #fff !important;

    &:hover {
      background: #628394;
    }
  }
}
.isLoading {
  color: transparent !important;
  position: relative;
  pointer-events: none;
  .loader {
    display: flex;
  }
}
