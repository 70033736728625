@import 'resources/styles/mixins.scss';

.item {
  border-left: 3px solid;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;

  &.themeYellow {
    border-left-color: #708fa0;
    background-color: #afcb05;
    color: #fff;

    &:hover,
    &.active {
      border-left-color: #6fdbe8;
      background-color: #628394;
    }
  }

  &.themeBlue {
    border-left-color: #708fa0;
    background-color: #051957;

    color: #fff;

    &:hover,
    &.active {
      border-left-color: #6fdbe8;
      background-color: #628394;
    }
  }

  &.themeBrown {
    border-left-color: #708fa0;
    background-color: #d7790f;

    color: #fff;

    &:hover,
    &.active {
      border-left-color: #6fdbe8;
      background-color: #628394;
    }
  }

  &.themeDark {
    border-left-color: #708fa0;
    background-color: #708fa0;
    color: #fff;

    &:hover,
    &.active {
      border-left-color: #6fdbe8;
      background-color: #628394;
    }
  }

  &.themeLight {
    border-left-color: #fff;
    background-color: #fff;
    color: #555;

    &:hover,
    &.active {
      border-left-color: #6fdbe8 !important;
      background-color: #f7f7f7;
    }
  }
}

.itemTitle {
  &.notification-de {
    @include media-laptop {
      white-space: normal;
    }
    @include media-tablet {
      margin-left: 8px;
    }
    @media screen and (max-width: 767px) {
      margin-left: 4px;
    }
    @media screen and (max-width: 700px) {
      margin-left: 8px;
    }
  }

  &.notification-en {
    @include media-laptop {
      white-space: normal;
    }
    @include media-tablet {
      margin-left: 4px;
    }
    @media screen and (max-width: 767px) {
      margin-left: 0px;
    }
    @media screen and (max-width: 600px) {
      margin-left: 5px;
    }
  }
}

.counter {
  font-size: 12px;
  border: 1px solid #ff6f6f;
  background-color: #ff6f6f !important;
  border-radius: 100%;
  margin-left: 10px;
  color: white;
  text-align: center;

  width: 18px;
  height: 18px;
  line-height: 18px;
}
