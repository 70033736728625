.toolBarIcon {
  display: inline-block;
  font-size: 40px;
  line-height: 50px;
  background-color: white;
  color: black;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: bottom;
  border-style: solid;
  border-width: thin;
  border-radius: 10px;
  cursor: pointer;
}

.toolBarIconLineWidth {
  display: inline-block;
  font-size: 40px;
  line-height: 50px;
  background-color: white;
  color: black;
  width: 90px;
  height: 50px;
  text-align: center;
  vertical-align: bottom;
  border-style: solid;
  border-width: thin;
  border-radius: 3px;
  cursor: pointer;
}

.toolBarIconSelected {
  background-color: black;
  color: white;

}

.toolBar {
  display: flex;
  gap: 5px;
  background-color: rgb(177, 169, 169);
  padding: 10px;
  flex-wrap: wrap;
}

.fileInput {
  display: none;
}

.imageUpload {
  cursor: pointer;
}

.textInput {
  display: inline-block;
  line-height: 50px;
  background-color: white;
  color: black;
  height: 50px;
  width: 150px;
  vertical-align: bottom;
  border-style: solid;
  border-width: thin;
  border-radius: 10px;
}

.numberInput {
  display: inline-block;
  line-height: 50px;
  background-color: white;
  color: black;
  height: 50px;
  width: 70px;
  vertical-align: bottom;
  border-style: solid;
  border-width: thin;
  border-radius: 10px;
}

.textInputWrapper {
  display: flex;
  line-height: 50px;
  height: 50px;
  background-color: white;
  border-radius: 10px;
}

.numberInputWrapper {
  display: flex;
  line-height: 50px;
  height: 50px;
  background-color: white;
  border-radius: 10px;
}

.textInputPlus {
  display: inline-block;
  width: 40px;
  text-align: center;
}