.tableRowButton {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.loader {
  height: 17px;
}

.container {
  cursor: pointer;
}