.container {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;

  @media (max-width: 600px) {
    .chatMessageWrapper {
      width: 180px;
      display: flex;
    }
  }
}

.imagesSortSelect {
  margin-bottom: 10px;
  border-radius: 5px;
}

.imagesContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.filesContainer {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 10px;
  border-top: 1px solid #d9d9d9;
}

.fileName {
  display: flex;
  margin: 15px 0;
}

.img {
  max-width: 200px;
  max-height: 500px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
}

.icon {
  font-size: 22px;
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.info {
  display: flex;
  max-width: calc(100vw - 50px);
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.name {
  font-size: 13px;
  color: #6fdbe8;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  user-select: none;
}

.playbackSpeed {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  .playbackSpeedButtons {
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    div {
      padding: 2px 6px;
      cursor: pointer;
    }
    div:not(:last-child) {
      border-right: 1px solid #ddd;
    }
    .active {
      font-weight: bold;
    }
  }
}
