.signup {
  &_topMargin {
    margin-top: 0;
  }

  &_container {
    max-width: 300px;
    margin: 0 auto 20px;
    margin-top: 20px;
    padding-bottom: 15px;
    text-align: left;
    width: 100%;
  }

  &_heading {
    padding: 15px;

    &_2 {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      padding: 10px 32px 15px 15px;
      color: #555555;
    }

    &_info {
      display: flex;
      flex-direction: column;

      &_checkbox {
        margin-top: 20px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        & input {
          margin-right: 5px;
          flex-shrink: 0;
        }
      }
    }

    &_text_sign_in {
      font-weight: 300;
      color: #555555;

      strong {
        font-weight: 700;
        color: #555555;
      }
    }
  }

  &_btn_container {
    margin: 0 15px;
  }

  &_btn_width {}

  &_in_btn {
    height: 36px;
    background: #708fa0;
    padding: 8px 14px;
    border-radius: 3px;
    cursor: pointer;
  }

  &_in_btn_hover:hover {
    background: #286090;
    cursor: pointer;
  }

  &_in_btn_disabled:disabled {
    height: 36px;
    padding: 8px 14px;
    background: #708fa0;
    opacity: 0.7;
    border-radius: 3px;
    cursor: pointer;
  }

  &_in_btn_text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
}

.horizontal_line {
  height: 1px;
  margin: 15px;
  margin-bottom: 10px;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.email_form {
  padding: 0px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
}

.error_msg {
  padding: 0 15px;
}

.formGroup {
  padding: 0px;
}