.historyTable {
  overflow-x: scroll;
}

.header {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;
  width: max-content;

  div {
    flex-shrink: 0;
    margin-right: 15px;
  }

  .executivePersonTitle {
    text-align: center;
    width: 170px;
  }

  .actionTitle {
    text-align: center;
    width: 120px;
  }

  .name {
    width: 165px;
  }

  .avatar {
    width: 40px;
  }

  .action {
    text-align: center;
    width: 120px;
  }

  .group {
    width: 100px;
    text-align: center;
  }

  .pickupPerson {
    width: 120px;
    text-align: center;
  }

  .pickupPerson {
    width: 120px;
    text-align: center;
  }

  .date {
    width: 100px;
    text-align: center;
  }

  .note {
    text-align: center;
    width: 250px;
  }
}

.searchInputWrap {
  margin: 10px 0;

  input {
    width: 60%;
  }
}