.input {
  width: 80%;
  margin-left: 10%;
  margin-bottom: 2em;
}

.selectContainer {
  margin-left: 10%;
  margin-bottom: 2em;
}

.selectLabel {
  margin-right: 1em;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.pageContainer {
  padding-bottom: 2em;
}

.fileDropArea {
  width: 50%;
  height: 4em;
  border: 1px solid #565d61;
  border-radius: 3px;
  background-color: #7693a3;
  color: black;
  font-weight: bold;
  margin: 0 auto 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileInput {
  display: none;
}

.attachmentContainer {
  margin-left: 10%;
  margin-bottom: 2em;
}
