.employeeItemContainer {
  padding: 20px;
  display: flex;
  gap: 0 10px;
  flex-direction: row;

  .profile,
  .employeeInfoContainer {
    gap: 10px;
  }

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .profileAvatar {
      height: 80px;
      width: 80px;
      border-radius: 3px;
    }
    .profileName {
      font-size: 1rem;
      width: 150px;
      text-align: center;
      color: #333;
    }
  }

  .employeeInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid #c8c8c8;
    padding-left: 10px;

    .holidaysInputContainer {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 15px;

      .holidaysInputWrapper {
        display: flex;
        align-items: center;
        gap: 3px;

        .daysLabel {
          width: 180px;
          display: inline-flex;
        }

        .daysAvailable {
          width: 80px;
        }

        .holidaysInput {
          width: 80px;
        }

        .heading {
          margin-top: 15px;
          font-weight: 600;
        }
      }
    }
  }
}

.subtractLegalBreakCheckbox,
.holidayOnOvertimeCheckbox {
  margin-top: 0;
  margin-left: 0;
}

.subtractLegalBreakCheckbox {
  padding: 10px 0;
}

.employeeInfoContainer {
  label {
    font-weight: 600;
  }
}
