@import 'resources/styles/mixins/table.scss';
@include table;

.button {
  position: absolute;
  top: 0;
  right: 0;
}

.admins {
  display: flex;
  flex-wrap: wrap;
  max-width: 254px;
}
