.title {
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #ffffff;
  background-color: #a7db33;
  padding-left: 5px;
}
.disclaimerContent {
  margin-top: 20px;

  .disclaimerTitle {
    font-size: 17px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #555555;
  }
  .disclaimerText {
    font-size: 15px;
    margin-bottom: 20px;
    color: #555555;
  }
}
