@import 'resources/styles/mixins.scss';

.presetValueTypeInputContainer{
    width: 100%;
    margin-right: 5px;
}

.dropdownButton{
    padding: 0;
    border: none;
    background: none;
}

.dropdownContainer {
    width: 100%;
    position: relative;
    text-transform: none;

    @include media-mobile {
        max-width: calc(100vw - 70px);
    }
}

.dropdown{
    background-color: #fff;
    box-shadow: 0 0 3px #dadada;
    border-radius: 4px;
    position: relative;
    margin-bottom: 15px;
    height: auto !important;
}

.dropdownItem{
    border-top: 1px solid #e5e5e5;
    margin: 3px 0;
    padding: 10px;
    border-bottom: 1px solid #eee;
    position: relative;
    border-left: 3px solid white;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}