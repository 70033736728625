.wrapper {
  padding: 20px;
}
.description {
  margin-bottom: 15px;
}
.send {
  margin-top: 30px;
}
.checkbox {
  margin-bottom: 15px;
}
