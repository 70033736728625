.title {
  font-size: 20px;
  font-weight: 300;

  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
}

.button {
  margin-right: 5px;
}

.formGroup {
  margin-bottom: 10px;

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }
}

.select {
  overflow: auto;
  max-height: 200px !important;
  font-size: 13px;
  width: 100%;
}

.inputBirthday {
  width: 100%;
}
.checkbox{
  margin-left: 0;
}


.QRbuttonDiv {
  display: flex;
}

.QRbutton {
  height: 35px;
  margin-top: 15px;
}


.QR {
  margin-left: 12px;
}

