.container {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: 300;
}
