.container {
  padding: 21px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px;
}

.header {
  margin-bottom: 15px;
  border-bottom: none;
  text-align: center;
  color: #555;
  font-weight: 300;
  font-size: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  & > div {
    margin: 0 5px;
  }
}

