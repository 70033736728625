@import 'resources/styles/mixins.scss';

.suggestionBoxIcon{
  width: 32px;
  height: 32px;
  border-radius: 3px;
}

.container {
  display: flex;
  flex-flow: column;
  padding: 10px;
  border-bottom: 1px solid #eee;
  position: relative;
  font-size: 13px;
  border-left: 3px solid white;
  cursor: pointer;

  &:hover {
    background-color: #f7f7f7;
    border-left: 3px solid #6fdbe8;
  }

  .content {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    margin-bottom: 5px;

    .avatarContainer {
      margin-right: 10px;

      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 3px;
      }
    }

    .lastMessageInfoContainer {
      display: flex;
      flex-flow: column;
      font-size: 14px;
      font-weight: 500;
      color: #555;

      .header {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;

        .userInfo {
          margin-right: 3px;
          padding-right: 3px;
        }

        .date {
          font-size: 11px;
          color: #bebebe;
          margin: 3px;
        }

        .deleteButton {
          margin-left: 10px;
          margin-right: 0;
          cursor: pointer;
          border: none;
          background: none;
        }
      }

      .chatName {
        color: #aeaeae;
        font-weight: 300;
        margin-bottom: 5px;
        min-height: 15px;
      }

      .textContainer {
        display: flex;
        flex-flow: row;

        ol, ul {
          padding-left: 30px;
        }

        .newLabel {
          background-color: #ff8989;
          border-radius: 0.25em;
          margin-left: 5px;
          height: 17px;
          padding: 1px 5px;
          font-weight: 600;
          font-size: 10px !important;
          color: white !important;
          vertical-align: baseline;
          white-space: nowrap;
        }
      }
    }
  }
}

.avatarButton {
  padding: 0px;
  margin-left: 0px;
  background-color: black;
  border-radius: 3px;
  height: 20px;
  width: 20px;
  align-self: flex-end;

  &:hover {
    background-color: black;
  }
}

.avatarsContainer {
  position: relative;
  height: 4px;

  .avatars {
    margin-left: 0px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    opacity: 0.5;
  }
}

.avatarSingleNumber {
  position: absolute;
  top: 0px;
  right: 5px;
  color: #fff;
  font-size: 10px;
}

.avatarDoubleNumber {
  position: absolute;
  top: 0px;
  right: 3px;
  color: #fff;
  font-size: 9px;
}

.avatarTrippleNumber {
  position: absolute;
  top: 2px;
  right: 1px;
  font-size: 7px;
  color: #fff;
}

.containerActive {
  background-color: #f7f7f7;
  border-left: 3px solid #6fdbe8;
}




