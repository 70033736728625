.childrenContainerWrapper {
  overflow-x: auto;
}
.header {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;
  .name {
    width: 180px;
    margin-right: 5px;
    margin-left: 65px;
    text-align: left;
    cursor: pointer;
  }
  .class {
    width: 100px;
    margin-right: 5px;
    text-align: center;
    cursor: pointer;
  }
  .group {
    width: 100px;
    margin-right: 5px;
    text-align: center;
    cursor: pointer;
  }
  i {
    margin-left: 1rem;
  }
}
.searchInputWrap {
  margin: 10px 0;
  input {
    width: 60%;
  }
}
