.container {
  margin: 1em 0;
}

.title {
  color: black;
  margin-bottom: 0.7em;
  font-size: 30px;
}
.content {
  min-height: 430px;

  .moduleTitle {
    color: black;
    font-weight: 700;
    font-size: 22px;
    text-decoration: underline;
  }

  .moduleDuration {
    color: black;
    font-weight: bold;
    font-size: 16px;
    margin: 0.2em 0;
    span {
      color: blue;
      font-weight: 600;
    }
  }

  .moduleGoals {
    color: black;
    font-weight: bold;
    font-size: 16px;
  }
  .moduleGoalsList {
    li {
      color: black;
      font-size: 16px;
    }
  }

  .verticalBorder {
    margin: 0.8em 0;
    border-bottom: 1px solid #ccc;
  }
  .text {
    white-space: pre-line;
    color: black;
    font-size: 16px;
  }
}
