@import 'resources/styles/mixins.scss';

.kitaListItemContainer {
  position: relative;
  width: 100%;
  padding: 11px 0;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 4px;

  &:hover {
    background-color: #f7f7f7;
  }

  &.kitaListItemContainerActive {
    background-color: #e2f8fa;
    z-index: 1;
  }

  &.kitaListItemContainerAwoWwActive {
    background: none;
  }

  &.opened {
    justify-content: flex-start;
  }

  .kitaListItemAvatarWrapper {
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1350px) {
      width: 58px;
      height: 58px;
      padding: 9px 0;
    }

    @include media-mobile {
      width: 46px;
      height: 46px;
      padding: 7px 0;
    }

    &.active {
      border: 3px solid #6fdbe8;
    }

    &.aoWwActive {
      border: 3px solid #e2001A;
    }

    .textLight {
      color: #ffffff;
    }

    .textDark {
      color: #000000;
    }

    .kitaListItemAvatarContainer {
      position: relative;
      width: 58px;
      height: 58px;
      font-size: 26px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      &Logo {
        image-rendering: -webkit-optimize-contrast;
        image-rendering: optimize-contrast;
      }

      &Inner {
        width: 100%;
        height: 100%;
        overflow: hidden;

        &>div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 1350px) {
        width: 46px;
        height: 46px;
        font-size: 21px;
      }

      @include media-mobile {
        width: 34px;
        height: 34px;
        font-size: 16px;
      }
    }
  }

  .logoEditingContainer {
    font-size: 13px;
    color: #555555;
    position: absolute;
    top: 46px;
    left: 0px;
    display: flex;
    justify-content: center;
    width: 58px;
    height: 14px;
    background: #ffffff;
    opacity: 0;
    border-radius: 0px 0px 2px 2px;
    cursor: pointer;
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: 0.9;
    }

    @media screen and (max-width: 1350px) {
      width: 46px;
      height: 11px;
      top: 35px;
    }

    @include media-mobile {
      font-size: 11px;
      width: 34px;
      height: 8px;
      top: 26px;
    }

    &.openedContainer {
      opacity: 0.9;
      background-color: #708fa0;
    }

    .logoEditingContainerEventWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .logoEditingOptionsContainer {
      display: flex;
      justify-content: center;
      z-index: 1;
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      background-color: transparent;
      border-top: none;
      transition: all 200ms ease-in-out;

      @media screen and (max-width: 1350px) {
        top: 18px;
      }

      @include media-mobile {
        top: 16px;
      }

      &.opened {
        max-height: 1000px;
        opacity: 1;
        visibility: visible;
      }

      .logoEditingButtons {
        font-size: 11px;
        width: 18px;
        height: 16px;
        background-color: #708fa0;
        margin-right: 2px;

        color: #fff;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #628394;
          border: 1px solid #6fdbe8;
        }

        input {
          width: 0px;
          position: absolute;
          z-index: -100;
          opacity: 0;
        }
      }

      .logoEditingButtonsDelete {
        font-size: 11px;
        width: 18px;
        height: 16px;
      }
    }
  }

  .logoEditingContainerTop {
    font-size: 13px;
    color: #555555;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    width: 58px;
    height: 14px;
    background: #ffffff;
    opacity: 0;
    border-radius: 0px 0px 2px 2px;
    cursor: pointer;
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: 0.9;
    }

    @media screen and (max-width: 1350px) {
      width: 46px;
      height: 11px;
      top: 0px;
    }

    @include media-mobile {
      font-size: 11px;
      width: 34px;
      height: 8px;
      top: 0px;
    }

    &.openedContainer {
      opacity: 0.9;
      background-color: #708fa0;
    }

    .logoEditingContainerEventWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .logoEditingOptionsContainer {
      display: flex;
      justify-content: center;
      z-index: 1;
      position: absolute;
      top: -21px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      background-color: transparent;
      border-top: none;
      transition: all 200ms ease-in-out;

      @media screen and (max-width: 1350px) {
        top: -21px;
      }

      @include media-mobile {
        top: -21px;
      }

      &.opened {
        max-height: 1000px;
        opacity: 1;
        visibility: visible;
      }

      .logoEditingButtons {
        font-size: 11px;
        width: 18px;
        height: 16px;
        background-color: #708fa0;
        margin-right: 2px;
        border-radius: 3px;

        i {
          color: #fff;
          padding: 4px;
        }

        &:hover {
          transform: scale(1.1);
          transition: transform 0.1s ease-in;
        }

        input {
          width: 0px;
          position: absolute;
          z-index: -100;
          opacity: 0;
        }
      }

      .logoEditingButtonsDelete {
        font-size: 11px;
        width: 18px;
        height: 16px;
      }
    }
  }

  .unreadNotifications {
    position: absolute;
    top: 10px;
    right: 0px;
    padding: 3px 5px;
    background-color: #ff8989;
    border-radius: 2px;
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: white;
    font-size: 10px;
    line-height: 10px;
  }

  .unreadBottomNotifications {
    position: absolute;
    bottom: 10px;
    right: 0px;
    padding: 3px 5px;
    background-color: #848d01;
    border-radius: 2px;
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: white;
    font-size: 10px;
    line-height: 10px;
  }
}

.progress {
  height: 10px;
  box-shadow: none;
  background: #ededed;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: 430px;

  &Container {
    width: 100%;
    height: 100%;
    padding: 4px;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &Info {
    background: #6fdbe8;
    height: 100%;
  }
}
