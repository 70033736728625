@import 'resources/styles/mixins.scss';

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 4px;

  &HeaderItem {
    font-size: 11px;
    color: #bebebe;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &Sortable {
      font-weight: 700;
      cursor: pointer;
    }
  }

  tr:first-child td {
    padding-bottom: 2px;

    .checkbox {
      position: relative;
      top: -4px;
    }
  }

  tr:last-child td {
    border-bottom: none;
  }

  @media screen and (max-width: 555px) {
    tr {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    td {
      width: 100%;
    }
  }

  td {
    padding: 7px 5px 9px 5px;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;

    &:nth-child(2) {
      @include media-mobile {
        display: none;
      }
    }
  }
}

.title {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #555555;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &Icon {
    width: 19px;
  }

  &Value {
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    @include media-tablet {
      max-width: 400px;
    }

    @include media-mobile {
      max-width: 200px;
    }

    @media screen and (max-width: 555px) {
      max-width: 150px;
    }

    &:hover {
      color: #6fdbe8;
    }
  }
}

.checkbox {
  margin: 0;
  &Container {
    margin-right: 12px;
    width: 23px;
  }
}

.privacy {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-mobile {
    display: none;
  }
}

.size {
  font-size: 13px;
  white-space: nowrap;

  @include media-mobile {
    display: none;
  }
}

.textRight {
  text-align: right;
  white-space: nowrap;
}

.rates {
  font-size: 11px;
  color: #aeaeae;
  white-space: nowrap;

  &Wrapper {
    display: flex;
    justify-content: flex-end;

    &Likes {
      margin-right: 4px;
      position: relative;
      top: -2px;

      & * {
        font-size: 11px;
        color: #aeaeae;
        font-weight: 400;
      }
    }

    &Comments {
      cursor: pointer;
    }
  }
}

.avatar {
  .avatarImage {
    width: 21px;
    height: 21px;
    overflow: hidden;
    float: right;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
  }

  @include media-mobile {
    display: none;
  }
}

.updated {
  font-size: 12px;
  white-space: nowrap;

  @include media-mobile {
    display: none;
  }
}

.emptyAttention {
  border-radius: 4px;
  box-shadow: 0 0 3px #dadada;
  margin-top: 18px;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 13px;

  &Title {
    color: #555555;
    font-weight: 700;
    margin-bottom: 10px;
  }
}

.hideOnMobile {
  @include media-mobile {
    display: none;
  }
}
