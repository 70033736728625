.signatureCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.signatureBackground {
  width: 100%;
  z-index: 1;
}

.signatureContainer {
  border: 1px solid black;
  position: relative;
}

h1 {
  text-align: center;
  margin-bottom: 2.5em;
}

.editButtonContainer{
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  border-radius: 3px;
  z-index: 200;
  background: none repeat scroll 0 0 #00b04f;
  padding: 5px;
}

.editButton{
  background: none;
  border: none;
  color: white;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
}
.editButton i{
  margin-left: 5px;
}
.deactiveMode{
  background: none repeat scroll 0 0 #ff0000;
}

.activeMode{
  background: none repeat scroll 0 0 #00b04f;
}
