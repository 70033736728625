.calendarHeader {
  position: relative;

  .calendarSettingsButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ededed;
    border-radius: 3px;
    font-size: 14px;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background: #e8e8e8;
    }

    i {
      color: #7a7a7a;
    }
  }
}

.printContainer {
  position: relative;
  background-color: #f7f7f7;
  height: 50px;
}

.calendarContainer {
  padding: 10px;
  hyphens: auto;
  .taskEvent {
    display: flex;
    flex-flow: row;
    align-items: flex-end;

    i {
      margin-left: 3px;
      margin-right: 5px;
    }
  }

  @media screen and (max-width: 768px) {
    :global(.fc-header-toolbar) {
      flex-wrap: wrap;

      :global(.fc-right) {
        margin: 10px 0;
      }
    }
  }
}

.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonSubmit {
    margin-right: 10px;
  }
}

.container {
  padding: 10px;
}

.containerEnable {
  padding: 10px;
  text-align: center;
}
