@import 'resources/styles/mixins.scss';
.cardContainer {
  width: 100%;
  height: fit-content;
  margin-bottom: 0;
}

.bodyTitle {
  background-color: #f7f7f7;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.container {
  padding: 0px 10px;
  max-height: 525px;
  overflow: auto;
  @include media-mobile {
    max-height: calc(100vh - 360px);
  }
}

.wrapper {
  display: none;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5rem;

  @include media-mobile {
    display: flex;
  }
  .button {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    font-weight: 600;
    padding: 0.5rem;
  }
  .text {
    margin-left: 0rem;
  }
}
.noChatSelected {
  display: block;
  flex-grow: 1;
  @include media-mobile {
    display: none;
  }
}

.chatSelected {
  flex-grow: 1;
}

.buttonsContainerConfirm {
  margin-top: 10px;
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonCancelConfirm {
    margin-right: 10px;
  }
}

.confirmText {
  text-align: center;
  margin: 15px 10px;
}
