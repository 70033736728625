.container {
  margin-bottom: 10px;
  padding: 0 20px;
}

.uploadButton {
  width: max-content;
  background: #ededed;
  color: #7a7a7a;
  font-size: 13px;
  padding: 4px 8px;
  flex-flow: row;

  i {
    margin-right: 5px;
  }

  &:hover {
    background: #e8e8e8;
    border-color: #adadad;
  }
}
