.holidayTimesContainer {
  width: 100%;

  .categoryTitle {
    margin-top: 12px;
    font-weight: bold;
  }

  .holidayGrid {
    padding: 8px;
    display: grid;
    grid-gap: 8px;
    align-items: center;

    .gridHeader {
      padding-bottom: 5px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border-bottom: 1px solid #ccc;
      font-weight: bold;
    }

    .gridRow {
      padding-bottom: 5px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 8px;
      align-items: center;
      border-bottom: 1px solid #ccc;

      input[type='time'] {
        width: 100px;
      }
    }
  }
}
