@import 'resources/styles/mixins/table.scss';
@include table;
.wrapper {
  padding: 10px;
  width: 100%;
}
.inputDuration {
  margin-top: 10px;
}
.inputChild {
  .label {
    text-transform: none !important;
  }
}
.noWrap {
  white-space: initial;
}
.dateWrapper {
  margin-bottom: 20px;
  .label {
    display: block;
    text-transform: none;
    color: red;
  }
  .dateStartWrapper,
  .dateEndWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    & > div {
      margin-right: 20px;
    }
    input {
      font-size: 14px;
      color: #777;
      padding: 5px;
      border: 1px solid #ededed;
    }
  }
}
.footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  .saveButton {
    margin-right: 10px;
  }
}
.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonSubmit {
    margin-right: 10px;
  }
}

.container {
  padding: 10px;
}

.containerEnable {
  padding: 10px;
  text-align: center;
}

.header {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;
  .executivePersonTitle {
    width: 245px;
  }
  .actionTitle {
    width: 155px;
  }
  .name {
    width: 220px;
  }
  .avatar {
    width: 60px;
  }
  .action {
    width: 140px;
  }
  .date {
    width: 100px;
  }
}
.searchInputWrap {
  margin: 10px 0;
  input {
    width: 60%;
  }
}
.absenceItemActions {
  display: flex;
  grid-gap: 12px;

  i {
    cursor: pointer;
  }
}
td {
  &.absenceName {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
  }
  &.absenceReason {
    width: 250px;
    max-width: 250px;
    min-width: 250px;
  }
}
.dateWrapper {
  display: flex;
  gap: 25px;
  margin-top: 25px;
  margin-bottom: 0px;
}
.checkbox {
  margin-top: 25px;
}
