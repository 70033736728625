.textCaret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-top-color: rgb(122, 122, 122);
  border-bottom-color: rgb(85, 85, 85);
  height: 6.928px;
  position: relative;
  top: 1px;
}
