.container {
  text-transform: none;

  .avatarContainer {
    width: 140px;
    height: 140px;
    position: absolute;
    z-index: 2;
    bottom: -50px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6fdbe8;
    color: #ffffff;
    font-size: 61px;
    border-radius: 3px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }

    .progressContainer {
      width: 100%;
      height: 100%;
      padding: 20px;
      background: #f8f8f8;
      display: flex;
      align-items: center;
      justify-content: center;

      .progress {
        height: 10px;
        margin-bottom: 15px;
        box-shadow: none;
        background: #ededed;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;

        .progressInfo {
          background: #6fdbe8;
          height: 100%;
        }
      }
    }
  }

  .uploadAvatarContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .uploadAvatar {
        display: flex;
      }
    }

    .uploadAvatar {
      position: absolute;
      right: 5px;
      bottom: 5px;
      display: none;
      align-items: center;
      justify-content: flex-end;
      z-index: 1;

      .uploadAvatarButton {
        margin-left: 5px;
      }

      &:hover {
        display: flex;
      }
    }

    .uploadAvatarImageContainer {
      width: 100%;
      height: 100%;
      border: 3px solid #fff;
      overflow: hidden;

      .uploadAvatarImage {
        pointer-events: none;
      }
    }
  }
}