.container {
  margin-bottom: 10px;
  padding: 0 20px;
  .absenceTimeRangeWrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    input {
      width: 100%;
      height: 35px;
      box-shadow: none;
      display: block;
      padding: 6px 12px;
      color: #555555;
      font-size: 14px;
      font-family: 'Open sans', sans-serif;
      background-color: #fff;
      border: 2px solid #ededed;
      border-radius: 4px;
      transition: border-color ease-in-out 0.15s;

      &:focus {
        border: 2px solid #6fdbe8;
      }
      &.input_border_error {
        border: 2px solid #ff8989;
      }
      &:disabled {
        background-color: #eeeeee;
        color: #eeeeee;
      }
    }
    ::-webkit-input-placeholder {
      color: #c0c0c0;
    }
    ::-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 19+ */
    :-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 18- */
    :-ms-input-placeholder {
      color: #c0c0c0;
    }
  }
  .timeRangeError {
    color: red;
    margin-top: 5px;
  }
}
.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 10px;
}
