.selectContainer {
  position: relative;
  padding: 8px 25px;
  border: 1px solid #ededed;
  height: 40px;
  margin-top: 4px;
  font-size: 14px;
  cursor: pointer;

  &_disabled {
    background-color: #eeeeee;
    cursor: not-allowed;
  }
  &.openedContainer {
    border: 1px solid #6fdbe8;
  }

  .selectOptionsContainer {
    z-index: 2;
    position: absolute;
    top: 40px;
    left: -1px;
    right: -1px;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    background-color: white;
    border: 1px solid #ededed;
    border-top: none;
    transition: all 200ms ease-in-out;
    overflow: auto;

    &.opened {
      max-height: 1000px;
      opacity: 1;
      visibility: visible;
    }
    .selectOption {
      padding: 12px 8px;
      cursor: pointer;
      &:hover {
        background-color: #e2f8fa;
      }
    }
  }
}
.error {
  border: 2px solid #ff8989;
}

.searchInput {
  position: relative;
  left: 0.5em;
  width: 100%;
  overflow: hidden;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  font-size: 15px;
  color: #555555;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  &_disabled {
    background-color: #777;
    cursor: not-allowed;
  }
}

.caretContainer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
