.show-date-time {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    min-width: 328px;
  }

  .datepicker-custom-input {
    width: 100%;
    padding: 6px 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
}
