.img {
  max-width: 200px;
  max-height: 500px;
  margin-right: 10px;
  cursor: pointer;
}

.container {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
}

.name {
  font-size: 13px;
  color: #6fdbe8;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.size {
  font-size: 11px;
  margin-bottom: 22px;
  margin-top: 1px;
}

.icon {
  font-size: 40px;
  display: flex;
  justify-content: center;
  min-width: 51px;
}

.info {
  display: flex;
  max-width: calc(100vw - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  margin-bottom: 16px;
  font-size: 13px;
}

.video {
  margin-bottom: 17px;
}
