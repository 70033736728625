.errorMsg {
  color: red;
}

.imagePreview {
  margin-left: 0.5em;
  margin-top: 0.5em;
  border: 1px solid black;
}

.imagePreviewDeleteButton {
  padding-left: 0.5em;
}

.upload {
  margin-top: 1rem;
  height: 26px;
  width: 31px;
  font-size: 14px;
  background: #6fdbe8;
  color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #59d6e4;
  }
  input {
    display: none;
  }
  label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.uploadButton {
  width: max-content;
  background: #ededed;
  color: #7a7a7a;
  font-size: 13px;
  padding: 4px 8px;
  flex-flow: row;

  i {
    margin-right: 5px;
  }

  &:hover {
    background: #e8e8e8;
    border-color: #adadad;
  }
}
