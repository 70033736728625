.componentTilesContainer {
  display: flex;
  flex-flow: row wrap;
}

.componentTile {
  width: 100px;
  height: 100px;
  background-color: #7f9baa;
  margin: 10px;
  color: white;
  text-align: center;
  padding: 5px;
}

.componentTile i {
  width: 90px;
  margin-top: 15px;
  font-size: 40px;
}

.componentHeader {
  font-size: 10px;
}
