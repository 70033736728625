.profileContainer {
  display: flex;
  flex-wrap: wrap;
}

/* Profile Card Styles */
.profileCard {
  padding: 2rem;
  width: 48%;
  margin: 1%;
  box-sizing: border-box;
  position: relative;
  transition: transform 0.2s; /* For hover animation */
  background-color: #e6e3e3 !important;
    border-radius: 1rem;  
}

.deleteProfileButton {
  position: absolute;
  top: 11px;
  right: 32px;
  background: transparent;
  border: none;
  font-size: 10px;
  cursor: pointer;
  z-index: 1;
  background-color: #e2001A !important;
  color: white !important;
  transition: opacity 0.2s, color 0.2s;
}

/* Profile Content */
.profileContent {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

.imageSection {
  display: flex;
  justify-content: center;
  flex: 1;
  min-width: 100px; /* Ensure minimum width for the image */
}

.inputSection {
  flex: 2;
  min-width: 200px; /* Ensure minimum width for the input section */
}

/* Image Styles */
.imageWrapper {
  position: relative;
  width: 10rem;
  height: 10rem;
  margin-right: 16px;
}

.imageUploadLabel,
.imagePlaceholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  position: relative;
}

.profileImageContainer {
  width: 150px;           /* or whatever fixed size you prefer */
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;     /* optional, if you need positioning */
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;         /* ensures no extra whitespace in some browsers */
}

.imageUploadLabel {
  background-color: #f0f0f0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagePlaceholder {
  background-color: #e0e0e0;
}

.plusIcon {
  font-size: 32px;
  color: #888;
}

.imageInput {
  display: none;
}

.deleteImageButton {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #e6e3e3 !important;
  color: black !important;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 22px;
}

/* Input Styles */
.inputGroup {
  margin-bottom: 12px;
  width: 100%;
}

.inputLabel {
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
}

.inputField {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.textField {
  padding: 8px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

/* Plus Section Styles */
.plusSection {
  width: 48%;
  margin: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addButton {
  font-size: 48px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  color: #888;
}

.addButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Edit Button Styles */
.editButtonContainer,
.editButtonsContainer {
  margin-top: 20px;
  text-align: center;
}

.editButton,
.saveButton,
.cancelButton {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  background-color: #e2001A !important;
  color: white !important;
}

.editButton {
  background-color: #e2001A !important;
  color: white !important;
  border: none;
}

.saveButton {
  background-color: #e2001A !important;
  color: white !important;
  border: none;
}

.cancelButton {
  background-color: #e2001A !important;
  color: white !important;
  border: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .profileCard,
  .plusSection {
    width: 100%;
    margin: 8px 0;
  }

  .profileContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .imageSection {
    margin-bottom: 16px;
  }

  .inputSection {
    width: 100%;
  }
}

.first_article_heading {
  background-color: #e2001A;
  color: white;
  height: 50px;
  padding-left: 14px;
  padding-top: 12px;
  font-weight: 700;
  font-size: larger;
  margin-bottom: 25px;
}

.moreProfiles {
  font-size: 13px;
}

.warningMessage {
  display: flex;
  justify-content: center;
}

.noArticle {
  height: 50px;
  background: lightgray;
  border-radius: 6px;
  padding: 14px;
}

.noProfile {
  height: 3rem;
  background-color: #ababae;
  display: flex;
  justify-content: center;
  padding: 14px;
  margin-bottom: 13px;
  margin-left: 10px;
  width: 100%;
  border-radius: 0.5rem;
}