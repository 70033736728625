.employeeKitaAbsenceTypes {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 10px 30px 10px;

  label {
    font-size: 1rem;
    font-weight: 600;
    color: #7a7a7a;
  }

  .inputContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .holidayOnOvertimeCheckbox {
    margin: 15px 0px;
  }

  .errorMessage {
    color: red;
    font-size: 0.875rem;
  }

  .saveButtonWrapper {
    display: inline-flex;
  }
}
