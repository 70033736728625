.container {
  padding: 10px;
}

.table {
  width: 100%;
  border-spacing: 0;
}

.row {
  &:hover {
    background-color: #f5f5f5;
  }

  td {
    padding: 6px 10px 6px 0;
    border-bottom: 1px solid #ddd;

    &:first-child {
      padding-left: 5px;
    }
  }

  &:last-child td {
    border-bottom: none;
  }

  &Title {
    font-size: 13px;
    font-weight: 700;
    margin-right: 7px;
    display: inline-block;
  }
  &Description {
    flex: 1;
    font-size: 13px;
  }
}

.select {
  margin-top: 0;
}
