.checkboxStatusContainer {
  display: flex;
  align-items: center;
}

.checkboxStatusContent {
  display: flex;
  align-items: center;
}

.container {
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 13px;
  color: #777;
  border-bottom: 1px solid #ddd;
  align-items: center;
  flex-wrap: wrap;

  .headerLeft {
    display: flex;
    flex-flow: row;
    align-items: center;

    i {
      margin-right: 10px;
      color: #9b9b9b;
    }

    .checkboxStatus {
      margin: auto 10px;
    }

    & {
      flex-wrap: wrap;
      margin-bottom: 5px;
    }
  }

  .headerRight {
    display: flex;
    flex-flow: row;
    align-items: center;
    flex-wrap: wrap;

    .userListsContainer {
      display: flex;
      flex-flow: row;

      .responsibleUsersListContainer {
        display: flex;
        flex-flow: row;

        .avatar {
          border: 2px solid #6fdbe8;
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }

      .assignedUsersListContainer {
        display: flex;
        flex-flow: row;

        .avatar {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }
    }

    .reviewRequiredContainer {
      display: flex;
      flex-flow: row;
    }

    .isSchedulingContainer {
      display: flex;
      flex-flow: row;
    }

    .isSchedulingContainerToday {
      color: #fdd198 !important;
    }

    .isSchedulingContainerOverdue {
      color: #ff8989 !important;
    }

    .commentsCountContainer {
      display: flex;
      flex-flow: row;
      i {
        align-self: center;
        margin-right: 5px;
      }
    }

    .headerRightSeparator {
      margin-right: 10px;
      margin-left: 10px;
      width: 1px;
      background: #eee;
    }

    .moreButtonsContainer {
      position: relative;
    }
  }

  .deleteTaskContainer {
    .wrapper {
      padding: 15px;
      margin-top: 10px;
      text-align: center;
    }

    .buttonsContainer {
      margin-top: 10px;
      display: flex;
      flex-flow: row;
      justify-content: center;

      .buttonCancel {
        margin-right: 10px;
      }
    }
  }
}

.containerOpened {
  border-bottom: 1px dashed #ddd;
}

.pendingLabel {
  background-color: #ededed;
  color: #7a7a7a !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
  }
}

.taskAssignButton {
  font-weight: 600;
  display: flex;
  flex-flow: row;
  align-items: center;
  background: #708fa0;
  color: #fff !important;
  padding: 2.75px 8px 2.75px 8px;
  font-size: 12px;
  border-radius: 3px;
  margin-left: 15px;

  &:hover {
    background: #628394;
  }

  i {
    color: white !important;
    margin-right: 5px !important;
  }
}

.inProgressLabel {
  background-color: #6fdbe8;
  color: #fff !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}

.pendingReviewLabel {
  background-color: #fdd198;
  color: #fff !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}

.completedLabel {
  background-color: #97d271;
  color: #fff !important;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}

.overdueLabel {
  background-color: #ff8989;
  color: #fff !important;
  margin-left: 10px;
  border-radius: 3px;

  i {
    margin-right: 0px !important;
    color: #fff !important;
  }
}

.wrapper {
  text-align: center;
  margin: 20px 0;
}

.buttonsContainer {
  display: flex;
  justify-content: center;

  & .buttonCancel {
    margin-right: 8px;
  }
}
