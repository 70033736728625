.inputContainer {
  position: relative;
  textarea {
    width: 100%;
    height: 76px;
    box-shadow: none;
    resize: none;
    display: block;
    padding: 6px 12px;
    color: #555555;
    font-size: 14px;
    font-family: 'Open sans', sans-serif;
    background-color: #fff;
    border: 2px solid #ededed;
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s;
    &.input_border:focus {
      outline: none;
      box-shadow: none;
      border: 2px solid #6fdbe8;
    }

    &.input_border_error {
      border: 2px solid #ff8989;
    }
    &:focus {
      border: 2px solid #6fdbe8;
    }
  }
}

.disabledTextArea {
  textarea {
    background-color: #fdfdfd;
    cursor: not-allowed;
  }
}

.inputIcon {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  background-color: #eee;

  i {
    font-size: 14px;
    color: #555;
  }
}
