.container {
  border-left: 3px solid;
  border-right: 1px solid #ddd;
  border-top: 2px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left-color: #708fa0 !important;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;
  font-size: 13px;

  .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    border-bottom: 1px solid #ddd;
    background-color: #f7f7f7;
    padding: 15px;

    .title {
      margin-left: 10px;
    }
  }

  .hiddenTaskListsContainer {
    display: flex;
    flex-flow: column;

    .hiddenTaskListContainer {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      font-size: 12px;
      padding: 10px;
      border-bottom: 1px solid #eee;

      .taskListButtons {
        display: flex;
        flex-flow: row;
        cursor: pointer;

        .taskListButtonEdit {
          margin-right: 5px;
        }
      }
    }
  }

  .emptyContainer {
    border-bottom: 1px solid #eee;
    padding: 10px;
  }
}
