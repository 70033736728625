.notFound {
  background: white;
  padding: 5px;

  &Description {
    font-size: 16px;
    font-weight: 300;
    color: #555;
    border: none;
    padding: 10px;
    border-radius: 4px;
  }

  &Text {
    padding: 5px;
    font-size: 30px;
  }
}
