.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 10px
}

.groupList {
  display: flex;
  flex-wrap: wrap;
}

.groupElement {
  width: 49%;
  margin-top: 5px;
  margin-right: 3px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  border-radius: 6px;
  cursor: pointer;
}

.groupName {
  margin-top: 8px;
}

@media screen and (max-width: 700px) {
  .groupElement {
    width: 100%;
  }
}

.groupLogo {
  width: 35px !important;
  height: 35px !important;
}
