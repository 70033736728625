.header {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;
  .executivePersonTitle {
    width: 245px;
  }
  .actionTitle {
    width: 155px;
  }
  .name {
    width: 220px;
  }
  .avatar {
    width: 60px;
  }
  .action {
    width: 140px;
  }
  .date {
    width: 100px;
  }
}
.searchInputWrap {
  margin: 10px 0;
  input {
    width: 60%;
  }
}
