
.orderTable{
  width: 100%;
  border-collapse: collapse;
}

.orderTableContainer {
  width: 100%;
  overflow-x: auto;
}

.tr{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  vertical-align: middle;
}

.exportButton{
  width: fit-content;
}

.datePickerWrapper{
  margin-top: 10px;
}
.timeSelect{
  width: 30%;
}

@media only screen and (max-width: 730px) {
  .timeSelect {
    width: 100%;
  }
}
.mr1{
  margin-right: 3px;
}

.groupFilterMultiSelectContainer {
  width: 25em;
}
