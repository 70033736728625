.workingTimeChangeContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin: 10px 0;
  max-width: 500px;

  .caption {
    display: flex;
    align-items: center;
    gap: 10px;
    label {
      font-weight: 600;
    }
  }

  .helpText {
    margin: 0;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .textButton {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: #545454;
      &:hover {
        color: #1e1e1e;
      }

      i {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  input {
    width: 104px;
    height: 35px;
    box-shadow: none;
    display: block;
    padding: 6px 12px;
    color: #555555;
    font-size: 14px;
    font-family: 'Open sans', sans-serif;
    background-color: #fff;
    border: 2px solid #ededed;
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s;

    &:focus {
      border: 2px solid #6fdbe8;
    }
    &.input_border_error {
      border: 2px solid #ff8989;
    }
    &:disabled {
      background-color: #eeeeee;
      color: #545454;
    }
  }
  ::-webkit-input-placeholder {
    color: #c0c0c0;
  }
  ::-moz-placeholder {
    color: #c0c0c0;
  } /* Firefox 19+ */
  :-moz-placeholder {
    color: #c0c0c0;
  } /* Firefox 18- */
  :-ms-input-placeholder {
    color: #c0c0c0;
  }
}

.reviewButtonGroup {
  justify-content: center;
  display: flex;
  gap: 10px;
}

.weekdayContainer {
  display: flex;
  gap: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  &:last-child {
    border-bottom: none;
  }

  .weekday {
    width: 100px;
  }

  .shiftContainer {
    display: flex;
    gap: 30px;

    .newChangeLabel {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: -10px;
      gap: 5px;
    }

    .shiftColumn {
      width: 140px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .newWorkintTimesLabel {
        font-weight: 600;
        width: 50px;
      }

      .highlighted {
        background-color: #6fdbe8;
        border-radius: 3px;
        padding: 0 3px;
        font-weight: 600;
        display: inline-flex;
        width: fit-content;
      }
    }
  }
}
