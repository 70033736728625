.upload {
  height: 36px;
  width: 47px;
  font-size: 14px;
  background-color: #ededed;
  color: #777;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.voiceForPost {
    height: 100%;
  }
  &.isCommentPage {
    width: auto;
  }

  &:hover {
    background: rgba(207, 202, 202, 0.719);
  }
  input {
    display: none;
  }
  label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
