.radioContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px 0;

  .radio {
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 2px solid #c0c0c0;
    height: 18px;
    width: 18px;
    min-width: 18px;
    border-radius: 50%;
    margin: 5px 5px 0 0;
    cursor: pointer;
    .dot {
      height: 0;
      width: 0;
      content: '';
      border-radius: 50%;
      background-color: #6fdbe8;
      transition: all 300ms ease-in-out;
    }
    &.checked .dot {
      height: 10px;
      width: 10px;
    }
  }

  span {
    font-size: 0.9rem;
  }
}
