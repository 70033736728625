.dateInputsContainer {
  display: flex;
  flex-flow: column;
  gap: 10px;

  .dateInputError {
    color: red;
  }

  .datePickerContainer {
    gap: 7px;
    display: inline-flex;
    flex-flow: row;

    .fromToBorder {
      border-left: solid 1px #c4d8da;
    }
  }

  input {
    width: 100%;
    height: 35px;
    box-shadow: none;
    display: block;
    padding: 6px 12px;
    color: #555555;
    font-size: 14px;
    font-family: 'Open sans', sans-serif;
    background-color: #fff;
    border: 2px solid #ededed;
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s;

    &:focus {
      border: 2px solid #6fdbe8;
    }
    &.input_border_error {
      border: 2px solid #ff8989;
    }
    &:disabled {
      background-color: #eeeeee;
      color: #eeeeee;
    }
  }
  ::-webkit-input-placeholder {
    color: #c0c0c0;
  }
  ::-moz-placeholder {
    color: #c0c0c0;
  } /* Firefox 19+ */
  :-moz-placeholder {
    color: #c0c0c0;
  } /* Firefox 18- */
  :-ms-input-placeholder {
    color: #c0c0c0;
  }
}

.dateStart,
.dateEnd {
  display: flex;
  flex-flow: column;
  width: 100px;

  .halfDayCheckbox {
    margin-left: 0;
    margin-right: 0;
  }
}

.absencesTable {
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  .statusColumn,
  .daysColumn,
  .typeColumn {
    min-width: 60px;
    width: 60px;
    text-align: center;
  }
  .nameColumn {
    min-width: 60px;
    width: 60px;
  }

  .periodColumn {
    width: 270px;
    min-width: 270px;
  }
  .reviewColumn {
    width: 70px;
    min-width: 70px;
  }

  .holidaysTableHeader th {
    padding: 10px;
  }

  .statusAccepted {
    color: #89cc5e;
  }
  .statusDeclined {
    color: #ff8989;
  }
  .statusOpen {
    color: gray;
  }
  td.typeColumn {
    color: #9e9e9e;
  }

  span.fromToDate {
    display: inline-block;
    width: 190px;
  }

  .highlightField {
    background-color: #e2f7f9;
  }

  .tooltip {
    z-index: 1602 !important;
  }

  .confirmCancelContainer {
    display: flex;
    gap: 5px;
    margin-top: 7px;
    margin-top: 10px;
  }

  .confirmCancelButton {
    width: 50%;
    i {
      margin-right: 5px;
    }
  }
}

.holidaysHistorySection {
  margin-top: 30px;
}

.container {
  position: relative;

  section {
    margin: 40px 0;
  }
  h2 {
    margin-bottom: 15px;
  }
}

.title {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 300;
}

.description {
  font-size: 12px;
  color: #bac2c7;
}

.text {
  margin-bottom: 20px;
}
