@import 'resources/styles/mixins.scss';
.searchBar:focus{
    width: 300%;
}

.sidePanelContainer {
  position: fixed;
  width: 1%;
  min-width: 16px;
  height: 100%;
  left: 0px;
  top: 100px;
  background: #ffffff;
  z-index: 99;
  padding: 5px;
  transition: width 0.2s ease;

  @include media-mobile {
    min-width: 16px;
  }

  .sidePanelOpenButtonContainer {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: 20px;
    background-color: transparent;
    border-left: 1px solid rgba(9, 30, 66, 0.08);

    .sidePanelOpenButton {
      width: 30px;
      height: 30px;
      position: absolute;
      left: -16px;
      top: calc(30% + 16px);
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba(9, 30, 66, 0.08), 0 2px 4px 1px rgba(9, 30, 66, 0.08);
      opacity: 1;
      transition: opacity 200ms ease;
      cursor: pointer;

      &:hover {
        border: 2px solid rgb(111, 219, 232);
      }

      &:before {
        /* для IE8+ */
        content: '';
        margin-left: 2px;
        margin-top: 3px;
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
    }

    &:hover {
      .sidePanelOpenButton {
        opacity: 1;
      }
    }
  }

  &.openedContainer {
    width: 80px;

    @media screen and (max-width: 1350px) {
      font-size: 15px;
      width: 70px;
    }

    @include media-mobile {
      width: 60px;
    }

    .sidePanelOpenButtonContainer {
      .sidePanelOpenButton {
        opacity: 0;
        font-size: 14px;
        &:before {
          /* для IE8+ */
          margin-left: -2px;
          margin-top: 4px;
        }
      }
    }

    &:hover {
      .sidePanelOpenButtonContainer {
        .sidePanelOpenButton {
          opacity: 1;
        }
      }
    }
  }
}

.sidePanelContainerOnlyForColor {
  background: #ededed;
}
