.wrapper {
  display: flex;
  margin-bottom: 20px;
  .title {
    width: 20%;
    max-width: 20%;
    color: #777777;
  }
  .title + div {
    width: 72%;
  }
  .textArea {
    border-color: green;
    width: 100%;
  }
  .optionMenu {
    width: 8%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    .menuBtn {
      z-index: 120;
    }
  }
}
