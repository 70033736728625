@import 'resources/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 30px;
  position: relative;
  .uploadContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    .uploadBtnWrapper {
      display: flex;
      gap: 10px;
      justify-items: center;
      @media screen and (max-width: 768px) {
        .downloadBtn {
          padding-top: 7px;
        }
      }
      .upload {
        height: 26px;
        width: 31px;
        font-size: 14px;
        background: #6fdbe8;
        color: #fff;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: #59d6e4;
        }
        input {
          display: none;
        }
        label {
          cursor: pointer;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .uploadButton {
        width: max-content;
        background: #ededed;
        color: #7a7a7a;
        font-size: 13px;
        padding: 4px 8px;
        flex-flow: row;
        @media screen and (max-width: 768px) {
          font-size: 9px;
        }

        i {
          margin-right: 5px;
        }

        &:hover {
          background: #e8e8e8;
          border-color: #adadad;
        }
      }
    }
    .patternInfo {
      display: flex;
      gap: 5px;
      .pattern {
        color: red;
      }
    }
  }
}

.input {
  border-radius: 100px;
  padding-right: 70px;

  &Container {
    position: relative;
    max-width: 545px;
    width: 100%;
  }

  &Button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    border-radius: 100px;
  }
}

.advancedText {
  font-size: 11px;
  color: #555555;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 17px;
  cursor: pointer;
  user-select: none;

  &Icon {
    margin-right: 4px;
  }
}

.advancedContainer {
  width: 545px;

  @include media-mobile {
    width: 100%;
  }

  &Text {
    font-size: 13px;
    color: #777777;
    margin-top: 20px;
  }
}

.dateWrapper {
  margin-bottom: 20px;
  display: flex;
  .label {
    display: block;
    text-transform: none;
    color: red;
  }

  .dateStartWrapper,
  .dateEndWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    &>div {
      margin-right: 20px;
    }

    input {
      font-size: 14px;
      color: #777;
      padding: 5px;
      border: 1px solid #ededed;
    }
  }
}
