.container {
  margin-bottom: 10px;
  padding: 0 20px;

  .titleContainer {
    margin-bottom: 15px;
  }

  .taskListsContainer {
    margin-bottom: 15px;
  }

  .descriptionContainer {
    margin-bottom: 15px;

    .textareaContent {
      min-height: 100px;
    }  
  }

  .checkboxContainer {
    margin-bottom: 15px;
  }
}
