.logo {
  width: 38px;
  height: 38px;
  margin-right: 6px;
  background-color: rgb(207, 31, 189);
  border-radius: 4px;
  overflow: hidden;
}

.group {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &Title {
    font-size: 14px;
    font-weight: 500;
    color: #555;
  }

  &Members {
    font-size: 13px;
    color: #777777;
  }
}
.avatarGroupLetter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
}

.img {
  width: 20px;
  height: 20px;
}
