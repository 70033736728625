.childrenContainerWrapper {
    overflow-x: auto;
}

.header {
    display: flex;
    margin-bottom: 10px;
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 10px;

    .name {
        width: 180px;
        margin-right: 5px;
        margin-left: 65px;
        text-align: left;
    }

    .group {
        width: 100px;
        text-align: center;
    }

    .goHome {
        width: 100px;
        text-align: center;
        margin-left: 80px;
    }

    .arrived {
        width: 100px;
        text-align: center;
        margin-left: 80px;
    }


    .status {
        width: 60%;
    }
}

.searchInputWrap {
    margin: 10px 0;

    input {
        width: 60%;
    }
}