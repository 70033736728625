.uploadButton {
  height: 60px;
  width: 90%;
  font-size: 15px;
  background-color: #ededed;
  color: #777;

  &:hover {
    background-color: #ededed;
  }
}