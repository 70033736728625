@mixin table {
  .title {
    margin: 10px 0;
    font-size: 20px;
    font-weight: 300;
  }

  .container {
    position: relative;
  }

  .buttons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;

    &Dropdown {
      position: absolute;
      top: 100%;
      right: 0;
      display: flex;
      flex-direction: column;
      width: 81px;
    }
  }

  .button {
    margin-left: 5px;
  }

  .filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    &Search {
      margin-right: 10px;
      flex: 1;

      &Input {
        height: 40px !important;
      }
    }

    &Visibility {
      flex: 0 286px;
      margin-top: -4px;
    }

    &Kita {
      flex: 0 1 200px;
      margin-top: -4px;
      margin-left: 10px;

      &Opened {
        max-height: 200px !important;
      }
    }
  }

  .table {
    width: 100%;
    white-space: nowrap;

    &Wrapper {
      overflow-x: auto;
    }

    td {
      padding: 10px 10px 10px 0;
      border-bottom: 1px solid #ddd;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }

  .noWs {
    white-space: normal;
  }

  .headerText {
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;

    &Container {
      display: flex;
      align-items: center;
    }

    &WithPadding {
      padding-left: 44px !important;
    }

    &Wrapper {
      display: flex;
    }
  }

  .groupAvatar {
    display: flex;
  }

  .groupName {
    font-size: 13px;
  }

  .groupDesc {
    font-size: 11px;
  }

  .groupName,
  .groupDesc {
    max-width: 300px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &Text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }

    &Box {
      font-size: 15px;
      width: 34px;
      height: 34px;
      min-width: 34px;
      margin-right: 10px;
    }
  }

  .membersCount {
    font-size: 13px;
  }

  .moreBtn {
    position: relative;

    &Dropdown {
      position: relative;
      right: -17px;
      top: 9px;
    }
  }

  .loader {
    margin: 50px 0;
  }

  .select {
    width: 200px;
    margin-top: -4px;
  }

  .deletion {
    &Question {
      color: #555555;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: 28px;
    }

    &Frame {
      min-height: 20px;
      padding: 19px;
      border-radius: 4px;
      border: none;
      box-shadow: none;
      background-color: #ededed;
    }

    &Profile {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 15px;
      margin-bottom: 10px;
      border-bottom: 1px solid #d9d9d9;

      &Avatar {
        width: 38px;
        height: 38px;
        margin-right: 6px;
      }

      &Name {
        font-size: 14px;
        font-weight: 500;
        color: #555;
        margin-top: 0;
        margin-bottom: 2px;
      }

      &Text {
        font-size: 13px;
        color: #555555;
        margin-bottom: 10px;
      }

      &Icon {
        color: #ff8989;
        margin-right: 6px;
      }
    }

    &Buttons {
      margin-top: 28px;
      padding-top: 10px;
      border-top: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &Checkbox {
      margin-top: 18px;
      margin-bottom: 10px;
      margin-left: 0px;
    }
  }

  .checkbox {
    margin-right: 0;

    &Container {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .checkbox {
        margin-right: 1px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .buttons {
      flex-direction: column;

      &Dropdown {
        top: 85px;
        width: 107px;
      }
    }

    .button {
      margin-bottom: 5px;
    }

    .text {
      margin-top: 65px;
    }

    .filters {
      flex-direction: column;

      & > div {
        flex: 0;
        margin: 0 0 5px 0;
        width: 100%;
      }
    }
  }
}
