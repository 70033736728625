.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  min-height: 100vh;
  padding-bottom: 40px;
}

.registration {
  &_container {
    max-width: 500px;
    margin: 0 auto 20px;
    padding-bottom: 15px;
    text-align: left;
  }

  &_heading {
    padding: 15px;

    &_2 {
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: #555555;
      border-bottom: 1px solid #EDEDED;
      margin: 0px 15px;
      padding: 0;
      margin-top: 14px;
      margin-bottom: 20px;
    }

    &_text_sign_in {
      font-weight: 300;
      color: #555555;

      strong {
        font-weight: 700;
        color: #555555;
      }
    }
  }

  &_btn_container {
    margin: 0 15px;
  }

  &_btn_width {
    width: 76px;
  }

  &_in_btn {
    height: 36px;
    background: #708FA0;
    padding: 8px 14px;
    border-radius: 3px;
    cursor: pointer;
    width: 136px;
  }

  &_in_btn_hover:hover {
    background: #286090;
    cursor: pointer;
  }

  &_in_btn_disabled:disabled {
    height: 36px;
    padding: 8px 14px;
    background: #708FA0;
    opacity: 0.7;
    border-radius: 3px;
    cursor: pointer;
  }

  &_in_btn_text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
}

.form {
  padding: 0px 5px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #555555;

  &__item {
    padding: 0;
    margin-bottom: 10px;
  }

  &__label {
    position: relative;
    font-size: 12px;
    font-weight: 900;
    line-height: 17px;
    padding: 0 15px;
    color: #777777;
    margin-bottom: 5px;

    &_text {
      padding: 0;
      font-weight: 600;
    }

    &_required {
      &:after {
        content: '*';
        position: absolute;
        margin-left: 2px;
      }
    }
  }
}


.error_msg {
  padding: 0 15px;
}