.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 10px
}

.formGroup {
  padding: 24px;
}

.formInput {
  margin-bottom: 24px;
}

.error_msg {
}
