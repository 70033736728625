.privateGroup {
  box-shadow: 0 0 3px #dadada;
  border-radius: 4px;
  position: relative;
  margin-bottom: 15px;
  background: white;
  padding: 10px;
  &__heading {
    font-weight: lighter;
    font-size: 16px;
  }
  &__content {
    font-size: 30px;
    color: #777777;
    font-weight: 300;
    margin-top: 37px;
    border-bottom: 1px solid #efeeee;
    padding-bottom: 6px;
  }
  &__footer {
    padding-top: 9px;
  }
}
