.wrapper {
  padding: 10px;
  width: 100%;
}

.inputDuration {
  margin-top: 10px;
}

.inputChild {
  .label {
    text-transform: none !important;
  }
}

.allKids {
  margin-bottom: 10px;
}

.dateWrapper {
  margin-bottom: 20px;

  .label {
    display: block;
    text-transform: none;
    color: red;
  }

  .dateStartWrapper,
  .dateEndWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    & > div {
      margin-right: 20px;
    }

    input {
      font-size: 14px;
      color: #777;
      padding: 5px;
      border: 1px solid #ededed;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .saveButton {
    margin-right: 10px;
  }
}

.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonSubmit {
    margin-right: 10px;
  }
}

.container {
  padding: 10px;
}

.containerEnable {
  padding: 10px;
  text-align: center;
}
