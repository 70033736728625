.question {
  margin-bottom: 18px;
  font-weight: 700;

  &Warning {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #ff8989;
    background-color: #fff6f6;
    border-color: #ff8989;
  }
}

.input {
  margin-bottom: 33px;
}

.error {
  color: #ff8989;
}
