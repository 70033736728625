.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.buttonSave {
  margin-right: 10px;
}

.wrapper {
  margin-top: 10px;
}
