.editCheckInOutControls {
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
  padding: 3px;
  background-color: #e3e3e3;
  border-radius: 3px;
  max-width: 215px;

  .timeInputContainer {
    display: flex;
    gap: 5px;

    .editCheckInOutButtonGroup {
      display: flex;
      gap: 5px;

      .approveBtn {
        background-color: #97d271;
        &:hover {
          background-color: #89cc5e;
        }
      }
      .cancelBtn {
        background-color: #ff8989;
        &:hover {
          background-color: #ff6f6f;
        }
      }

      .approveBtn,
      .cancelBtn {
        i {
          color: white;
        }
        color: #7bc24f;
      }
    }

    .timeInput {
      width: 100px;
    }
  }

  .errorMessageContainer {
    color: #f44b4b;
    font-weight: 600;
    font-size: 0.75rem;
  }
}

.checkInOutTime {
  display: inline-flex;
  width: 140px;
  align-items: center;
  gap: 3px;
}

.editCheckInOutButton {
  background-color: transparent;
  &:hover {
    background-color: #d6d3d3;
  }
}
