.container {
  padding: 10px;
  .description {
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
  }
  .checkbox {
    margin: 12px 0;
  }

  .intervalContainer {
    margin: 30px 0;
  }

  .spacesContainer {
    margin: 30px 0;
    .spacesSelect {
      margin: 10px 0 30px;
    }
  }

}
