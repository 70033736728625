.container {
  margin-bottom: 0px;
  padding: 0px 10px;
  padding-top: 4px;
}

.title {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 13px;
}

.selectText {
  margin-bottom: 15px;
}

.titleBig {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 300;
}
