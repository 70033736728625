.container {
  padding: 10px;
}

.select {
  &Open {
    overflow: auto;
    max-height: 200px !important;
    font-size: 13px;
    width: 100%;
  }
}


.checkboxSpace{
  margin-left: 0px;
}
