@import 'resources/styles/mixins.scss';

.notifications {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  @include media-mobile {
    position: static;
    transform: none;
    padding-left: 10px;
  }
  @media (max-width: 330px) {
    padding-left: 0px;
  }
}

.item {
  width: 34px;
  height: 30px;
  margin: 0 2px;
  border-radius: 3px;
  background-color: #afcb05;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  @media (max-width: 330px) {
    margin: 0 1px;
  }

  i {
    color: #fff;
  }

  &.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .unreadNotifications {
    position: absolute;
    top: -4px;
    right: -2px;
    padding: 3px 5px;
    background-color: #ff8989;
    border-radius: 2px;
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: white;
    font-size: 10px;
    line-height: 10px;
  }
}

.blueItem {
  width: 34px;
  height: 30px;
  margin: 0 2px;
  border-radius: 3px;
  background-color: #051957;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  i {
    color: #fff;
  }

  &.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .unreadNotifications {
    position: absolute;
    top: -4px;
    right: -2px;
    padding: 3px 5px;
    background-color: #ff8989;
    border-radius: 2px;
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: white;
    font-size: 10px;
    line-height: 10px;
  }
}

.brownItem {
  width: 34px;
  height: 30px;
  margin: 0 2px;
  border-radius: 3px;
  background-color: #d7790f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  i {
    color: #fff;
  }

  &.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .unreadNotifications {
    position: absolute;
    top: -4px;
    right: -2px;
    padding: 3px 5px;
    background-color: #ff8989;
    border-radius: 2px;
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: white;
    font-size: 10px;
    line-height: 10px;
  }
}
