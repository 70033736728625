@import 'resources/styles/mixins.scss';

.wrapper {
  display: flex;
  justify-content: space-between;

  @include media-tablet {
    flex-wrap: wrap;
  }
}

.body {
  flex: 1;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  word-break: break-word;
}

.menu {
  flex: 0 170px;
  margin-right: 25px;

  @include media-laptop {
    flex: 0 137px;
  }

  @include media-tablet {
    flex: 0 100%;
    margin-right: 0;
  }
}

.listItem {
  padding: 6px 15px 5px;
  align-items: flex-start;
  i {
    font-size: 14px;
    width: 18px;
    position: relative;
    top: 1px;
  }

  & > div {
    font-size: 12px;
  }
}
