.container {
  padding: 10px;

  .description {
    font-size: 12px;
    color: #bac2c7;
  }

  .table {
    border-collapse: collapse;
    font-size: 13px;
    width: 100%;
  
    @media screen and (max-width: 555px) {
      width: 488px;
    }

    thead {
      tr {
        th {
          text-align: left;
          font-size: 13px;
          font-weight: bold;
          padding: 10px 10px 10px 0;
          color: #bebebe;
          &:first-child {
            padding-left: 5px;
            color: #555;
            cursor: pointer;
          }
        }
      }
    }
    tbody {
      tr {
        border-top: 1px solid #ddd;
        td {
          padding: 6px 10px 6px 0;
          &:first-child {
            padding-left: 5px;
          }
        }
        &:hover {
          background: #f5f5f5;
        }
      }
    }
  }
}

.acceptButton {
  padding: 4px 8px;
  font-size: 12px;
  background: #97d271;
  color: #fff !important;
  margin-right: 5px;

  &:hover {
    background: #89cc5e;
    border-color: #398439;
  }
}

.denyButton {
  padding: 4px 8px;
  font-size: 12px;
  background: #ff8989;
  color: #fff !important;

  &:hover {
    background: #ff6f6f;
    border-color: #ac2925;
  }
}
