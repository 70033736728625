.body {
  width: 100%;
  margin-bottom: 0px;
}

.container {
  padding: 10px;

  .titleContainer {
    margin-bottom: 15px;
  
    .titleHeader {
      font-weight: 300;
      font-size: 130%;
    }
  
    .tittleDescription {
      margin-top: 5px;
      color: #aeaeae !important;
      font-size: 11px;
  
      &:hover {
        color: #7a7a7a !important;
      }
    }
  }
  
  .checkboxContainerSnippet {
    margin-bottom: 15px;
  }

  .checkboxContainer {
    margin-bottom: 15px;

    .description {
      margin-top: 3px;
      margin-left: 15px;
      color: #aeaeae !important;
      font-size: 11px;
  
      &:hover {
        color: #7a7a7a !important;
      }
    }
  }
  
  .inputContainer {
    margin-bottom: 15px;
  }
}
