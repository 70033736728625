.cardContainer {
  width: 100%;
  height: fit-content;
}

.container{
  margin: 10px 20px 10px 20px;
}



.bodyTitle {
  background-color: #f7f7f7;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.headerMoreBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  width: 48px;
  height: 26px;
}
