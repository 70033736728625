.disclaimer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  letter-spacing: 0.2px;
  line-height: 22px;

  & .disclaimerBackground {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.15s;
    z-index: -1;
    position: fixed;
  }

  & .disclaimerWindowWrapper {
    overflow: auto;
    max-width: 450px;
    max-height: calc(100% - 30px);
    background-color: white;
    transition: transform 0.15s, opacity 0.15s;
    transform: scale(0.5);
    opacity: 0;
    border-radius: 4px;
    padding: 16px;
  }

  & .disclaimerControls {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    & .disclaimerButton {
      background-color: rgb(255, 255, 255);
      min-width: 100px;
      margin-left: 8px;
      border-radius: 4px;
      color: rgb(64, 138, 236);
      padding: 6px 15px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.3);
      transition: opacity 0.1s;

      &Accept {
        color: rgb(255, 255, 255);
        border: none;
        background-color: rgb(64, 138, 236);

      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  & .disclaimerWindow {
    &Header {
      font-size: 16px;
      margin-bottom: 20px;
      color: rgb(0, 0, 0);

      & span {
        text-decoration: underline;
        font-size: 14px;
        color: rgb(100, 100, 100);
      }
    }

    & p {
      margin-top: 10px;
      font-size: 14px;
      color: rgb(100, 100, 100);
    }
  }
}

.open {
  & .disclaimerBackground {
    background-color: rgba(0, 0, 0, 0.5);
  }

  & .disclaimerWindowWrapper {
    transform: scale(1);
    opacity: 1;
  }
}