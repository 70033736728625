.holidayCareFrame {
  margin-bottom: 0px;
  padding-top: 4px;
  transition: 0.2s cubic-bezier(0.37, -0.25, 0.47, 1.26);

  .holidaysCareContainer {
    width: 100%;
    padding: 10px;
  
    .holidayCare {
      display: grid;
      grid-gap: 8px;
      align-items: center;
  
      .holidayCareHeader {
        width: 600px;
        padding-bottom: 5px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-bottom: 1px solid #ccc;
        font-weight: bold;
      }
  
      .holidayCareRow {
        display: flex;
        gap: 10px;
        align-items: center;
  
        .holidayCareDateRow, .holidayCareTimeRow {
          width: 600px;
          padding-bottom: 5px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 8px;
          align-items: center;
    
          input {
            width: 140px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .footer {
    padding: 0 10px;
    display: flex;
    gap: 10px;
  }
}