.breakButtonContainer {
  display: flex;
  border: none;
  align-items: center;
  height: 68px;
  cursor: pointer;
  label {
    margin-right: 10px;
    font-weight: 700;
    cursor: pointer;
  }

  .breakButtonWrapper {
    display: flex;
    border: 2px solid #628394;
    border-radius: 3px;
    flex-direction: column;
    height: 100%;
  }
  .breakButtonWrapperStarted {
    border: 2px solid #ff8989;
  }

  .buttonLabel {
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100%;
    width: 100%;
    padding: 2px;
  }
  .timeInput {
    width: 6.2rem;
    border: none;
  }

  .buttonRunning {
    border: 2px solid #ff8989;
    background-color: #ff8989;
    &:hover {
      background-color: #f45e5e;
    }
  }

  .buttonStopped {
    background-color: #628394;
    &:hover {
      background-color: #4a6a7a;
    }
  }
}
