.wrapper {
  display: flex;
  margin-bottom: 20px;
  div {
    margin-right: 15px;
  }
  .photo {
    width: 40px;
    img {
      height: 40px;
      width: 40px;
    }
  }
  .status {
    display: flex;
    align-items: center;
    width: 140px;
  }
  .date {
    display: flex;
    align-items: center;
  }
  .name {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    width: 220px;
    .parent {
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
    }
  }
  .executivePerson {
    display: flex;
    width: 220px;
    align-items: center;
    img {
      height: 40px;
      width: 40px;
      margin-right: 15px;
    }
  }
}

.statusIn {
  color: #08803a;
}

.statusOut {
  color: #ffaf00;
}

.statusAbsent {
  color: #ff4532;
}
