.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.buttonSave {
  margin-right: 10px;
}

.wrapper {
  padding: 20px;
}

.notes {
  margin-bottom: 20px;
}

.inputGroup {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
