@import 'resources/styles/mixins.scss';
@import 'resources/styles/mixins/table.scss';
@import 'resources/styles/mixins.scss';
@include table;

.wrapper {
  padding: 10px;
  min-height: 200px;
  position: relative;

  @media all and (min-width: #{$mobile-width}) {
    width: 100%;
  }
}

.loader {
  position: absolute;
  top: 0;
}

.tableWrapperOverflow {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    font-size: 11px;
    color: #bebebe;
    font-weight: 700;
    vertical-align: bottom;
    border-bottom: 1px solid #ddd;
    text-align: left;

    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(5) {
      @include media-laptop {
        //width: 100px;
        min-width: 100px;
      }
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      @include media-tablet {
        min-width: 80px;
      }
    }

    &:nth-child(6) {
      @include media-tablet {
        min-width: 150px;
      }
    }
  }

  td {
    border-bottom: 1px solid #ddd;
    font-size: 13px;
    color: #555555;
    padding: 10px;
  }

  tr.alreadyOver td {
    background-color: rgba(204, 204, 204, 0.449);
  }
}

.absenceEmpty {
  margin: 5px;
  font-size: 18px;
  text-align: center;
}

.email {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.headerTextContainerCheckbox {
  justify-content: flex-start;
  margin-right: 1px;
}

.titleCheckbox {
  margin: 0 0 0 6px;
}

.titleCheckboxTitle {
  min-width: 10px;
}

.titleButtons {
  @include media-mobile {
    position: initial;
  }
}

.headerMoreBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  width: 48px;
  height: 26px;
}