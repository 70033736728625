@import 'resources/styles/mixins.scss';

.buttonsContainer{
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonCancel {
    margin-right: 10px;
  }
}

.confirmText {
  text-align: center;
  margin: 15px 10px;
  font-weight: 300;
}

.container {
  max-width: 70%;
  margin-left: 5px;
  .topRight {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;

    .leaveButton {
      background: #EDEDED;
      color: #7A7A7A !important;
      padding: 7px 11px;
      margin-left: 2px;
      font-size: 16px;

      @include media-mobile {
        padding: 7px 11px;
        font-size: 15px;
      }
    }

    .avatar {
      width: 35px;
      height: 35px;
      border-radius: 3px;
      opacity: 0.5;

      &:hover {
        opacity: 0.3;
      }

      @include media-mobile {
        width: 34px;
        height: 34px;
      }
    }

    .buttonAddUser div {
      background: #257D9D;
      color: #fff !important;
      font-size: 15px;
      padding: 8px 14px;

      @include media-mobile {
        font-size: 13px;
      }
      i {
        margin-right: 5px;

        @include media-mobile {
          margin-right: 0px;
        }
      }
    }
  }
}

.avatarSingleNumber {
  position: absolute;
  top: 5px;
  right: 11px;
  color: #fff;

  @include media-mobile {
    top: 4px;
    right: 10px;
  }
}

.avatarDoubleNumber {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;

  @include media-mobile {
    top: 4px;
    right: 4px;
  }
}

.avatarTrippleNumber {
  position: absolute;
  top: 7px;
  right: 3px;
  font-size: 11px;
  color: #fff;

  @include media-mobile {
    top: 6px;
    right: 2px;
  }
}

.avatarContainer {
  position: relative;
  height: 4px;
}

.avatarButton {
  padding: 0px;
  margin-left: 3px;
  background-color: black;
  border-radius: 3px;
  height: 35px;

  @include media-mobile {
    height: 34px;
  }

  &:hover {
    background-color: black;
  }
}

.avatarList {
  text-align: center;
  width: 106px;
  margin-top: 5px;

  @include media-mobile {
    width: 76px;
  }
}

.avatarName {
  width: 100px;

  @include media-mobile {
    width: 70px;
  }
}

.avatarInPopup {
  margin: 3px;
  width: 100px;
  height: 100px;
  border-radius: 3px;

  @include media-mobile {
    width: 70px;
    height: 70px;
  }
}

.grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4,minmax(0,1fr));
  align-items: end;
}

.oneAvatar {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(1,minmax(0,1fr));
  align-items: end;
}

.twoAvatar {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2,minmax(0,1fr));
  align-items: end;
}

.threeAvatar {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3,minmax(0,1fr));
  align-items: end;
}

.tooltip {
  background-color: #257D9D;

  &:after {
    left: 50%;
    right: unset;
  }

  @media screen and (max-width: 1300px) {
    left: -55px;

    &:after {
      left: unset;
      right: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    left: 50%;

    &:after {
      left: 50%;
      right: unset;
    }
  }

  @media screen and (max-width: 1100px) {
    left: -55px;

    &:after {
      left: unset;
      right: 0;
    }
  }

  @media screen and (max-width: 990px) {
    left: 50%;

    &:after {
      left: 50%;
      right: unset;
    }
  }

  @media screen and (max-width: 885px) {
    left: -55px;

    &:after {
      left: unset;
      right: 0;
    }
  }
}

.buttonText {
  @include media-mobile {
    display: none;
  }
}

.addUserContainer {
  margin: 10px;
}

.buttonWithLoader {
  background: #257D9D;
  color: #fff !important;

  &:hover {
    background: #257D9D;
  }
}
