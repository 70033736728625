.container {
  width: 100%;
  flex: 1;
  background-color: #fff;
  box-shadow: 0 0 3px #dadada;
  border-radius: 4px;
  margin-bottom: 20px;
  padding-top: 0.5em;

  .header {
    margin-bottom: 0.5em;
    padding: 10px;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #555;
    }
  }
}
