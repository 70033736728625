.body {
  padding: 10px;
}

.buttons {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Left {
    display: flex;
    & > * {
      margin-right: 3px;
      position: relative;
    }

    .uploadButton {
      height: 36px;
      width: 47px;
      font-size: 15px;
      background-color: #ededed;
      color: #777;
    }
  }

  &Right {
    position: relative;
    display: flex;
    align-items: center;
    & > * {
      margin-left: 5px;
    }
  }
}

.error {
  color: #ff8989;
  font-size: 13px;
  margin-top: 6px;
}

.moreBtn {
  width: 34px;
  height: 28px;
}

.progressBar {
  background: #ededed;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  margin: 10px 0px;
  overflow: hidden;

  &Value {
    background: #6fdbe8;
    transition: width 0.6s ease;
    height: 100%;
  }
}
