@import 'resources/styles/mixins.scss';

.photoslideContainer{

  .pswp {
    height: 80vh !important;
  }

  .pswp__zoom-wrap{
    top: -45px;
  }
}

