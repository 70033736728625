.searchInputWrap {
  margin: 10px 0;
  input {
    width: 30ch;
  }
}

.containerEmployeeHistory {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
