.container {
  margin-bottom: 10px;
}

.itemInfo {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  border-left: 3px solid white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &:hover {
    border-left: 3px solid #6fdbe8;
    background-color: #f7f7f7;
  }

  &Avatar {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  &Name {
    font-size: 14px;
    font-weight: 500;
    color: #555;
    margin-bottom: 2px;
  }

  &Status {
    color: #aeaeae;
    font-weight: 300;
    margin-bottom: 5px;
    font-size: 14px;
  }
}
