.inputContainer {
  padding: 0 15px;

  i {
    font-size: 14px;
    color: #555;
  }

  input {
    width: 100%;
    height: 35px;
    padding: 6px 12px;
    color: #555555;
    font-size: 14px;
    font-family: 'Open sans', sans-serif;
    background-color: #ffffff;
    border: 1px solid #ededed;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &::-moz-placeholder {
      color: rgba(167, 167, 167, 0.7);
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: rgba(167, 167, 167, 0.7);
    }
    &::-webkit-input-placeholder {
      color: rgba(167, 167, 167, 0.7);
    }
  }

  input[type='text']:focus,
  input[type='password']:focus {
    outline: none;
    box-shadow: none;
  }

  .input_border:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #6fdbe8;
  }

  .input_border_error {
    border: 2px solid #ff8989;
  }
}

::-webkit-input-placeholder {
  color:#c0c0c0;
}
::-moz-placeholder{
  color:#c0c0c0;
}/* Firefox 19+ */
:-moz-placeholder{
  color:#c0c0c0;
}/* Firefox 18- */
:-ms-input-placeholder{
  color:#c0c0c0;
}

.form-control {
  font-family: 'Open sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  color: #555555 !important;
}

.inputIcon {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  background-color: #eee;
}
