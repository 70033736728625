.container {
  width: 100%;
}

.title {
  padding: 10px;
  font-size: 16px;
  color: #555555;
}

.gallery {
  position: relative;
  margin: 0 5px 5px 0;
  width: 149px;
  height: 149px;
  border-radius: 3px;
  box-shadow: 0 0 3px #dadada;
  color: #fff;
  cursor: pointer;
  background-color: #97d271;

  &Container {
    padding: 0 10px 10px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &Title {
    max-width: calc(75% + 10px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: 12px;
    padding: 8px 11px;
    position: relative;
    z-index: 1;
    font-weight: 600;

    &Wrap {
      white-space: normal;
    }

    &Icon {
      padding-left: 5px;
    }
  }

  &New {
    background: #97d271;
  }

  &:hover {
    .gallery {
      &Image {
        transform: scale(1.3);
      }

      &Overlay {
        opacity: 0.55;
      }

      &PlusIcon {
        opacity: 0.55;
      }
    }
  }

  &Image {
    color: #000;
    font-size: 104px;
    transition: 0.5s;

    &Stub {
      padding: 15%;
    }

    &Container {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background-size: cover;
      background-position: center;

      & > img {
        width: 100%;
      }
    }
  }

  &Overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    transition: 0.5s;
  }

  &Description {
    font-size: 13px;
    color: #777777;
    margin: 11px 0 -5px 9px;
  }

  &PlusIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;
  }

  .selectBtn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    .checkboxFile {
      margin: 0;
    }
  }
}

.moreBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
}

.inputFile {
  display: none;
}

.wrapper {
  width: 100%;
}

.headerMoreBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  width: 48px;
  height: 26px;
}

.progressBar {
  background: #ededed;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  margin: 10px 0px;
  overflow: hidden;

  &Container {
    padding: 0 10px;
  }

  &Value {
    background: #6fdbe8;
    transition: width 0.6s ease;
    height: 100%;
  }
}

.loader {
  margin-bottom: 20px;
}

.headerMoreBtnSubmitting {
  position: absolute;
  top: 10px;
  right: 10px;
  width: fit-content;
  height: 26px;
}
