.acceptButton {
  padding: 4px 8px;
  font-size: 12px;
  background: #97d271;
  color: #fff !important;
  margin-right: 5px;

  &:hover {
    background: #89cc5e;
    border-color: #398439;
  }
}

.denyButton {
  padding: 4px 8px;
  font-size: 12px;
  background: #ff8989;
  color: #fff !important;
  margin-left: 3px;

  &:hover {
    background: #ff6f6f;
    border-color: #ac2925;
  }
}
