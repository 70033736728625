.checkboxSpace {
  margin-left: 0;
}

.form2faGroups {
  display: flex;
  flex-direction: column;
  .form2faLabel {
    font-weight: bold;
  }
}

.saveButtonWrapper {
  margin-top: 1rem;
}

.ageCounter {
  display: flex;
  margin-top: 0.5rem;
}

.counterInput {
  width: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.inMonths{
  padding: 0.45rem;
    margin-left: 4px;
}


