.header {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;
  flex-direction: column;

  .name {
    width: 40%;
    padding-left: 80px;
  }


  .status {
    width: 60%;
  }
}

.searchInputWrap {
  margin: 10px 0;

  input {
    width: 60%;
  }
}