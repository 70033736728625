.container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  h2 {
    margin-top: 50px;
  }

  .inputGroup {
    display: flex;
    gap: 20px;

    .timeInputContainer,
    .dateContainer {
      width: 100px;
      display: flex;
      flex-direction: column;
    }
  }

  .timeInput {
    width: 13ch;
  }

  .textBoxReason {
    width: 340px;
  }

  .breakCheckbox {
    margin: 0;
  }

  .errorMessage {
    color: #ff8989;
    font-size: 12px;
    padding: 10px 0;
  }

  .submitButton {
    min-width: 100px;
    width: fit-content;
    margin: 2px;
  }

  .dateContainer {
    input {
      width: 100%;
      height: 35px;
      box-shadow: none;
      display: block;
      padding: 6px 12px;
      color: #555555;
      font-size: 14px;
      font-family: 'Open sans', sans-serif;
      background-color: #fff;
      border: 2px solid #ededed;
      border-radius: 4px;
      transition: border-color ease-in-out 0.15s;

      &:focus {
        border: 2px solid #6fdbe8;
      }
      &.input_border_error {
        border: 2px solid #ff8989;
      }
      &:disabled {
        background-color: #eeeeee;
        color: #eeeeee;
      }
    }
    ::-webkit-input-placeholder {
      color: #c0c0c0;
    }
    ::-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 19+ */
    :-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 18- */
    :-ms-input-placeholder {
      color: #c0c0c0;
    }
  }
}
