.groupHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &Image {
    pointer-events: none;
    position: absolute;
    width: 100%;
  }
}

.logo {
  text-transform: none;
}

.uploadAvatar {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: none;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;

  &Button {
    margin-left: 5px;
  }

  &Container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .uploadAvatar {
        display: flex;
      }
    }
  }
}

.progress {
  height: 10px;
  box-shadow: none;
  background: #ededed;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: 430px;

  &Container {
    width: 100%;
    height: 100%;
    padding: 20px;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &Info {
    background: #6fdbe8;
    height: 100%;
  }
}

.avatarContainer {
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: 1;
  bottom: -50px;
  left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6fdbe8;
  color: #ffffff;
  font-size: 61px;
  border-radius: 3px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border: 3px solid #fff;
  }
}