.foodItem{
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  -webkit-box-shadow: 5px 5px 12px -4px rgba(0,0,0,0.39);
  box-shadow: 5px 5px 12px -4px rgba(0,0,0,0.3);
  margin: 0 13px 16px 0;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
}

.checkbox{
  margin: 0 5px 0 auto;
}

.top{
  margin-top: 3px;
}


.greenline{
  background: linear-gradient(90deg, rgba(0, 191, 0, 0) 97%, rgba(0, 211, 0, 0.64) 3%);
}
.detailsIcon{
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 10px;

}
.details{
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  margin-left: 10px;
}

.border{
  background-color: #f6f6f6;
  margin-top: 4px;
  margin-bottom: 6px;
  padding: 8px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
}
