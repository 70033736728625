.smallRightMargin{
   margin-right:10px
}

.calendarContainer {
   padding: 10px;
   hyphens: auto;
   .taskEvent {
     display: flex;
     flex-flow: row;
     align-items: flex-end;

     i {
       margin-left: 3px;
       margin-right: 5px;
     }
   }

   @media screen and (max-width: 768px) {
     :global(.fc-header-toolbar) {
       flex-wrap: wrap;

       :global(.fc-right) {
         margin: 10px 0;
       }
     }
   }
 }
