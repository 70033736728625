.container {
  padding: 10px;
}

.titleContainer {
  margin-bottom: 20px;

  .titleHeader {
    font-weight: 300;
    font-size: 150%;
  }

  .tittleDescription {
    margin-top: 5px;
    color: #aeaeae !important;
    font-size: 12;

    &:hover {
      color: #7a7a7a !important;
    }
  }
}

.participationModeContainer {
  margin-bottom: 20px;
}

.showInStreanCheckboxContainer {
  margin-bottom: 20px;
}