.container {
  font-size: 13px;
  padding: 21px;
  text-align: center;
}

.header {
  margin-bottom: 15px;
  border-bottom: none;
  text-align: center;
  color: #555;
  font-weight: 300;
  font-size: 20px;
}

.footerNavigation {
  display: flex;
  justify-content: center;
  & > div {
    margin: 0 5px;
  }
}

.linkWrapper {
  margin-top: 10px;

  .link {
    text-decoration: underline;
    color: #3b3b8e;
  }
}
