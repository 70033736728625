.helpText {
  color: #aeaeae;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 10px;

  &:hover {
    color: #7a7a7a;
  }
}
