@import 'resources/styles/mixins.scss';

.bottombarHamburger {
  border-left: 1px solid rgb(237, 237, 237);
  display: none;

  @include media-tablet {
    display: block;
  }
}

.awoBottomHamburger {
  display: block;
}
