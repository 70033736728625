.delete {
  height: 26px;
  width: 27px;
  font-size: 14px;
  background: #ff8989;
  color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #ff6f6f;
  }
}
