.container {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
}

.helptextcontainer {
  max-width: 300px;
  margin: 0 auto 20px;
  margin-top: 20px;
  padding-bottom: 15px;
  text-align: left;
  width: 100%;
}