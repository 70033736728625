.wrapper {
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  min-height: 200px;
  padding: 10px;
  ul {
    margin-top: 10px;
    margin-left: 30px;
    font-size: 12px;
    font-weight: bold;
  }
}
.exportButton {
  margin-top: 20px;
}
.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonSubmit {
    margin-right: 10px;
  }
}

.container {
  padding: 10px;
}

.containerEnable {
  padding: 10px;
  text-align: center;
}
.modalBody {
  padding: 20px;
  text-align: center;
}
