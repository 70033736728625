.search {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #fff;
  
    &Label {
      font-weight: 600;
      margin-bottom: 7px;
    }
  }
  
  .searchingResults {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 1px solid #d7d7d7;
    background: white;
    margin-top: -1px;
    font-size: 14px;
    border-radius: 0px 0px 4px 4px;
    position: relative;
    z-index: 2;
    color: #777;
    &Loader {
      padding: 10px 0;
    }
    &List {
      &Empty {
        padding: 6px 7px;
      }
      &Item {
        cursor: pointer;
        border-left: 3px solid transparent;
        padding: 6px;
        font-size: 14px;
        color: #777;
        align-items: center;
        &Active {
          background-color: #f7f7f7;
          border-left: 3px solid #6fdbe8;
          color: #555;
        }
        display: flex;
        &Photo {
          width: 24px;
          height: 24px;
          margin-right: 3px;
  
          .isSmall & {
            min-width: 18px;
            width: 18px;
            height: 18px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        &Hidden {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
  
  .searchInput {
  }
  
  .tagsinput__focus {
    position: absolute;
    width: 100%;
    border: 2px solid #6fdbe8;
    height: 40px;
    border-radius: 4px;
    top: 25px;
    pointer-events: none;
    display: none;
  }
  .inputTags {
    border: 2px solid #ededed;
    min-height: 40px;
    border-radius: 4px;
    background: transparent;
    cursor: text;
    display: flex;
    padding: 2px 2px;
    flex-wrap: wrap;
    justify-content: flex-start;
  
    .isSmall & {
      min-height: 35px;
    }
    &_focused {
      border: 2px solid #6fdbe8;
    }
    &_error {
      border: 2px solid #ff8989;
    }
    &__item {
      padding: 2px;
      font-size: 12px;
      color: white;
      align-items: center;
      display: flex;
      background: #6fdbe8;
      height: 28px;
      border-radius: 4px;
      position: relative;
      padding-right: 7px;
      margin: 2px;
      cursor: pointer;
  
      .isSmall & {
        height: 23px;
      }
  
      .photo {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        border-radius: 3px;
        overflow: hidden;
  
        .isSmall & {
          width: 18px;
          height: 18px;
        }
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .name {
        margin: 0 5px;
        padding-bottom: 4px;
        font-weight: 700;
  
        .isSmall & {
          padding-bottom: 3px;
        }
      }
      .delete {
        margin-left: 4px;
        margin-bottom: 4px;
        cursor: pointer;
  
        .isSmall & {
          margin-bottom: 0;
        }
      }
    }
  }
  .inputTags__input {
    font-size: 14px;
    color: #555;
    border: none;
    padding-left: 7px;
    height: 28px;
    width: 150px;
    margin: 2px;
  
    &.inputFullWidth {
      width: 100%;
    }
  
    .isSmall & {
      font-weight: 700;
      font-size: 12px;
    }
  }
  
  .searchingInput_focused ~ .searchingResults {
    // display: block;
  }
  .searchingInput_focused ~ .tagsinput__focus {
    display: block;
  }
  