@import 'resources/styles/mixins.scss';

.kitaPanelWrapper {
  margin-left: 70px;
  transition: all 0.3s ease;

  &Wide {
    margin-left: 17px;
  }

  @media screen and (max-width: 1350px) {
    margin-left: 60px;

    &Wide {
      margin-left: 17px;
    }
  }

  @include media-laptop {
  }

  @include media-tablet {
  }

  @include media-mobile {
    margin-left: 50px;

    &Wide {
      margin-left: 17px;
    }
  }
}
