.wrapper {
  display: flex;
  margin-bottom: 20px;

  div {
    flex-shrink: 0;
    margin-right: 15px;
  }

  .photo {
    display: flex;
    align-items: center;
    width: 40px;

    img {
      height: 40px;
      width: 40px;
    }
  }

  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
  }

  .note {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    text-align: center;

    ul {
      list-style-type: none;
      padding-left: 0;
    }
  }

  .group {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  }

  .pickupPerson {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
  }

  .date {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100px;
  }

  .name {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    width: 165px;

    .parent {
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
    }
  }

  .executivePerson {
    display: flex;
    width: 170px;
    align-items: center;

    img {
      height: 40px;
      width: 40px;
      margin-right: 15px;
    }
  }
}

.statusIn {
  color: #08803a;
}

.statusOut {
  color: #ffaf00;
}

.statusAbsent {
  color: #ff4532;
}