.cardContainer {
  padding: 12px;

  .groupListTitle {
    font-size: 20px;
    font-weight: bold;
  }
}

.nodeHeader {
  margin: 4px 0;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  cursor: pointer;
  background-color: #e2001a;
  color: white;
}

.contentPanel {
  margin-left: 36px;
  padding-bottom: 8px;
  margin-top: 0.5rem;
}

.kitaItem {
  display: flex;
  padding: 2px 0;
  align-items: center;
  gap: 4px;
  margin-left: 1.5rem;
  cursor: pointer;

  i {
    font-size: 12px;
  }
}

.groupLink {
  color: black;
  margin-left: 0.5rem !important;
  text-decoration: underline;
}

.divider {
  border-top: 1px solid #cecece;

  &:last-child {
    border-bottom: 1px solid #cecece;
  }
}

.allKitas {
  font-size: medium;
  font-weight: 700;
  height: 20px;
}

.levelText {
  margin-left: 0.5rem;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
}

.groupItem {
  display: flex;
  margin-left: 1.5rem;
  align-items: center;
}

.groupLink {
  margin-bottom: 0.2rem;
}

.checkbox {
  color: black !important;
}

.fa {
  margin-left: auto;
}

.deleteFilterButton {
  margin-left: 10px;
}
