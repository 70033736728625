@keyframes pulsing {
    0% {
        background-color: #DDD;
    }

    50% {
        background-color: #FFF;
    }

    100% {
        background-color: #DDD;
    }
}

.textPreload {
    height: 15px;
    border-radius: 0;
    animation: 1s linear infinite;
    animation-name: pulsing;
    flex-grow: 1;
    margin-bottom: 10px;
}

.wrapper {
    display: inline;
    max-width: 100%;
    min-height: 18px;
    overflow: hidden;
}
