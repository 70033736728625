.notification {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 13px;
  padding: 12px 10px;
  border-bottom: 1px solid #EDEDED;
  border-left: 3px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: #F7F7F7;
  }
  &.noBorder {
    border-bottom: none;
  }
  &.unread {
    background-color: #E2F8FA;
    border-left: 3px solid #E2F8FA;
  }
  &:hover {
    border-left: 3px solid #6FDBE8;
  }

  .imageContainer {
    position: relative;
    margin-right: 15px;
    .image {
      width: 40px;
      height: 40px;
    }
    .logo {
      position: absolute;
      top: 24px;
      right: -2px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
    }
  }
  .content {
    width: 100%;
  }
  .dateContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4px;
    .date {
      font-size: 12px;
      color: #C0C0C0;
    }
    .new {
      padding: 3px 5px 0;
      background: #FF8989;
      border-radius: 2px;
      color: white;
      font-family: 'Open sans', sans-serif;
      font-size: 10px;
      line-height: 10px;
    }
  }
}

.deleteCheck {
  display: flex;
}

.deletecheckbox {
  margin-top: 50%;
}
