.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 10px
}

.groupList {
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
}

.groupElement {
  width: 49%;
  margin-top: 5px;
  margin-right: 3px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  border-radius: 6px;
  cursor: pointer;
}

.name {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: bold;
  .parent {
    font-size: 11px;
    font-weight: normal;
    font-style: italic;
  }
}

.kidAvatar {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &Text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  &Box {
    font-size: 15px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    margin-right: 10px;
  }
}
