.groupContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px 30px 30px;
  overflow-y: scroll;
  max-height:400px;
}
.groupHint{
  margin-top: 20px;
  padding-left: 30px;
}
.groupLogo{
  width: 35px !important;
  height: 35px !important;
}
.groupElement{
  background-color: #fafafa;
  width: 49%;
  margin-right: 3px;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  padding: 5px;
  border-radius: 6px;
}

.groupElement:hover{
  cursor: pointer;
}

.groupName{
  margin-top: 8px;
}
@media screen and (max-width: 700px) {
  .groupElement {
    width: 100%;
  }
}

.awoGroupContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
  .kitaNameText {
    padding-left: 30px;
    font-weight: bold;
  }
  .groupItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 30px;
  }
}

.groupNameWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footerWrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
}
